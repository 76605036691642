import React, { Component } from 'react';
// import config from '../../config';
import { Page, LayoutSingleColumn } from '../../components';
import Footer from '../../components/Footer/FooterNew';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import { ExternalLink } from '../../components';
import Cookies from 'universal-cookie';

import { FormattedMessage } from '../../util/reactIntl';

import css from './FAQPage.module.css';

import FAQ from './FAQ.js';

import insta from '../../assets/icons/socialmedia/instagram-icon.png';
import fb from '../../assets/icons/socialmedia/facebook-icon.png';
import linkedIn from '../../assets/icons/socialmedia/linkedIn-icon.png';

export class FaqPage extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      subPage: 'third',
      contactForm: '1',
      name: '',
      message: '',
      email: '',
    };
  }
  componentDidMount() {
    if(typeof window !== 'undefined' && window?.document){
    document.body.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }
  }
  render() {
    // const firstNextClick = () => {
    //   this.setState({ contactForm: '2' });
    //   this.setState({ subPage: 'fourth' });
    //   if (window !== undefined) window.scrollTo({ top: 0 });
    // };

    // const firstClick = () => {
    //   if (window !== undefined) window.scrollTo({ top: 0, behavior: 'smooth' });
    //   this.setState({ subPage: 'first' });
    // };
    // const secondClick = () => {
    //   if (window !== undefined) window.scrollTo({ top: 0, behavior: 'smooth' });
    //   this.setState({ subPage: 'second' });
    // };

    // const thirdClick = () => {
    //   if (window !== undefined) window.scrollTo({ top: 0, behavior: 'smooth' });
    //   this.setState({ subPage: 'third' });
    // };

    // const fourthClick = () => {
    //   if (window !== undefined) window.scrollTo({ top: 0, behavior: 'smooth' });
    //   this.setState({ subPage: 'fourth' });
    // };

    // const buttonClick = () => {
    //   var menuText = document.getElementById('menuText');
    //   menuText.classList.add(css.menuText_active);

    //   if (window !== undefined)
    //     window.location.href =
    //       '/s?bounds=69.99494455%2C175.31638722%2C-62.46576751%2C-169.88909712';
    // };

    // const aboutClick = () => {
    //   if (window !== undefined) window.location.href = '/about';
    // };

    // let options = {
    //   sectionClassName: 'section',
    //   anchors: [
    //     'sectionOne',
    //     'sectionTwo',
    //     'sectionThree',
    //     'sectionFour',
    //     'sectionFive',
    //     'sectionLast',
    //   ],
    //   scrollBar: false,
    //   navigation: false,
    //   verticalAlign: false,
    //   sectionPaddingTop: '0px',
    //   sectionPaddingBottom: '0px',
    //   arrowNavigation: true,
    //   delay: 700,
    // };

    // let props = this.props;
    const cookies = new Cookies();

    return (
      <Page
        title="socialbnb FAQs"
        description={
          cookies.get('language') === 'en'
            ? 'FAQ page and frequetly asked questions for your holiday with socialbnb.'
            : 'FAQs und meistgestellte Fragen für deinen Urlaub mit socialbnb. Was muss ich bei einer Buchung mit socialbnb beachten? '
        }
        keywords={
          cookies.get('language') === 'en'
            ? 'FAQ Page, frequently asked questions, where can I find the FAQS, are FAQs usefull, why are FAQs important, FAQs with answers, FAQs for users, FAQs meaning '
            : 'FAQ Seite, häufig gestellte Fragen, wo finde ich die FAQS, sind FAQs nützlich, warum sind FAQs wichtig, FAQs mit Antworten, FAQs für Benutzer, Bedeutung der FAQs'
        }
        schema={{
          '@context': 'http://schema.org',
          '@type': 'QAPage',
          url: 'https://www.socialbnb.org/faq',
          mainEntity: [
            {
              '@type': 'Question',
              name: 'How does socialbnb work?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'socialbnb is an innovative online platform that connects social and ecological projects with travelers from all over the world. Many of these projects have rooms that we offer on our platform as accommodations for travelers. This way, we not only create another source of funding for local projects that are committed to their community but also enable a unique and authentic experience for travelers. With the money spend on your accommodation, projects can realize their goals, such as building schools, planting trees, improving animal welfare, advocating for more equality and much more. After you have decided on an accommodation, you can submit a booking request via our site, which will then be confirmed by our partner project on site. Next, you pay our service fee and you are ready to go.',
              },
            },
            {
              '@type': 'Question',
              name: 'In which countries can I find a socialbnb?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'socialbnb is a global platform that supports social and ecological projects around the world. Each day more projects are added to establish the vision of a sustainable tourism from which all may profit. Currently we are dealing with the expansion in Europe, because there are a lot of project we want to support as well. The platform is aimed at travelers worldwide and also aims to promote domestic tourism. You can find the latest overview of all socialbnbs.',
              },
            },
            {
              '@type': 'Question',
              name: 'Why should I become a part of socialbnb?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'socialbnb enables you to become part of a community that contributes to sustainable tourism. By booking accommodation through socialbnb, you primarily benefit financially, as the additional revenue goes directly to your projects and supports them. The travelers who find you through socialbnb are often very interested in sustainability and the work of social and environmental organizations. Beyond the booking, the travelers can share their project experiences on further trips, at home among friends, via social media, etc. and thus draw attention to you and, for example, inspire other people to also stay in your projects on their next trips. We at socialbnb also advertise our partner projects via our website and our channels and draw attention to your work.',
              },
            },
          ],
        }}
      >
        <LayoutSingleColumn
          mainColumnClassName={css.layoutWrapperMain}
          topbar={
            <div
              id="topbar"
              ref={this.topbarRef}
              // className={
              //   scrollPosition > 420 ||
              //   (typeof window !== 'undefined' && window.innerWidth < 1200 && scrollPosition > 240)
              //     ? css.topbarScrolled
              //     : css.topbar
              // }
              style={{ position: 'fixed', width: '100vw', top: 0 }}
            >
              <TopbarContainer />
            </div>
          }
          footer={<Footer />}
        >
          <div className={css.Wrapper}>
            <div id="topbar" className={css.topbar} style={{ position: 'fixed', width: '100vw' }}>
              <TopbarContainer />
            </div>

            {/* <div className={css.LeftFooterContent}>
            <ExternalLink
              href="https://www.instagram.com/socialbnb"
              target="_blank"
              className={css.socialmedia}
            >
              <img src={insta} className={css.socialmedia} alt="instagram"></img>
            </ExternalLink>

            <ExternalLink
              href="https://www.facebook.com/Socialbnb"
              target="_blank"
              className={css.socialmedia}
            >
              <img src={fb} className={css.socialmedia} alt="facebook"></img>
            </ExternalLink>

            <ExternalLink
              href="https://www.linkedin.com/company/socialbnb/"
              target="_blank"
              className={css.socialmedia}
            >
              <img src={linkedIn} className={css.socialmedia} alt="facebook"></img>
            </ExternalLink>
          </div> */}
            <div className={css.FaqContainer}>
              <h1 className={css.header}>
                <FormattedMessage id="faq.title" />
              </h1>
              <FAQ></FAQ>
              <br />
              <br />
              <div className={css.section}></div>
            </div>
          </div>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

export default FaqPage;
