import React, { useEffect, useState } from 'react';
import IconReviewStarMini from '../ReviewRatings/IconReviewStarMini/IconReviewStarMini';
import IconReviewSearchPage from './IconReviewSearchPage/iconReviewSearchPage';

const ReviewRatings = ({
  currentListing,
  reviews,
  googleReviews,
  onShowModalFromOutside,
  forSearchPage,
  forRoomType,
  isHostModal,
  displayBothReviews,
}) => {
  const [reviewsAmount, setReviewsAmount] = useState('');
  const [socialbnbRating, setSocialbnbRating] = useState(0);
  const [finalRating, setFinalRating] = useState(0);
  const [isGoogleReviews, setIsGoogleReviews] = useState(false);

  useEffect(() => {
    let googleReviewsFinal = currentListing?.attributes?.publicData?.location?.reviews?.length
      ? currentListing?.attributes?.publicData?.location?.reviews
      : currentListing?.attributes?.publicData?.googleReviews?.length
      ? currentListing?.attributes?.publicData?.googleReviews
      : [];
    let googleReviewsRating =
      typeof currentListing?.rating == 'number'
        ? currentListing?.rating
        : typeof currentListing?.attributes?.publicData?.location?.rating == 'number'
        ? currentListing?.attributes?.publicData?.location?.rating
        : typeof currentListing?.attributes?.publicData?.googleRating == 'number'
        ? currentListing?.attributes?.publicData?.googleRating
        : 0;

    // Handle reviews display
    if (displayBothReviews) {
      const combinedReviews = [...(reviews || []), ...(googleReviewsFinal || [])];
      if (combinedReviews.length > 0) {
        setReviewsAmount({ value: combinedReviews });
      } else {
        setReviewsAmount({ value: 0 });
      }
    }

    // Handle rating calculation (keeping original logic)
    if (reviews?.length > 0) {
      if (!displayBothReviews) {
        setReviewsAmount({ value: reviews });
      }
      const ratings = reviews.map(review => review?.attributes?.rating ?? 1);
      const sum = ratings.reduce((total, rating) => total + rating, 0);
      const average = sum / ratings.length;
      setSocialbnbRating(average.toFixed(1));
      setFinalRating(average.toFixed(1));
      setIsGoogleReviews(false);
    } else if (reviews?.length === 0 && googleReviewsFinal?.length > 0) {
      if (!displayBothReviews) {
        setReviewsAmount({ value: googleReviewsFinal });
      }
      setFinalRating(+googleReviewsRating);
      setIsGoogleReviews(
        currentListing?.attributes?.publicData?.location?.totalGoogleReviews ?? false
      );
    } else if (reviews?.length === 0 && googleReviews?.length === 0) {
      if (!displayBothReviews) {
        setReviewsAmount({ value: 0 });
      }
      setFinalRating(0);
      setIsGoogleReviews(false);
    }
  }, [reviews, googleReviews, currentListing, displayBothReviews]);

  const reviewsNumber =
    reviewsAmount && reviewsAmount?.value?.length > 0 ? reviewsAmount?.value?.length : 0;

  if (
    typeof currentListing?.attributes?.publicData?.location?.rating !== 'number' &&
    typeof currentListing?.attributes?.publicData?.googleRating !== 'number' &&
    forSearchPage &&
    finalRating === 0
  ) {
    return <></>;
  }

  return (
    <div
      data-elb-accomodation={`rating:${finalRating};reviews:${reviewsNumber}`}
      data-elbaction="wait(1000):reviewsLoaded"
    >
      {!(forSearchPage || forRoomType) ? (
        <IconReviewStarMini
          value={finalRating[2] === 0 ? finalRating[0] : finalRating}
          reviewsAmount={reviewsAmount}
          onShowModalFromOutside={onShowModalFromOutside}
          isGoogleReviews={isGoogleReviews}
          isHostModal={isHostModal}
        />
      ) : finalRating == 0 && reviewsAmount?.value?.length == 0 ? (
        <></>
      ) : (
        <>
          <IconReviewSearchPage
            value={finalRating[2] === 0 ? finalRating[0] : finalRating}
            reviewsAmount={reviewsAmount}
            onShowModalFromOutside={onShowModalFromOutside}
            forRoomType={forRoomType}
          />
        </>
      )}
    </div>
  );
};

export default ReviewRatings;
