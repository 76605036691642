import React , { useEffect, useState }  from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './logo.module.css'
import { useConfiguration } from '../../context/configurationContext';
import { ResponsiveImage } from '../../components/';
import LogoImage from './logo_final.svg';
import LogoWhite from './logo_weiss_schrift_bild.svg';
import IconLogo from './IconLogo';
const Logo = props => {
  const { className, format, ...rest } = props;
  const config = useConfiguration();
  const mobileClasses = classNames(css.logoMobile, className);

  const [scrollPosition, setScrollPosition] = useState(0);
  var wheelEvent = null;
  var position = 0;
  useEffect(()=> {
  wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
  window.addEventListener(wheelEvent, changeCss, false);
  },[])
  const changeCss = () => {
    if(typeof window !== 'undefined' && window?.document){
    position = document.body.scrollTop;
    setScrollPosition(position);
    }
  };

  if (format === 'desktop') {
    return <img className={css.logo} src={LogoImage} alt={config.siteTitle} {...rest} />;
  }
  if (format === 'white') {
    return <img className={css.logoWhite} src={LogoWhite} alt={config.siteTitle} {...rest} />;
  }

  return <IconLogo className={mobileClasses} {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile', 'white']),
};

export default Logo;
