import React, { useState, useEffect } from 'react';
import css from './OurMission.module.css';
import { FormattedMessage } from 'react-intl';
/*assets*/
import houses from './images/Rectangle+60.png';
import ballons from './images/Rectangle+59.png';
/* Components */
import SectionStepsNew from '../SectionSteps/SectionStepsNew';
import { NavLink, useParams } from 'react-router-dom';
/*assets*/

const OurMission = () => {
  const [scrollingHeight, setScrollingHeight] = useState(2250);
  const [setWidth] = useState(0);

  const { lang } = useParams();

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 768 && screenSize > 425) {
        setScrollingHeight(screenSize + 1300);
      } else if (screenSize < 425) {
        setScrollingHeight(screenSize + 1500);
      } else {
        setScrollingHeight(screenSize + 1000);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  const handleScrollToListing = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  return (
    <section className={css['mission-steps-container']}>
      <div className={css['mission-container']}>
        <img className={css['ballons']} src={ballons} alt="ballons-cartoon socialbnb" />
        <div className={css['mission-card']}>
          <div className={css['mission-card-text']}>
            <h2 className={css['mission-heading']}>
              <FormattedMessage id="landing.missionHeadline" />
            </h2>
            <p className={css['mission-text-heading']}>
              <FormattedMessage id="landing.missionText.one" />
            </p>
            <p className={css['mission-text']}>
              <FormattedMessage id="landing.missionText.two" />
            </p>
            <p className={css['mission-text']}>
              <FormattedMessage id="landing.missionText.three" />
            </p>
            <NavLink
              to={`/${lang}/s`}
              onClick={handleScrollToListing}
              smooth="true"
              duration={500}
              spy="true"
              exact={true}
              offset={-72}
              className={css['mission-btn']}
              data-elbaction={'click:click lp-explorewithimpactcta'}
            >
              <FormattedMessage id="heroSectionTitle" />
            </NavLink>
          </div>
          <div className={css['mission-card-img']}>
            <img className={css['houses']} src={houses} alt="houses-cartoon socialbnb" />
          </div>
        </div>

        <SectionStepsNew />
      </div>
    </section>
  );
};

export default OurMission;
