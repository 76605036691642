import React, { useEffect } from 'react';
import css from './notification.module.css';
const Notification = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={css.notification}>
      {message}
    </div>
  );
};

export default Notification;
