import { types as sdkTypes } from '../util/sdkLoader.js';
import Cookies from 'universal-cookie';

const { LatLng, LatLngBounds } = sdkTypes;
const cookies = new Cookies();
// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const ita = cookies.get('language') === 'en' ? 'Italy' : 'Italien';
const spa = cookies.get('language') === 'en' ? 'Spain' : 'Spanien';
const ger = cookies.get('language') === 'en' ? 'Germany' : 'Deutschland';
const col = cookies.get('language') === 'en' ? 'Colombia' : 'Kolumbien';

export default [
  {
    id: 'default- Italien',
    predictionPlace: {
      address: ita,
      bounds: new LatLngBounds(
        new LatLng(47.06901179, 22.19523811),
        new LatLng(34.84473041, 2.17461996)
      ),
      origin: new LatLng(43.70040457, 5.95054692),
    },
  },
  {
    id: 'default-Spain',
    predictionPlace: {
      address: spa,
      bounds: new LatLngBounds(new LatLng(45.244, 5.098), new LatLng(35.17300000000001, -12.524)),
      origin: new LatLng(40.46366700000001, -3.74922),
    },
  },
  {
    id: 'default-Deutschland',
    predictionPlace: {
      address: ger,
      bounds: new LatLngBounds(
        new LatLng(55.08149999602162, 15.04189619759281),
        new LatLng(47.27011146235569, 5.86634248758437)
      ),
      origin: new LatLng(51.165691, 10.451526),
    },
  },
  {
    id: 'default-Colombia',
    predictionPlace: {
      address: col,
      bounds: new LatLngBounds(
        new LatLng(13.517799913155, -66.84631221641975),
        new LatLng(-4.227109891488264, -81.83169995879739)
      ),
      origin: new LatLng(4.570868, -74.297333),
    },
  },
  // {
  //   id: 'default-ruka',
  //   predictionPlace: {
  //     address: 'Ruka',
  // bounds: new LatLngBounds(new LatLng(66.16997, 29.16773), new LatLng(66.16095, 29.13572)),
  //   },
  // },
];
