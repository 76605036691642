import React from 'react';
import css from './AmbassadorPage.module.css';
import { FormattedMessage } from 'react-intl';
import imgHowitWorks from '../../assets/ambassadorPage/stepsSection1.webp';

const HowItWorks = () => {
  const listItems = [
    {
      id: 1,
      listTitle: <FormattedMessage id="ImpactWorks.txtTitle1" />,
      listText: <FormattedMessage id="ImpactWorks.txt1" />,
    },
    {
      id: 2,
      listTitle: <FormattedMessage id="ImpactWorks.txtTitle2" />,
      listText: <FormattedMessage id="ImpactWorks.txt2" />,
    },
    {
      id: 3,
      listTitle: <FormattedMessage id="ImpactWorks.txtTitle3" />,
      listText: <FormattedMessage id="ImpactWorks.txt3" />,
    },
    {
      id: 4,
      listTitle: <FormattedMessage id="ImpactWorks.txtTitle4" />,
      listText: <FormattedMessage id="ImpactWorks.txt4" />,
    },
    {
      id: 5,
      listTitle: <FormattedMessage id="ImpactWorks.txtTitle5" />,
      listText: <FormattedMessage id="ImpactWorks.txt5" />,
    },
  ];

  return (
    <div className={css.wrapperPosition}>
      <div className={css.worksSection}>
        <div className={css.sectionTitle}>
          <FormattedMessage id="ImpactWorks.title" />
        </div>
        <div className={css.worksContainer}>
          <div className={css.item1}>
            <FormattedMessage id="ImpactWorks.subTitle" />
          </div>
          <div className={css.item2}>
            <img src={imgHowitWorks} alt="tourism/Tourismus how does it work" />
          </div>
          <div className={css.item3}>
            <ul className={css.howitWorksTxt}>
              {listItems.map(listItem => (
                <li key={listItem.id}>
                  <p className={css.txtWrapper}>
                    <span className={css.itemNumber}>{listItem.id}</span>
                    <span className={css.listTitle}>{listItem.listTitle}</span>
                    <span className={css.listSubtitle}>{listItem.listText}</span>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
