import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './style.module.css';

const GetRoomType = ({ roomTypeRaw, withIcon, withTitle }) => {
  const ProjectRoomtype =
    roomTypeRaw === 'singlebedroom' ? (
      <FormattedMessage id="roomtypes.singlebedroom" />
    ) : roomTypeRaw === 'twobedroom' ? (
      <FormattedMessage id="roomtypes.twobedroom" />
    ) : (roomTypeRaw === 'doublebedroom') || (roomTypeRaw === 'doubleRoom') ? (
      <FormattedMessage id="roomtypes.doublebedroom" />
    ) : roomTypeRaw === 'shared_bedroom' ? (
      <FormattedMessage id="roomtypes.shared_bedroom" />
    ) : roomTypeRaw === 'multi_bedroom' ? (
      <FormattedMessage id="roomtypes.multi_bedroom" />
    ) : roomTypeRaw === 'entire_accomodation' ? (
      <FormattedMessage id="roomtypes.entire_accomodation" />
    ) : roomTypeRaw === 'tent' ? (
      <FormattedMessage id="roomtypes.tent" />
    ) : null;

  const ProjectRoomtypeIcon =
    roomTypeRaw === 'singlebedroom' ? (
      <img
        src={require('../../../assets/icons/roomtypes/onebedroom.png')}
        width="auto"
        height="50px"
        style={{ paddingRight: '20px', paddingLeft: '0px' }}
        alt="onebedroom bnb"
      ></img>
    ) : roomTypeRaw === 'twobedroom' ? (
      <img
        src={require('../../../assets/icons/roomtypes/twobedroom.png')}
        width="auto"
        height="50px"
        style={{ paddingRight: '20px', paddingLeft: '0px' }}
        alt="two bedroom bnb"
      ></img>
    ) : (roomTypeRaw === 'doublebedroom') || (roomTypeRaw === 'doubleRoom') ? (
      <img
        src={require('../../../assets/icons/roomtypes/doublebedroom.png')}
        width="auto"
        height="50px"
        style={{ paddingRight: '20px', paddingLeft: '0px' }}
        alt="doublebedroom bnb"
      ></img>
    ) : roomTypeRaw === 'shared_bedroom' ? (
      <img
        src={require('../../../assets/icons/roomtypes/shared_bedroom.png')}
        width="auto"
        height="50px"
        style={{ paddingRight: '20px', paddingLeft: '0px' }}
        alt="shared_bedroom bnb"
      ></img>
    ) : roomTypeRaw === 'multi_bedroom' ? (
      <img
        src={require('../../../assets/icons/roomtypes/multi_bedroom.png')}
        width="auto"
        height="50px"
        style={{ paddingRight: '20px', paddingLeft: '0px' }}
        alt="multi_bedroom"
      ></img>
    ) : roomTypeRaw === 'entire_accomodation' ? (
      <img
        src={require('../../../assets/icons/roomtypes/entire_accomodation.png')}
        width="auto"
        height="50px"
        style={{ paddingRight: '20px', paddingLeft: '0px' }}
        alt="entire_accomodation"
      ></img>
    ) : roomTypeRaw === 'tent' ? (
      <img
        src={require('../../../assets/icons/roomtypes/camping.png')}
        width="auto"
        height="50px"
        style={{ paddingRight: '20px', paddingLeft: '0px' }}
        alt="camping at socialbnb"
      ></img>
    ) : null;

  return (
    <div className={css.roomTypeWrapper}>
      {withIcon && ProjectRoomtypeIcon}

      {withTitle && <span>{ProjectRoomtype}</span>}
    </div>
  );
};

export default GetRoomType;
