import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleSwitchLanguage } from '../../ducks/ui.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import Cookies from 'universal-cookie';
import styles from './LanguageSwitcher.module.css';
import enFlag from './icons/enFlag.png';
import deFlag from './icons/deFlag.png';

const LanguageSwitcherComponent = ({ onHandleSwitchNewLanguage, currentUser, onUpdateProfile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { lang: currentLang } = useParams();
  const reduxLanguage = useSelector(state => state.ui.language);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (currentLang && currentLang !== reduxLanguage) {
      dispatch(handleSwitchLanguage(currentLang));
    }
  }, [currentLang, reduxLanguage, dispatch]);

  useEffect(() => {
    const savedLanguage =
      new Cookies().get('language') ||
      window.sessionStorage.getItem('sessionLanguage') ||
      navigator?.languages[0];
    const newLang = savedLanguage?.includes('de') ? 'de' : 'en';
    onHandleSwitchNewLanguage(newLang);
    setNewLang(newLang);
  }, []);

  const setNewLang = lang => {
    new Cookies().set('language', lang?.includes('de') ? 'de' : 'en', {
      path: '/',
      maxAge: 7776000,
    });
  };

  const handleLanguageSwitch = async lang => {
    await onHandleSwitchNewLanguage(lang);
    dispatch(handleSwitchLanguage(lang));
    setNewLang(lang);
    setIsOpen(false);
    window.emitter?.emit('sessionLanguage', lang);

    const currentPath = window.location.pathname
      .split('/')
      .slice(2)
      .join('/');
    history.push(`/${lang}/${currentPath}${window.location.search}`);

    currentUser?.id?.uuid
      ? await onUpdateProfile({ protectedData: { language: lang } })
      : window.sessionStorage.setItem('sessionLanguage', lang);
  };

  const languages = {
    en: { flag: enFlag, messageId: 'LanguageSwither.en' },
    de: { flag: deFlag, messageId: 'LanguageSwither.de' },
  };

  return (
    <div className={styles.languageSwitcher} ref={dropdownRef}>
      <button className={styles.switcherButton} onClick={() => setIsOpen(!isOpen)}>
        <img src={languages[reduxLanguage].flag} alt={reduxLanguage} className={styles.flagIcon} />
        <span>
          <FormattedMessage id={languages[reduxLanguage].messageId} />
        </span>
      </button>

      {isOpen && (
        <div className={styles.dropdown}>
          {Object.entries(languages).map(
            ([code, { flag, messageId }]) =>
              code !== reduxLanguage && (
                <button
                  key={code}
                  onClick={() => handleLanguageSwitch(code)}
                  className={styles.languageOption}
                >
                  <img src={flag} alt={code} className={styles.flagIcon} />
                  <span>
                    <FormattedMessage id={messageId} />
                  </span>
                </button>
              )
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  language: state.ui.language,
});

const mapDispatchToProps = dispatch => ({
  onHandleSwitchNewLanguage: newLanguage => dispatch(handleSwitchLanguage(newLanguage)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const LanguageSwitcherWide = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LanguageSwitcherComponent);

export default LanguageSwitcherWide;
