import React, { useEffect, useState } from 'react';
import css from './FooterNew.module.css';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { FooterHeader } from './components/Header';
import { FooterMain } from './components/Main';
import { FirstColumn } from './components/FooterBottomLists/FirstColumn';
import { SecondColumn } from './components/FooterBottomLists/SecondColumn';
import { ThirdColumn } from './components/FooterBottomLists/ThirdColumn';
import { IntroColumn } from './components/FooterBottomLists/IntroColumn';
import NamedLinkNewTab from '../NamedLink/NamedLinkNewTab';
import LanguageSwitcherWide from '../LanguageSwitcherMobile/LanguageSwitcherWide';
import { useLocation } from 'react-router-dom';

const downloadLinks = {
  safetyInfoEN: 'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/safetyInfoEN.pdf',
  safetyInfoDE: 'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/safetyInfoDE.pdf',
  childProtectionPolicy:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/ChildProtectionPolicy.pdf',
  KinderschutzPolicy:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/KinderschutzPolicy.pdf',
  qualityCriteriaDE:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/qualityCriteriaDE.pdf',
  qualityCriteriaEN:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/qualityCriteriaEN.pdf',
  travelerGuidlines:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/TravelerGuidelines.pdf',
  reisendeGuidlines:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/GuidelineFuerReisende.pdf',
  file2022de: 'https://drive.google.com/uc?export=download&id=1uLYtbR0XRa25XjPH3BU7Iw3kjf_wS1QU',
  file2022en: 'https://drive.google.com/uc?export=download&id=1gnQX3QFcWdZMrtgcdNiTiOnb2Zrams-P',
};

const Footer = () => {
  const intl = useIntl();
  const selectedLanguage = intl.locale;
  const [displayAdditionalSections, setDisplayAdditionalSections] = useState(false);

  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    if (typeof window !== 'undefined' && window.document) {
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const location = useLocation();
  const allowedPaths = ['/en', '/de', '/de/', '/en/', '/'];

  useEffect(() => {
    if(location){
      if(allowedPaths.includes(location?.pathname.trim())){
        setDisplayAdditionalSections(true);
      }
    }
  }, [location]);

  return (
    <div
      className={css.footerContainer}
      data-elb="section"
      data-elb-section="name:footerMain;position:1"
      data-elbaction="visible:footer main visible"
    >
      {displayAdditionalSections ? (
        <>
          <FooterHeader />
          <FooterMain />{' '}
        </>
      ) : (
        <></>
      )}
      <div
        className={css.footerBottom}
        data-elb="section"
        data-elb-section="name:footerBottom;position:14"
        data-elbaction="visible:footer bottom visible"
      >
        <div className={css.bottomSectionWrapper}>
          <div className={css.footerBottomContainer}>
            <div>
              <IntroColumn />
            </div>
            <div>
              <FirstColumn scrollToTop={scrollToTop} css={css} />
            </div>
            <div className={css.mobileColumn}>
              <SecondColumn
                selectedLanguage={selectedLanguage}
                css={css}
                downloadLinks={downloadLinks}
              />
            </div>
            <div className={css.mobileColumn}>
              <ThirdColumn css={css} />
            </div>
          </div>
        </div>
        <div
          className={css.bottomSectionDesktop}
          data-elb="section"
          data-elb-section="name:footerDesktopLinks;position:15"
          data-elbaction="visible:footer desktop links visible"
        >
          <div className={css.bottomSection}>
            <div
              className={css.languageSwitcher}
              data-elbaction="click:click footer-language-switcher"
            >
              <LanguageSwitcherWide />
            </div>
            <div className={css.spacer}> </div>

            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'impressum' }}
              className={css.link}
              data-elbaction="click:click footer-imprint"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.imprint" />
              </span>
            </NamedLinkNewTab>
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'agb' }}
              className={css.link}
              data-elbaction="click:click footer-terms"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.termsOfUse" />
              </span>
            </NamedLinkNewTab>
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'datenschutz' }}
              className={css.link}
              data-elbaction="click:click footer-privacy"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </span>
            </NamedLinkNewTab>

            <div className={css.copyrightContainer}>
              <div className={css.spacer}> </div>
              <div className={css.copyright}>
                Copyright <span>&copy;</span> {currentYear} socialbnb
              </div>
            </div>
          </div>
        </div>
        <div
          className={css.bottomSectionMobile}
          data-elb="section"
          data-elb-section="name:footerMobileLinks;position:16"
          data-elbaction="visible:footer mobile links visible"
        >
          <div className={css.mobileLinksContainer}>
            <div
              className={css.languageSwitcher}
              data-elbaction="click:click footer-mobile-language-switcher"
            >
              <LanguageSwitcherWide />
            </div>
            <div className={css.spacer}> </div>

            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'impressum' }}
              className={css.link}
              data-elbaction="click:click footer-mobile-imprint"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.imprint" />
              </span>
            </NamedLinkNewTab>
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'agb' }}
              className={css.link}
              data-elbaction="click:click footer-mobile-terms"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.termsOfUse" />
              </span>
            </NamedLinkNewTab>
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'datenschutz' }}
              className={css.link}
              data-elbaction="click:click footer-mobile-privacy"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </span>
            </NamedLinkNewTab>
          </div>
          <div className={css.copyrightContainer}>
            <div className={css.copyright}>
              Copyright <span>&copy;</span> {currentYear} socialbnb
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
