import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_PROVIDER_COMMISSION, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or negative.
// const isValidCommission = commissionLineItem => {
//   return commissionLineItem.lineTotal instanceof Money && commissionLineItem.lineTotal.amount <= 0;
// };

const LineItemProviderCommissionMaybe = props => {
  const { transaction, lineItems, isProvider, marketplaceName, intl } = props;
  let currentListing = props.currentListing;

  if (!currentListing) {
    currentListing = transaction.listing;
  }

  const providerCommissionLineItem = lineItems.find(
    item => item.code === LINE_ITEM_PROVIDER_COMMISSION && !item.reversal
  );

  // If commission is passed it will be shown as a fee already reduces from the total price
  let commissionItem = null;

  // Sharetribe Web Template is using the default transaction process (https://www.sharetribe.com/docs/concepts/transaction-process/#sharetribe-flex-default-transaction-process)
  // which containt provider commissions so by default the providerCommissionLineItem should exist.
  // If you are not using provider commisison you might want to remove this whole component from OrderBreakdown.js file.
  if (true) {
    //isProvider && providerCommissionLineItem) {
    // if (!isValidCommission(providerCommissionLineItem)) {
    //   // eslint-disable-next-line no-console
    //   console.error('invalid commission line item:', providerCommissionLineItem);
    //   throw new Error('Commission should be present and the value should be zero or negative');
    // }

    const toBePaid = ((transaction.attributes.payinTotal.amount / 100) * 0.15).toFixed(2);
    const onSite = (transaction.attributes.payinTotal.amount / 100).toFixed(2) - toBePaid;
    const onSitePrice = new Money(onSite, transaction.attributes.payinTotal.currency);
    const strikeThroughTotal = props?.strikeThroughTotal ? props?.strikeThroughTotal : false;
    const discountExist =
      transaction?.attributes?.protectedData?.discountData?.discountData != undefined &&
      !location.pathname.endsWith('/checkout');
    const percentageDiscount =
      props?.discountDataMaybe?.discountType == 'percentage' ? true : false;
    const discountPayout = props?.discountDataMaybe?.discountPayout
      ? props?.discountDataMaybe?.discountPayout
      : transaction?.attributes?.protectedData?.discountData?.discountPayout
      ? transaction?.attributes?.protectedData?.discountData?.discountPayout
      : null;
    const discountTotalAmount = props?.discountDataMaybe?.discountTotalAmount
      ? props?.discountDataMaybe?.discountTotalAmount
      : transaction?.attributes?.protectedData?.discountData?.discountTotalAmount
      ? transaction.attributes.protectedData.discountData.discountTotalAmount
      : null;
    commissionItem = (
      <div>
        <div className={css.lineItem2}>
          <div className={css.upfrontPaymentLabel}>
            <div
              className={css.upfrontPaymentTitle}
              style={
                strikeThroughTotal || discountExist
                  ? { textDecoration: 'line-through', color: 'gray' }
                  : null
              }
            >
              <FormattedMessage id="BookingBreakdown.upfront" />
              <span >{intl.locale == 'en' ? '€' + toBePaid : toBePaid + ' €'}</span>
            </div>
            <span className={css.upfrontComissionTitle}>
              <FormattedMessage id="BookingBreakdown.upfrontText" />
            </span>

          </div>
        </div>
        {strikeThroughTotal || discountExist ? (
          <div className={css.lineItem3}>
            <div className={css.discountTextFinal}>
              <FormattedMessage id="TransactionPanel.discountAdvance" />{' '}
            </div>
            <div className={css.discountPriceFinal}>€{Number(discountPayout).toFixed(2)}</div>
          </div>
        ) : null}

        <div className={css.lineItem6}>
          <div
            className={css.itemLabel2}
            style={
              (strikeThroughTotal || discountExist) && !percentageDiscount
                ? { textDecoration: 'line-through', color: 'gray' }
                : null
            }
          >
            <FormattedMessage id="BookingBreakdown.onSite" />
            {intl.locale == 'en' ? '€' + Number(onSitePrice.amount).toFixed(2).toString() : Number(onSitePrice.amount).toFixed(2).toString() + '€'}
          </div>
        </div>
        {strikeThroughTotal || discountExist ? (
          <div className={css.lineItem2}>
            <div className={css.discountTextFinal}>
              <FormattedMessage id="TransactionPanel.discountOnSite" />{' '}
            </div>
            <div className={css.totalPriceDiscount}>
              {intl.locale == 'en'
                ? '€' + (Number(discountTotalAmount) - Number(discountPayout)).toFixed(2)
                : (Number(discountTotalAmount) - Number(discountPayout)).toFixed(2) + '€'}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return commissionItem;
};

LineItemProviderCommissionMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isProvider: bool.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemProviderCommissionMaybe;
