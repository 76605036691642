import React, { useState, useEffect, useRef } from 'react';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import TestimonialsCard from './TestimonialsCard';
import AccommodationCard from './AccommodationCard';
import QuestionCard from './QuestionCard';
import css from './AmbassadorPage.module.css';
import Footer from '../../components/Footer/FooterNew';
import { LayoutWrapperTopbar, LayoutWrapperFooter, LayoutWrapperMain } from '../../components';
import Cookies from 'universal-cookie';
import AmbassadorHeroSection from './AmbassadorHeroSection';
import HowItWorks from './HowItWorks';

const AmbassadorPage = () => {
  const [pageLanguage, setpageLanguage] = useState('en');
  const cookies = new Cookies();
  const topbarRef = useRef(null);

  useEffect(() => {
    const language = cookies.get('language');
    setpageLanguage(language);
  }, []);

  const pageContent = (
    <div style={{ overflow: 'hidden', width: '100vw' }}>
      <div className={css.container}>
        <div className={css.heroSectionOuterWrapper}>
          <AmbassadorHeroSection pageLanguage={pageLanguage} />
        </div>

        <div className={css.accommodationCardOuterWrapper}>
          <AccommodationCard pageLanguage={pageLanguage} />
        </div>

        <div className={css.howitWorksOuterWrapper}>
          <HowItWorks />
        </div>

        <div className={css.testiMonialsOuterWrapper}>
          <div>
            <TestimonialsCard />
          </div>
        </div>

        <div>
          <QuestionCard />
        </div>
      </div>
    </div>
  );
  return (
    <Page
      title={
        cookies.get('language') === 'en'
          ? 'Support our mission and travel for free'
          : 'Unterstütze unsere Mission und reise umsonst'
      }
      description={
        cookies.get('language') === 'en'
          ? "You are on a budget trip? Explore the world, support local projects, and stay for free with socialbnb's Impact Ambassador Program! Experience authentic travel and share your experiences with the community. Apply now and stay for free in unique accommodations!"
          : 'Günstig reisen und die Welt entdecken. Unterstütze lokale Projekte und übernachte kostenlos mit dem Impact Ambassador Programm von socialbnb! Erlebe authentische Reisen und teile deine Erfahrungen mit der Community. Jetzt bewerben und kostenlos in einzigartigen Unterkünften übernachten!'
      }
      keywords={
        cookies.get('language') === 'en'
          ? 'ugc creator, ugc content creator, brands looking for ugc creators, user generated content, content creator, ambassador, budget travel '
          : 'ambassador, hotel ambassador, ugc, ugc plattformen, tourismus jobs, jobs im tourismus, ugc creator, ugc creator werden, kostenlos reisen, günstig reisen'
      }
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BecomeAmbassador',
        url: 'https://socialbnb.org/ambassadorPage',
      }}
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={
          <div id="topbar" ref={topbarRef} style={{ position: 'fixed', width: '100vw', top: 0 }}>
            <TopbarContainer />
          </div>
        }
        footer={<Footer />}
      >
        <div id="my-scrollbar" className={css.Wrapper}>
          {pageContent}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default AmbassadorPage;
