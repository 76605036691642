import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ExternalLink } from '../../../../components';
import NamedLink from '../../../NamedLink/NamedLink';
import NamedLinkNewTab from '../../../../components/NamedLink/NamedLinkNewTab';

export const SecondColumn = ({ selectedLanguage, css, downloadLinks }) => {
  return (
    <div
      className={css.list3}
    >
      <ul className={css.list}>
        <li className={css.listItem}>
          <h3 className={css.heading}>
            <FormattedMessage id="footer.listing.categories" />
          </h3>
        </li>
        <li className={css.listItem} data-elbaction="click:click footer-worldwide">
          <NamedLink
            name="SearchPage"
            to={{
              search: '?bounds=85.051129%2C179.00736739%2C-75.14077719%2C-160.60200761',
            }}
            className={css.link}
          >
            <FormattedMessage id="TopbarDesktop.accomodations" />
          </NamedLink>
        </li>
        <ul
          className={css.ulNew}
          data-elb="section"
          data-elb-section="name:footerRegions;position:3"
          data-elbaction="visible:footer regions visible"
        >
          <li className={css.listItem} data-elbaction="click:click footer-europe">
            <NamedLink
              name="SearchPage"
              to={{
                search: '?bounds=66.75842601%2C44.21184607%2C26.75060877%2C-31.82643116',
              }}
              className={css.link}
            >
              &nbsp; • &nbsp; <FormattedMessage id="europe" />
            </NamedLink>
          </li>
          <li className={css.listItem} data-elbaction="click:click footer-southamerica">
            <NamedLink
              name="SearchPage"
              to={{
                search: '?bounds=34.15056365%2C-25.99456573%2C-59.50348924%2C-150.22941462',
              }}
              className={css.link}
            >
              &nbsp; • &nbsp; <FormattedMessage id="southamerica" />
            </NamedLink>
          </li>
          <li className={css.listItem} data-elbaction="click:click footer-africa">
            <NamedLink
              name="SearchPage"
              to={{
                search: '?bounds=36.59497175%2C52.76846679%2C-36.15117826%2C-34.86957672',
              }}
              className={css.link}
            >
              &nbsp; • &nbsp;
              <FormattedMessage id="africa" />
            </NamedLink>
          </li>
          <li className={css.listItem} data-elbaction="click:click footer-asia">
            <NamedLink
              name="SearchPage"
              to={{
                search: '?bounds=64.09173416%2C169.49644716%2C-14.97311392%2C58.14770588',
              }}
              className={css.link}
            >
              &nbsp; • &nbsp; <FormattedMessage id="asia" />
            </NamedLink>
          </li>
        </ul>
        <li className={css.listItem} data-elbaction="click:click footer-experience">
          <NamedLinkNewTab
            name="CMSPage"
            params={{ pageId: 'impact-experiences' }}
            className={css.link}
          >
            <FormattedMessage id="TopbarDesktop.experience" />
          </NamedLinkNewTab>
        </li>
        <li className={css.listItem} data-elbaction="click:click footer-roundtrips">
          <NamedLinkNewTab name="CMSPage" params={{ pageId: 'impact-trips' }} className={css.link}>
            <FormattedMessage id="TopbarDesktop.roundTrips" />
          </NamedLinkNewTab>
        </li>
      </ul>
    </div>
  );
};
