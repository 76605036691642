import React, { useState, useEffect } from 'react';
import css from './SliderCommunity.module.css';

import { FormattedMessage } from 'react-intl';
import Swipe from 'react-easy-swipe';
import arrowLeft from './images/Vector 2.svg';
import arrowRight from './images/Vector 1.svg';
import hCommas from './images/hCommas.png';
import { useParams } from 'react-router-dom';
import NamedLinkNewTab from '../../../../components/NamedLink/NamedLinkNewTab';

const SliderCommunity = () => {
  const { lang } = useParams();
  //slider data
  const defaultSlideData = [
    {
      index: 0,
      headline: <FormattedMessage id="landing.community.headline2" />,
      subHeadlineNGO: <FormattedMessage id="landing.community.tileOne" />,
      src:
        typeof window !== 'undefined'
          ? window.innerWidth <= 767
            ? require('../../../../assets/landingPage/reviews/Fenny-mobile.webp')
            : require('../../../../assets/landingPage/reviews/Fenny.webp')
          : require('../../../../assets/landingPage/reviews/Fenny.webp'),
    },

    {
      index: 1,
      headline: <FormattedMessage id="landing.community.headline5" />,
      subHeadlineNGO: 'Geo Saison',
      src:
        typeof window !== 'undefined'
          ? window.innerWidth <= 767
            ? require('../../../../assets/landingPage/reviews/GEOSaison-mobile.webp')
            : require('../../../../assets/landingPage/reviews/GEOSaison.webp')
          : require('../../../../assets/landingPage/reviews/GEOSaison.webp'),
    },

    {
      index: 2,
      headline: <FormattedMessage id="landing.community.headline7" />,
      subHeadlineNGO: <FormattedMessage id="landing.community.titleTwo" />,
      name: 'Zonca-Ecovillage',
      link:
        '/l/zonca-ecovillage-in-piemonte-italy-casa-in-mezzo-entire-accomodation/60f572e2-ed29-4a70-8aa6-232260f00ac7',
      linkName: 'ListingPage',
      linkParams: {
        slug: 'zonca-ecovillage-in-piemonte-italy-casa-in-mezzo-entire-accomodation',
        id: '60f572e2-ed29-4a70-8aa6-232260f00ac7',
      },
      src:
        typeof window !== 'undefined'
          ? window.innerWidth <= 767
            ? require('../../../../assets/landingPage/reviews/LindaZonca-mobile.webp')
            : require('../../../../assets/landingPage/reviews/LindaZonca.webp')
          : require('../../../../assets/landingPage/reviews/LindaZonca.webp'),
    },
    {
      index: 3,
      headline: <FormattedMessage id="landing.community.headline8" />,
      subHeadlineNGO: <FormattedMessage id="landing.community.titleThree" />,
      name: 'Kawsay, Peru',
      link: '/l/kawsay-biological-station-twobedroom/61d1d97e-cbe5-4e99-9916-cb81ef66de46',
      linkName: 'ListingPage',
      linkParams: {
        slug: 'kawsay-biological-station-twobedroom',
        id: '61d1d97e-cbe5-4e99-9916-cb81ef66de46',
      },
      src:
        typeof window !== 'undefined'
          ? window.innerWidth <= 767
            ? require('../../../../assets/landingPage/reviews/PeruRaulbello-mobile.webp')
            : require('../../../../assets/landingPage/reviews/PeruRaulbello.webp')
          : require('../../../../assets/landingPage/reviews/PeruRaulbello.webp'),
    },
    {
      index: 4,
      headline: <FormattedMessage id="landing.community.headline6" />,
      subHeadlineNGO: <FormattedMessage id="Slider2.mildred" />,
      name: 'Intiwawa',
      link: '/l/intiwawa-arequipa-intihouse-shared-bedroom/60781b58-6b2c-4506-80b5-213855343cef',
      linkName: 'ListingPage',
      linkParams: {
        slug: 'intiwawa-arequipa-intihouse-shared-bedroom',
        id: '60781b58-6b2c-4506-80b5-213855343cef',
      },
      src:
        typeof window !== 'undefined'
          ? window.innerWidth <= 767
            ? require('../../../../assets/landingPage/reviews/Mildred-mobile.webp')
            : require('../../../../assets/landingPage/reviews/Mildred.webp')
          : require('../../../../assets/landingPage/reviews/Mildred.webp'),
    },
    {
      index: 5,
      headline: <FormattedMessage id="landing.community.headline3" />,
      subHeadlineNGO: <FormattedMessage id="landing.community.subheadline5" />,
      name: 'YAWE',
      link: '/l/yawe-foundation-singlebedroom/6089b73e-26df-4dd8-b53e-493cbb2be646',
      linkName: 'ListingPage',
      linkParams: {
        slug: 'yawe-foundation-singlebedroom',
        id: '6089b73e-26df-4dd8-b53e-493cbb2be646',
      },
      src:
        typeof window !== 'undefined'
          ? window.innerWidth <= 767
            ? require('../../../../assets/landingPage/reviews/markus-mobile.webp')
            : require('../../../../assets/landingPage/reviews/markus.webp')
          : require('../../../../assets/landingPage/reviews/markus.webp'),
    },
  ];

  const [slideData, setSlideData] = useState(defaultSlideData);
  const [autoplay, setAutoplay] = useState(true);

  // Update slideData each time `lang` changes
  useEffect(() => {
    setSlideData(defaultSlideData);
  }, [lang]);

  //logic carousel

  const [index, setIndex] = useState(0);

  //modular f(x) , returns positive value
  const mod = (n, m) => {
    let result = n % m;

    return result >= 0 ? result : result + m;
  };
  // increase btn
  const increaseHandler = () => {
    setIndex((index + 1) % slideData.length);
    setAutoplay(false);
  };

  // decrease btn

  const decreaseHandler = () => {
    setIndex(mod(index - 1, slideData.length));
    setAutoplay(false);
  };

  //swipe logic

  const handleSwipeLeft = () => {
    console.log('swiped left');
    setIndex((index + 1) % slideData.length);
    setAutoplay(false);
  };

  const handleSwipeRight = () => {
    console.log('swiped right');
    setIndex(mod(index - 1, slideData.length));
    setAutoplay(false);
  };

  // autoplay
  useEffect(() => {
    if (autoplay) {
      setTimeout(() => {
        setIndex((index + 1) % slideData.length);
      }, 5000);
    } else return;
  }, [index, autoplay]);

  // create the slider elements and based on the active index add styles to.

  const sliderCards = slideData.map((slide, i) => {
    const indexLeft = mod(index - 1, slideData.length);
    const indexRight = mod(index + 1, slideData.length);

    let className = '';
    if (i === indexLeft) {
      className = `${css['slide']} ${css['slide-0']}`;
    } else if (i === index) {
      className = `${css['slide']} ${css['slide-1']}`;
    } else if (i === indexRight) {
      className = `${css['slide']} ${css['slide-2']}`;
    } else if (i === (indexRight + 1) % slideData.length) {
      className = `${css['slide']} ${css['slide-3']} `;
    } else if (i === (indexRight + 2) % slideData.length) {
      className = `${css['slide']} ${css['slide-4']} `;
    } else if (i === (indexRight + 3) % slideData.length) {
      className = `${css['slide']} ${css['slide-5']}`;
    } else {
      className = `${css['slide']} ${css['hidden']}`;
    }

    return (
      <div id={slide.index} key={slide.index} className={className}>
        <img className={css['slide-img']} src={slide.src} alt="slides" />
        <div className={css['slide-text']}>
          <img className={css['comma']} src={hCommas} alt="commas" />
          <p className={css['slide-headline']}>{slide.headline}</p>
          <p className={css['slide-subHeadline']}>
            {slide.subHeadlineNGO}{' '}
            {slide?.linkName && (
              <NamedLinkNewTab
                name={slide.linkName}
                params={slide.linkParams}
                className={css['link-name']}
              >
                {slide.name}
              </NamedLinkNewTab>
            )}
          </p>
        </div>
      </div>
    );
  });

  return (
    <div className={css['slider-container']}>
      <div className={css['slider-buttons']}>
        <img
          onClick={decreaseHandler}
          className={css['slider-button']}
          src={arrowLeft}
          alt="arrowLeft - socialbnb"
        />
        <img
          onClick={increaseHandler}
          className={css['slider-button']}
          src={arrowRight}
          alt="arrowRight - socialbnb"
        />
      </div>
      <h2 className={css['slider-title']}>
        <FormattedMessage id="landing.community" />{' '}
      </h2>
      <div className={css['slider-cards_title']}>
        <Swipe
          onSwipeLeft={handleSwipeLeft}
          onSwipeRight={handleSwipeRight}
          allowMouseEvents={true}
          tolerance={50}
          delta={10}
          preventdefaulttouchmoveevent="false"
        >
          <div className={css['slider-cards']}> {sliderCards}</div>
        </Swipe>
      </div>
    </div>
  );
};

export default SliderCommunity;
