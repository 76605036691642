/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
import { FormattedMessage } from 'react-intl';
export const listingFields = [
  {
    key: 'roomtype',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'singlebedroom',
        label: (
          <>
           {/*  <img
              src={require('../assets/icons/roomtypes/onebedroom.png')}
              width="40px"
              alt="Single bedroom bnb"
            ></img>{' '} */}
            <FormattedMessage id="roomtypes.singlebedroom" />
          </>
        ),
      },
      {
        option: 'doublebedroom',
        label: (
          <>
           {/*  <img
              src={require('../assets/icons/roomtypes/doublebedroom.png')}
              width="40px"
              alt="Doublebedroom bnb"
            ></img>{' '} */}
            <FormattedMessage id="roomtypes.doublebedroom" />
          </>
        ),
      },
      {
        option: 'twobedroom',
        label: (
          <>
            {/* <img
              src={require('../assets/icons/roomtypes/twobedroom.png')}
              width="40px"
              alt="Twobedroom bnb"
            ></img>{' '} */}
            <FormattedMessage id="roomtypes.twobedroom" />
          </>
        ),
      },
      {
        option: 'shared_bedroom',
        label: (
          <>
          {/*   <img
              src={require('../assets/icons/roomtypes/shared_bedroom.png')}
              width="40px"
              alt="Shared bedroom bnb"
            ></img>{' '} */}
            <FormattedMessage id="roomtypes.shared_bedroom" />
          </>
        ),
      },
      {
        option: 'multi_bedroom',
        label: (
          <>
           {/*  <img
              src={require('../assets/icons/roomtypes/multi_bedroom.png')}
              width="40px"
              alt="Multi bedroom bnb"
            ></img>{' '} */}
            <FormattedMessage id="roomtypes.multi_bedroom" />
          </>
        ),
      },
      {
        option: 'entire_accomodation',
        label: (
          <>
           {/*  <img
              src={require('../assets/icons/roomtypes/entire_accomodation.png')}
              width="40px"
              alt="Entire accomodation bnb"
            ></img>{' '} */}
            <FormattedMessage id="roomtypes.entire_accomodation" />
          </>
        ),
      },
      {
        option: 'tent',
        label: (
          <>
          {/*   <img src={require('../assets/icons/roomtypes/camping.png')} width="40px" alt="Eco-Friendly Stays for Sustainable Travelers"></img>{' '}
            */} <FormattedMessage id="roomtypes.tent" />
          </>
        ),
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: <FormattedMessage id="FilterComponent.RoomType" />,
      group: 'primary',
    },
    showConfig: {
      label: <FormattedMessage id="FilterComponent.Topic" />,
      group: 'secondary',
      isDetail: true,
    },
    saveConfig: {
      label: <FormattedMessage id="FilterComponent.RoomType" />,
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select an option.',
    },
  },

  {
    key: 'accomodationtype',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'B&B', label: <FormattedMessage id="accomodationFilter.B&B" /> },
      { option: 'glamping', label: <FormattedMessage id="accomodationFilter.glamping" /> },
      { option: 'hostel', label: <FormattedMessage id="accomodationFilter.hostel" /> },
      { option: 'hotel', label: <FormattedMessage id="accomodationFilter.hotel" /> },
      { option: 'homestay', label: <FormattedMessage id="accomodationFilter.homestay" /> },
      { option: 'holiday', label: <FormattedMessage id="accomodationFilter.holiday" /> },
      { option: 'apartment', label: <FormattedMessage id="accomodationFilter.apartment" /> },
      { option: 'tinyHouse', label: <FormattedMessage id="accomodationFilter.tinyHouse" /> },
      { option: 'camping', label: <FormattedMessage id="accomodationFilter.camping" /> },
      { option: 'banglow', label: <FormattedMessage id="accomodationFilter.banglow" /> },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: <FormattedMessage id="FilterComponent.AccomodationType" />,
      group: 'primary',
    },
    showConfig: {
      label: <FormattedMessage id="FilterComponent.AccomodationType" />,
      isDetail: true,
    },
    saveConfig: {
      label: <FormattedMessage id="FilterComponent.AccomodationType" />,
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select an option.',
    },
  },
  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'Bildung',
        label: (
          <>
           {/*  <img src={require('../assets/categories/education.webp')} width="40px" alt="Eco-Conscious Travel Options - socialbnb"></img>{' '}
             */}<FormattedMessage id="filter.category.education" />
          </>
        ),
      },
      {
        option: 'Equality',
        label: (
          <>
           {/*  <img src={require('../assets/categories/equality.webp')} width="40px" alt="Supporting Social Projects Through Travel - socialbnb"></img>{' '}
             */}<FormattedMessage id="filter.category.equality" />
          </>
        ),
      },
      {
        option: 'Naturschutz',
        label: (
          <>
           {/*  <img src={require('../assets/categories/environment.webp')} width="40px" alt="Environmentally Conscious Accommodations - socialbnb"></img>{' '}
           */}  <FormattedMessage id="filter.category.environment" />
          </>
        ),
      },
      {
        option: 'Health',
        label: (
          <>
          {/*   <img src={require('../assets/categories/health.webp')} width="40px" alt="Wellness-Focused Accommodations - socialbnb"></img>{' '}
           */}  <FormattedMessage id="filter.category.health" />
          </>
        ),
      },
      {
        option: 'Tierschutz',
        label: (
          <>
           {/*  <img src={require('../assets/categories/animal.webp')} width="40px" alt="Animal-Welcoming Accommodations: Where Furry Friends Feel at Home - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.category.animalCare" />
          </>
        ),
      },
      {
        option: 'Sports',
        label: (
          <>
          {/*   <img src={require('../assets/categories/sports.webp')} width="40px" alt="Sports-Inspired Accommodations for Active Travelers - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.category.sports" />
          </>
        ),
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: <FormattedMessage id="EditListingDescriptionForm.categoryLabel" />,
      searchMode: 'has_all',
      group: 'primary',
    },
    showConfig: {
      label: <FormattedMessage id="FilterComponent.Topic" />,
    },
    saveConfig: {
      label: <FormattedMessage id="FilterComponent.RoomType" />,
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },/*
  {
    key: 'characteristics',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'comfort',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/Komfort.webp')}
              width="40px"
              alt="Relaxing Getaways: Comfort-Centric Lodging Choices - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.comfort" />
          </>
        ),
      },
      {
        option: 'adventure',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/adventure.webp')}
              width="40px"
              alt="Wilderness-Friendly Stays: Accommodations Geared Towards Adventure - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.adventure" />
          </>
        ),
      },
      {
        option: 'nature',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/Nature.webp')}
              width="40px"
              alt="Escape to Nature: Lodging Choices Surrounded by Natural Beauty - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.nature" />
          </>
        ),
      },
      {
        option: 'near_beach',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/Beach.webp')}
              width="40px"
              alt="Seaside Retreats: Lodging Choices with Ocean Views - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.near_beach" />
          </>
        ),
      },
      {
        option: 'animallovers',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/Animals.webp')}
              width="40px"
              alt="Animal-Welcoming Accommodations: Where Furry Friends Feel at Home - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.animallovers" />
          </>
        ),
      },
      {
        option: 'sportlovers',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/Sport.webp')}
              width="40px"
              alt="Athlete-Friendly Lodging Choices - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.sportlovers" />
          </>
        ),
      },

      {
        option: 'familyfriendly',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/Family.webp')}
              width="40px"
              alt="Home Away from Home: Lodging Choices Ideal for Family Bonding - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.familyfriendly" />
          </>
        ),
      },

      {
        option: 'trekking',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/Trekking.webp')}
              width="40px"
              alt="Trekking Haven: Accommodations for Outdoor Enthusiasts - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.trekking" />
          </>
        ),
      },
      {
        option: 'culture',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/Theatre.webp')}
              width="40px"
              alt="Theatre-Inspired Accommodations: Where Drama Meets Comfort - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.culture" />
          </>
        ),
      },
      {
        option: 'barrier_free',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/barrierefreiheit.webp')}
              width="40px"
              alt="Barrier-Free Stays: Lodging Choices for Universal Accessibility - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.barrier_free" />
          </>
        ),
      },
      {
        option: 'accessibility',
        label: (
          <>
            <img src={require('../assets/icons/characteristics/Bus.webp')} width="40px" alt="Accessible Retreats: Lodging Choices Designed for All - socialbnb"></img>{' '}
            <FormattedMessage id="filter.characteristics.accessibility" />
          </>
        ),
      },
      {
        option: 'projecttours_possible',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/Projektbesuch_.webp')}
              width="40px"
              alt="Project Tour: Explore Accommodations with Accessibility in Mind - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.projecttours_possible" />
          </>
        ),
      },
      {
        option: 'projecttours_impossible',
        label: (
          <>
            <img
              src={require('../assets/icons/characteristics/Projektbesuch_Cancel.webp')}
              width="40px"
              alt="Accessible Accommodations: Your Gateway to Inclusive Travel - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.characteristics.projecttours_impossible" />
          </>
        ),
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Highlights',
      group: 'primary',
    },
    showConfig: {
      label: 'Highlights',
      isDetail: true,
    },
    saveConfig: {
      label: 'Features',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select an option.',
    },
  }, */
  {
    key: 'food',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'breakfast_inclusive',
        label: (
          <>
           {/*  <img src={require('../assets/icons/meals/Breakfast.webp')} width="40px" alt=""></img>{' '}
            */} <FormattedMessage id="filter.food.breakfast_inclusive" />
          </>
        ),
      },
     /*  {
        option: 'breakfast_for_sale',
        label: (
          <>
           <img
              src={require('../assets/icons/meals/Breakfast-paid.webp')}
              width="40px"
              alt="Complimentary Breakfast Included: Start Your Day Right - socialbnb"
            ></img>{' '}
             <FormattedMessage id="filter.food.breakfast_for_sale" />
          </>
        ),
      }, */
      {
        option: 'lunch_inclusive',
        label: (
          <>
           {/*  <img src={require('../assets/icons/meals/Lunch.webp')} width="40px" alt="Lunch Included: Savor Every Moment of Your Stay - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.food.lunch_inclusive" />
          </>
        ),
      },
   /*    {
        option: 'lunch_for_sale',
        label: (
          <>
            <img src={require('../assets/icons/meals/Lunch-paid.webp')} width="40px" alt="Paid Lunch Option Available: Convenient Dining During Your Stay - socialbnb"></img>{' '}
             <FormattedMessage id="filter.food.lunch_for_sale" />
          </>
        ),
      }, */

      {
        option: 'dinner_inclusive',
        label: (
          <>
          {/*   <img src={require('../assets/icons/meals/Abendessen.webp')} width="40px" alt="Dinner Included: Delight in Evening Meals During Your Stay - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.food.dinner_inclusive" />
          </>
        ),
      },
 /*      {
        option: 'dinner_for_sale',
        label: (
          <>
             <img
              src={require('../assets/icons/meals/Abendessen-paid.webp')}
              width="40px"
              alt="Upgrade Your Evening: Purchase Dinner for Added Comfort - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.food.dinner_for_sale" />
          </>
        ),
      }, */
      {
        option: 'vegetarian_options',
        label: (
          <>
          {/*   <img src={require('../assets/icons/meals/Vegetarian_.webp')} width="40px" alt="Vegetarian-Friendly: Enjoy a Variety of Plant-Based Options - socialbnb"></img>{' '}
           */}  <FormattedMessage id="filter.food.vegetarian_options" />
          </>
        ),
      },
      {
        option: 'vegan_options',
        label: (
          <>
          {/*   <img src={require('../assets/icons/meals/Vegan.webp')} width="40px" alt="Vegan-Friendly: Indulge in Our Plant-Powered Menu Selection - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.food.vegan_options" />
          </>
        ),
      },

 /*      {
        option: 'coffee_tee',
        label: (
          <>
            <img src={require('../assets/icons/meals/Coffee_.webp')} width="40px" alt="Coffee and Tea Station: Enjoy a Refreshing Beverage Anytime - sociabnb"></img>{' '}
             <FormattedMessage id="filter.food.coffee_tee" />
          </>
        ),
      }, */
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: <FormattedMessage id="FilterComponent.Food" />,
      group: 'secondary',
    },
    showConfig: {
      label: <FormattedMessage id="FilterComponent.Food" />,
      isDetail: true,
    },
    saveConfig: {
      label: <FormattedMessage id="FilterComponent.Food" />,
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select an option.',
    },
  },
  {
    key: 'amenities',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
 /*      {
        option: 'light',
        label: (
          <>
            <img src={require('../assets/icons/equipment/Lampe.webp')} width="40px" alt="Lighting Included: Brighten Your Accommodations at No Extra Cost - socialbnb"></img>{' '}
             <FormattedMessage id="filter.amenities.light" />
          </>
        ),
      }, */
      /* {
        option: 'electricity',
        label: (
          <>
            <img
              src={require('../assets/icons/equipment/Electricity.webp')}
              width="40px"
              alt=" Electricity Included: Illuminate Your Stay with Ease - socialbnb"
            ></img>{' '}
            <FormattedMessage id="filter.amenities.electricity" />
          </>
        ),
      }, */

/*       {
        option: 'bedsheets',
        label: (
          <>
           <img
              src={require('../assets/icons/equipment/Bettwäsche_.webp')}
              width="40px"
              alt="Fresh Bedsheets Provided: Enjoy Clean Comfort Every Night - socialbnb"
            ></img>{' '}
          <FormattedMessage id="filter.amenities.bedsheets" />
          </>
        ),
      }, */
    /*   {
        option: 'sink',
        label: (
          <>
          <img src={require('../assets/icons/equipment/Sink.webp')} width="40px" alt="Convenient Sink Access: Stay Refreshed Throughout Your Stay - socialbnb"></img>{' '}
             <FormattedMessage id="filter.amenities.sink" />
          </>
        ),
      }, */
    /*   {
        option: 'shower',
        label: (
          <>
             <img src={require('../assets/icons/equipment/Shower.webp')} width="40px" alt="Refreshing Shower Access: Revitalize Your Day with Ease - socialbnb"></img>{' '}
            <FormattedMessage id="filter.amenities.shower" />
          </>
        ),
      }, */
   /*    {
        option: 'toilet',
        label: (
          <>
            <img src={require('../assets/icons/equipment/Toilet.webp')} width="40px" alt="Private Toilet Access: Ensuring Your Comfort and Convenience - socialbnb"></img>{' '}
             <FormattedMessage id="filter.amenities.toilet" />
          </>
        ),
      }, */

      {
        option: 'privat_bathroom',
        label: (
          <>
           {/*  <img src={require('../assets/icons/equipment/Bathroom.webp')} width="40px" alt="Private Bathroom Access: Enjoy Your Personal Sanctuary - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.amenities.privat_bathroom" />
          </>
        ),
      },
    /*   {
        option: 'shared_bathroom',
        label: (
          <>
            <img
              src={require('../assets/icons/equipment/Bathroom-Shared.webp')}
              width="40px"
              alt="Shared Bathroom Facilities: Convenient Access for Every Guest - socialbnb"
            ></img>{' '}
             <FormattedMessage id="filter.amenities.shared_bathroom" />
          </>
        ),
      }, */
      {
        option: 'private_kitchen',
        label: (
          <>
           {/*  <img src={require('../assets/icons/equipment/Kitchen.webp')} width="40px" alt="Exclusive Kitchen Access: Prepare Meals in Your Own Private Space - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.amenities.private_kitchen" />
          </>
        ),
      },
    /*   {
        option: 'shared_kitchen',
        label: (
          <>
            <img
              src={require('../assets/icons/equipment/SharedKitchen.webp')}
              width="40px"
              alt="Shared Culinary Space: Collaborative Cooking for a Social Experience - socialbnb"
            ></img>{' '}
          <FormattedMessage id="filter.amenities.shared_kitchen" />
          </>
        ),
      }, */
      {
        option: 'internet',
        label: (
          <>
          {/*   <img src={require('../assets/icons/equipment/Wifi.webp')} width="40px" alt="Internet Access: Stay Connected Throughout Your Stay - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.amenities.internet" />
          </>
        ),
      },
    /*   {
        option: 'computer',
        label: (
          <>
            <img src={require('../assets/icons/equipment/Computer.webp')} width="40px" alt="Access to Computers: Stay Productive During Your Stay - socialbnb"></img>{' '}
            <FormattedMessage id="filter.amenities.computer" />
          </>
        ),
      }, */
      /* {
        option: 'tv',
        label: (
          <>
             <img src={require('../assets/icons/equipment/Tv.webp')} width="40px" alt="TV Included: Relax and Unwind with Your Favorite Shows - socialbnb"></img>{' '}
            <FormattedMessage id="filter.amenities.tv" />
          </>
        ),
      }, */
      {
        option: 'mosquito_net',
        label: (
          <>
          {/*   <img src={require('../assets/icons/equipment/Moskito.webp')} width="40px" alt="Stay Protected: Enjoy a Mosquito-Free Environment - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.amenities.mosquito_net" />
          </>
        ),
      },
     /*  {
        option: 'towels',
        label: (
          <>
          <img
              src={require('../assets/icons/equipment/Handtücher_.webp')}
              width="40px"
              alt="Fresh Towels Provided: Stay Clean and Comfortable Throughout Your Stay - socialbnb"
            ></img>{' '}
        <FormattedMessage id="filter.amenities.towels" />
          </>
        ),
      }, */
   /*    {
        option: 'fan',
        label: (
          <>
             <img
              src={require('../assets/icons/equipment/Ventilator_.webp')}
              width="40px"
              alt=""
            ></img>{' '}
           <FormattedMessage id="filter.amenities.fan" />
          </>
        ),
      }, */
      {
        option: 'air_conditioning',
        label: (
          <>
           {/*  <img
              src={require('../assets/icons/equipment/Klimaanlage.webp')}
              width="40px"
              alt="Fan Included: Beat the Heat with Convenient Cooling Amenities - socialbnb"
            ></img>{' '}
           */}  <FormattedMessage id="filter.amenities.air_conditioning" />
          </>
        ),
      },

    /*   {
        option: 'heating',
        label: (
          <>
             <img src={require('../assets/icons/equipment/Heater.webp')} width="40px" alt="Stay Warm and Cozy: Access to Heating During Your Stay - socialbnb"></img>{' '}
             <FormattedMessage id="filter.amenities.heating" />
          </>
        ),
      }, */
    /*   {
        option: 'safe',
        label: (
          <>
            <img
              src={require('../assets/icons/equipment/Security_Locker.webp')}
              width="40px"
              alt="Safety First: Ensure the Security of Your Items with a Provided Safe - socialbnb"
            ></img>{' '}
             <FormattedMessage id="filter.amenities.safe" />
          </>
        ),
      }, */

   /*    {
        option: 'wardrobe',
        label: (
          <>
             <img src={require('../assets/icons/equipment/Dresser.webp')} width="40px" alt="Organized Living: Utilize a Wardrobe for Your Clothing Storage - socialbnb"></img>{' '}
            <FormattedMessage id="filter.amenities.wardrobe" />
          </>
        ),
      }, */

      {
        option: 'parking',
        label: (
          <>
           {/*  <img src={require('../assets/icons/equipment/Parking.webp')} width="40px" alt="Parking Included: Enjoy Hassle-Free Parking Throughout Your Visit - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.amenities.parking" />
          </>
        ),
      },

    /*   {
        option: 'luggage_storage',
        label: (
          <>
             <img src={require('../assets/icons/equipment/Luggage.webp')} width="40px" alt="Secure Luggage Storage: Keep Your Belongings Safe During Your Stay - socialbnb"></img>{' '}
           <FormattedMessage id="filter.amenities.luggage_storage" />
          </>
        ),
      }, */

    /*   {
        option: 'laundry_facilities',
        label: (
          <>
             <img
              src={require('../assets/icons/equipment/Waschküche_.webp')}
              width="40px"
              alt="Laundry Made Easy: Access to On-Site Facilities for Your Convenience - socialbnb"
            ></img>{' '}
             <FormattedMessage id="filter.amenities.laundry_facilities" />
          </>
        ),
      }, */
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: <FormattedMessage id="FilterComponent.Equipment" />,
      group: 'secondary',
    },
    showConfig: {
      label: <FormattedMessage id="FilterComponent.Equipment" />,
      isDetail: true,
    },
    saveConfig: {
      label: <FormattedMessage id="FilterComponent.Equipment" />,
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select an option.',
    },
  },
 /*  {
    key: 'customCurrency',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'Dollar', label: '$ Dollar ' },
      { option: 'Euro', label: '€ Euro' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: <FormattedMessage id="FilterComponent.Currency" />,
      group: 'secondary',
    },
    showConfig: {
      label: <FormattedMessage id="FilterComponent.Currency" />,
      isDetail: true,
    },
    saveConfig: {
      label: <FormattedMessage id="FilterComponent.Currency" />,
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select an option.',
    },
  }, */

  {
    key: 'surroundings',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'supermarket',
        label: (
          <>
          {/*   <img
              src={require('../assets/icons/surroundings/Supermarket.png')}
              width="40px"
              alt="Convenient Supermarket Access - socialbnb"
            ></img>{' '} */}
            <FormattedMessage id="filter.surroundings.supermarket" />
          </>
        ),
      },
      {
        option: 'restaurant',
        label: (
          <>
 {/*            <img
              src={require('../assets/icons/surroundings/Restaurant.png')}
              width="40px"
              alt="Local Restaurant Options - socialbnb"
            ></img>{' '} */}
            <FormattedMessage id="filter.surroundings.restaurant" />
          </>
        ),
      },
      {
        option: 'facility',
        label: (
          <>
            {/* <img src={require('../assets/icons/surroundings/Doctor.png')} width="40px" alt="Facilities Nearby for Your Convenience - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.surroundings.facility" />
          </>
        ),
      },
      {
        option: 'washing',
        label: (
          <>
          {/*   <img
              src={require('../assets/icons/equipment/Waschküche_.webp')}
              width="40px"
              alt="Washing Facilities Accessible Nearby - socialbnb"
            ></img>{' '}
            */} <FormattedMessage id="filter.surroundings.washing" />
          </>
        ),
      },

      {
        option: 'bank',
        label: (
          <>
           {/*  <img src={require('../assets/icons/surroundings/Bank.png')} width="40px" alt="Banking Services in the Area - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.surroundings.bank" />
          </>
        ),
      },
      {
        option: 'public_transport',
        label: (
          <>
           {/*  <img src={require('../assets/icons/characteristics/Bus.webp')} width="40px" alt="Easy Access to Public Transport - socialbnb"></img>{' '}
            */} <FormattedMessage id="filter.surroundings.public_transport" />
          </>
        ),
      },
      {
        option: 'tour',
        label: (
          <>
          {/*   <img
              src={require('../assets/icons/surroundings/Touranbieter.png')}
              width="40px"
              alt="Tour Opportunities in the Vicinity - socialbnb"
            ></img>{' '}
           */}  <FormattedMessage id="filter.surroundings.tour" />
          </>
        ),
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: <FormattedMessage id="FilterComponent.Surroundings" />,
      group: 'secondary',
    },
    showConfig: {
      label: <FormattedMessage id="FilterComponent.Surroundings" />,
      isDetail: true,
    },
    saveConfig: {
      label: <FormattedMessage id="FilterComponent.Surroundings" />,
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select an option.',
    },
  },
  {
    key: 'languages',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      {
        option: 'english',
        label: <FormattedMessage id="filter.language.english" />,
      },
      {
        option: 'spanish',
        label: <FormattedMessage id="filter.language.spanish" />,
      },
      {
        option: 'french',
        label: <FormattedMessage id="filter.language.french" />,
      },
      {
        option: 'german',
        label: <FormattedMessage id="filter.language.german" />,
      },
      {
        option: 'portuguese',
        label: <FormattedMessage id="filter.language.portuguese" />,
      },
      {
        option: 'chinese',
        label: <FormattedMessage id="filter.language.chinese" />,
      },
    ],
    filterConfig: {
      indexForSearch: true,
      label: <FormattedMessage id="FilterComponent.Languages" />,
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: <FormattedMessage id="FilterComponent.Languages" />,
    },
    saveConfig: {
      label: <FormattedMessage id="FilterComponent.Languages" />,
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },

  // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'night',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
