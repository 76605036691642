import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../../../../util/reactIntl';

import css from './iconReviewSearchPage.module.css';

const IconReviewSearchPage = props => {
  const {
    value,
    reviewsAmount,
    classNameContainer,
    currentListing,
    onShowModalFromOutside,
    isListingPage,
    forRoomType,
    isWhite,
    isBlack,
    isLandingPage,
  } = props;
  
  const reviewsNumber = reviewsAmount && reviewsAmount.value.length > 0 ? reviewsAmount.value.length : 0;
  const formattedNumber = value.toString().replace('.', ',');

  const containerClassName = `${css.starContainer} ${css[classNameContainer]}`;

  const handleOnShowModalFromOutside = () => {
    onShowModalFromOutside(true);
  };

  const isClickable = reviewsNumber !== 0;
  const reviewHeadingClassName = isClickable ? css.reviewsHeading : css.reviewsHeadingUnclickable;

  // Set the star fill color based on the value
  const starFillColor = value > 0.1 ? "#1C7881" : "#FFF";

  return (
    <div className={containerClassName}>
      <svg
        className={css.star}
        width="14"
        height="14"
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
        style={{ zIndex: 2 }}
      >
        <path
          d="M10 16.7111L16.18 20.7931L14.54 13.0997L20 7.92327L12.81 7.2557L10 0L7.19 7.2557L0 7.92327L5.46 13.0997L3.82 20.7931L10 16.7111Z"
          fill={starFillColor}
          className={
            isClickable
              ? isWhite
                ? css.whiteStroke
                : css.starPath
              : isWhite
              ? css.whiteStroke
              : css.starPathUnclickable
          }
        />
      </svg>

      <p
        className={
          isClickable
            ? isBlack
              ? css.blackText
              : isWhite
              ? css.whiteText
              : css.starReviews
            : isBlack
            ? css.blackText
            : isWhite
            ? css.whiteText
            : css.reviewsNumberUnclickable
        }
      >
        {isLandingPage ? formattedNumber : formattedNumber[2] == 0 ? formattedNumber[0] : formattedNumber }
      </p>
      {isListingPage || forRoomType ? (
        <> </>
      ) : (
        <span className={isClickable ? css.starReviews : css.reviewsNumberUnclickable}>&bull;</span>
      )}
    </div>
  );
};

IconReviewSearchPage.defaultProps = {
  className: null,
  rootClassName: null,
  value: 0,
};

const { number, string } = PropTypes;

IconReviewSearchPage.propTypes = {
  className: string,
  rootClassName: string,
  value: number,
};

export default IconReviewSearchPage;
