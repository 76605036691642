import React from 'react';
import css from './loadingSpinner.module.css';

const Spinner = () => (
  <div className={css.spinner}>
    <div className={css.spinnerInner}></div>
  </div>
);

export default Spinner;
