import React from 'react';
import css from './AmbassadorPage.module.css';
import { FormattedMessage } from 'react-intl';
import NamedLinkNewTab from '../../components/NamedLink/NamedLinkNewTab';
const AccommodationCard = ({ pageLanguage }) => {
  const cardData = [
    {
      id: 1,
      titleEN: 'BED AND BREAKFAST',
      titleDE: 'BED AND BREAKFAST',
      link:
        'https://www.socialbnb.org/l/la-zagara-eco-entire-accomodation/6239da6d-d54f-41fc-a9bb-c2d1cfa7f917',
      linkName: 'ListingPage',
      linkParams: {
        slug: 'la-zagara-eco-entire-accomodation',
        id: '6239da6d-d54f-41fc-a9bb-c2d1cfa7f917',
      },
      descriptionDE: 'Komfortable Unterkünfte für jeden Geschmack',
      descriptionEN: 'Comfortable accommodation for every taste',
      photo: require('../../assets/ambassadorPage/acc1.webp'),
    },
    {
      id: 2,
      titleEN: 'HOTELS',
      titleDE: 'HOTELS',
      link:
        'https://www.socialbnb.org/l/orto-dell-idro-doublebedroom/6233ba15-0b00-42a1-9992-d46b03f5196d',
      linkName: 'ListingPage',
      linkParams: {
        slug: 'orto-dell-idro-doublebedroom',
        id: '6233ba15-0b00-42a1-9992-d46b03f5196d',
      },
      descriptionDE: 'Stilvolle Möglichkeiten zum übernachten',
      descriptionEN: 'Stylish place to stay',
      photo: require('../../assets/ambassadorPage/acc2.webp'),
    },
    {
      id: 3,
      titleEN: 'APARTMENT',
      titleDE: 'FERIENWOHNUNG',
      link:
        'https://www.socialbnb.org/s?address=Italien&bounds=47.09199997988367%2C18.79759992139748%2C35.48969995195065%2C6.626720064926066&origin=41.87194%2C12.56738',
      linkName: 'SearchPage',
      to: {
        search:
          '?address=Italien&bounds=47.09199997988367%2C18.79759992139748%2C35.48969995195065%2C6.626720064926066&origin=41.87194%2C12.56738',
      },
      descriptionDE: 'Individueller Urlaub in privater Atmosphäre',
      descriptionEN: 'Individual vacation in a private atmosphere',
      photo: require('../../assets/ambassadorPage/acc3.webp'),
    },
    {
      id: 4,
      titleEN: 'SPECIAL ACCOMMODATION',
      titleDE: 'BESONDERE UNTERKUNFT',
      link:
        'https://www.socialbnb.org/l/orto-dell-idro-camping/62a0715b-39ee-4d9e-b6f9-020c014e0de6',
      linkName: 'ListingPage',
      linkParams: {
        slug: 'orto-dell-idro-camping',
        id: '62a0715b-39ee-4d9e-b6f9-020c014e0de6',
      },
      descriptionDE: 'Schlafen in einzigartigen Räumen ',
      descriptionEN: 'Sleep in unique rooms',
      photo: require('../../assets/ambassadorPage/acc4.webp'),
    },
  ];

  return (
    <div className={css.wrapperPosition}>
      <div className={css.accomodationSection}>
        <div className={css.sectionTitle}>
          <FormattedMessage id="SectionAccommodation.title" />
        </div>

        <div className={css.sectionSubTitle}>
          <FormattedMessage id="SectionAccommodation.subTitle" />
        </div>

        <div className={css.accCardOuterWrapper}>
          {cardData.map(data => (
            <div key={data.id} className={css.accCardWrapper}>
              <NamedLinkNewTab
                name={data.linkName}
                to={data?.to}
                params={data?.linkParams}
                className={css.cookieLink}
              >
                <div className={css.imageArea}>
                  <img
                    className={css.accCardPhoto}
                    src={data.photo}
                    alt="tourism/Tourismus photo"
                  />
                </div>
              </NamedLinkNewTab>

              <div className={css.accCardTextArea}>
                {pageLanguage === 'en' ? (
                  <>
                    {' '}
                    <span className={css.cardTitle}>{data.titleEN}</span>
                    <p className={css.cardDescription}>{data.descriptionEN}</p>
                  </>
                ) : (
                  <>
                    <span className={css.cardTitle}>{data.titleDE}</span>
                    <p className={css.cardDescription}>{data.descriptionDE}</p>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccommodationCard;
