import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';

import classNames from 'classnames';
import { Form } from '../../../components';
import css from './TopbarSearchForm.module.css';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import LocationAutocompleteInputImpl from '../../LocationAutocompleteInput/LocationAutocompleteInput';
import { removeLanguagePrefix } from '../../../util/urlHelpers';

const identity = v => v;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
    this.state = {
      routingLocation: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const routeLoc = window.location.pathname;
    if (removeLanguagePrefix(routeLoc) === '/s') {
      this.setState({
        routingLocation: true,
      });
    } else {
      this.setState({
        routingLocation: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange(location) {
    this.props.handleLocationChange({ location });

    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.

      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const { rootClassName, className, desktopInputRoot, intl, isMobile } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;
          const isCurrentLocationIsSearch = this.state.routingLocation;
          const mobileInputClass = isCurrentLocationIsSearch
            ? css.mobileInputRoot
            : css.landingPageMobileInput;

          const desktopIconClass = isCurrentLocationIsSearch
            ? css.desktopIcon
            : css.landingPageIcon;

          const inputClassForMobile = isCurrentLocationIsSearch
            ? css.mobileInput
            : css.desktopInput;

          const isTobarSearch = this.props.isTobarSearch;
          // Allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();
          return (
            <Form className={classes} onSubmit={preventFormSubmit}>
              <Field
                name="location"
                format={identity}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;
                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    onChange(value);
                    this.onChange(value);
                  };
                  const searchInput = { ...restInput, onChange: searchOnChange };
                  return (
                    <LocationAutocompleteInputImpl
                      className={
                        isTobarSearch
                          ? css.searchBar
                          : isMobile
                          ? mobileInputClass
                          : desktopInputRootClass
                      }
                      iconClassName={
                        isTobarSearch
                          ? css.locationAutocompleteInputIcon
                          : isMobile
                          ? css.mobileIcon
                          : desktopIconClass
                      }
                      inputClassName={
                        isTobarSearch
                          ? css.searchInput
                          : isMobile
                          ? inputClassForMobile
                          : css.desktopInput
                      }
                      predictionsClassName={
                        isTobarSearch
                          ? css.predictionsRoot
                          : isMobile
                          ? css.mobilePredictions
                          : css.desktopPredictions
                      }
                      predictionsAttributionClassName={
                        isMobile ? css.mobilePredictionsAttribution : null
                      }
                      placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                      closeOnBlur={!isMobile}
                      inputRef={node => {
                        this.searchInput = node;
                      }}
                      input={searchInput}
                      meta={meta}
                      isMobile={isMobile}
                      isTobarSearch={isTobarSearch}
                    />
                  );
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}
const { func, string, bool } = PropTypes;
TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  isTobarSearch: false,
};
TopbarSearchFormComponent.propTypes = {
  handleLocationChange: func.isRequired,
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  intl: intlShape.isRequired,
  isTobarSearch: bool,
};
const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);
export default TopbarSearchForm;
