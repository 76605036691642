import React, { useEffect, useState } from 'react';
import icon1 from '../../assets/categories/equality.webp';
import icon2 from '../../assets/categories/education.webp';
import icon3 from '../../assets/categories/environment.webp';
import icon4 from '../../assets/categories/animal.webp';
import icon5 from '../../assets/categories/health.webp';
import icon6 from '../../assets/categories/sports.webp';
import css from './SectionImpact.module.css';
import { FormattedMessage } from '../../util/reactIntl';

function SectionImpact() {
  const [main, setMain] = useState(true);
  const [equality, setEquality] = useState(false);
  const [education, setEducation] = useState(false);
  const [environment, setEnvironment] = useState(false);
  const [animal, setAnimal] = useState(false);
  const [health, setHealth] = useState(false);
  const [sports, setSports] = useState(false);
  const [change, setChange] = useState('Main');
  const handleChange = (e, spot) => {
    e.preventDefault();
    setChange(spot);
  };

  useEffect(() => {
    if (change == 'Main') {
      setMain(true);
      setEquality(false);
      setEducation(false);
      setEnvironment(false);
      setAnimal(false);
      setHealth(false);
      setSports(false);
    }
    if (change == 'Equality') {
      setMain(false);
      setEquality(true);
      setEducation(false);
      setEnvironment(false);
      setAnimal(false);
      setHealth(false);
      setSports(false);
    }
    if (change == 'Education') {
      setMain(false);
      setEquality(false);
      setEducation(true);
      setEnvironment(false);
      setAnimal(false);
      setHealth(false);
      setSports(false);
    }
    if (change == 'Env') {
      setMain(false);
      setEquality(false);
      setEducation(false);
      setEnvironment(true);
      setAnimal(false);
      setHealth(false);
      setSports(false);
    }
    if (change == 'Animal') {
      setMain(false);
      setEquality(false);
      setEducation(false);
      setEnvironment(false);
      setAnimal(true);
      setHealth(false);
      setSports(false);
    }
    if (change == 'Health') {
      setMain(false);
      setEquality(false);
      setEducation(false);
      setEnvironment(false);
      setAnimal(false);
      setHealth(true);
      setSports(false);
    }
    if (change == 'Sports') {
      setMain(false);
      setEquality(false);
      setEducation(false);
      setEnvironment(false);
      setAnimal(false);
      setHealth(false);
      setSports(true);
    }
  }, [change]);
  return (
    <>
      <div className={css.impactWrapper} data-elb="section" data-elb-section="name:impactSection;position:7" data-elbaction="visible">
        <div className={css.impactHeaderWrapper}>
          <h1 className={css.impactHeader}>
            <FormattedMessage id="about.impactHeadline" />
          </h1>
        </div>
      </div>
      <div className={css.circleWrapper}>
        <div className={css.circle}>
          <ul
            className={css.circleContainer}
            onMouseEnter={() => setMain(false)}
            onMouseLeave={() => setMain(true)}
          >
            <li
              className={css.healthIcon}
              onMouseEnter={() => setHealth(true)}
              onMouseLeave={() => setHealth(false)}
              onClick={e => handleChange(e, 'Health')}
            >
              <img
                src={icon5}
                alt="socialbnb icons für nachhaltiger Tourismus/sustainable tourism"
              />{' '}
            </li>
            <li
              className={css.animalIcon}
              onMouseEnter={() => setAnimal(true)}
              onMouseLeave={() => setAnimal(false)}
              onClick={e => handleChange(e, 'Animal')}
            >
              <img
                src={icon4}
                alt="socialbnb icons für nachhaltiger Tourismus/sustainable tourism"
              />
            </li>
            <li
              className={css.sportsIcon}
              onMouseEnter={() => setSports(true)}
              onMouseLeave={() => setSports(false)}
              onClick={e => handleChange(e, 'Sports')}
            >
              <img
                src={icon6}
                alt="socialbnb icons für nachhaltiger Tourismus/sustainable tourism"
              />
            </li>
            <li
              className={css.educationIcon}
              onMouseEnter={() => setEducation(true)}
              onMouseLeave={() => setEducation(false)}
              onClick={e => handleChange(e, 'Education')}
            >
              <img
                src={icon2}
                alt="socialbnb icons für nachhaltiger Tourismus/sustainable tourism"
              />
            </li>
            <li
              className={css.environmentIcon}
              onMouseEnter={() => setEnvironment(true)}
              onMouseLeave={() => setEnvironment(false)}
              onClick={e => handleChange(e, 'Env')}
            >
              <img
                src={icon3}
                alt="socialbnb icons für nachhaltiger Tourismus/sustainable tourism"
              />
            </li>
            <li
              className={css.equalityIcon}
              onMouseEnter={() => setEquality(true)}
              onMouseLeave={() => setEquality(false)}
              onClick={e => handleChange(e, 'Equality')}
            >
              <img
                src={icon1}
                alt="socialbnb icons für nachhaltiger Tourismus/sustainable tourism"
              />
            </li>
          </ul>
          <div className={css.text}>
            {main ? (
              <>
                <h1>
                  <FormattedMessage id="about.mainCircleH" />
                </h1>
                <p>
                  <FormattedMessage id="about.mainCircleP" />
                </p>
              </>
            ) : equality ? (
              <>
                <h1>
                  <FormattedMessage id="about.equalityIconH" />
                </h1>
                <p>
                  <FormattedMessage id="about.equalityIconP" />
                </p>
              </>
            ) : education ? (
              <>
                <h1>
                  <FormattedMessage id="about.educationIconH" />
                </h1>
                <p>
                  <FormattedMessage id="about.educationIconP" />
                </p>
              </>
            ) : environment ? (
              <>
                <h1>
                  <FormattedMessage id="about.environmentIconH" />
                </h1>
                <p>
                  <FormattedMessage id="about.environmentIconP" />
                </p>
              </>
            ) : animal ? (
              <>
                <h1>
                  <FormattedMessage id="about.animalIconH" />
                </h1>
                <p>
                  <FormattedMessage id="about.animalIconP" />
                </p>
              </>
            ) : health ? (
              <>
                <h1>
                  <FormattedMessage id="about.healthIconH" />
                </h1>
                <p>
                  <FormattedMessage id="about.healthIconP" />
                </p>
              </>
            ) : sports ? (
              <>
                <h1>
                  <FormattedMessage id="about.sportsIconH" />
                </h1>
                <p>
                  <FormattedMessage id="about.sportsIconP" />
                </p>
              </>
            ) : null}
          </div>

          <ul className={css.backgroundContainer}>
            <li>
              <div className={css.background} />
            </li>
            <li>
              <div className={css.background} />
            </li>
            <li>
              <div className={css.background} />
            </li>
            <li>
              <div className={css.background} />
            </li>
            <li>
              <div className={css.background} />
            </li>
            <li>
              <div className={css.background} />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SectionImpact;
