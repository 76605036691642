import css from './Main.module.css';
import un from './assets/main/un.webp';
import giz from './assets/main/giz.webp';
import forum from './assets/main/forum.webp';
import bundesmin from './assets/main/bundesmin.webp';
import ltc from './assets/main/ltc.webp';
import ecpat from './assets/main/ecpat.webp';
import { FormattedMessage } from 'react-intl';

export const FooterMain = () => {
  const logoLinks = {
    un: 'https://www.unwto.org/unwto-awake-tourism-challenge',
    giz: 'https://www.giz.de/de/html/index.html',
    forum: 'https://forumandersreisen.de/',
    bundesmin: 'https://www.bmwk.de/Navigation/DE/Home/home.html',
    ltc: 'https://www.ltandc.org/',
    ecpat: 'https://ecpat.de/',
  };

  const LogoWithLink = ({ src, alt, href, elbwalker }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: 'block' }}
      data-elbaction={`click:click footer-supportedBy-${elbwalker}`}
    >
      <img src={src} alt={alt} className={css.logo} />
    </a>
  );

  return (
    <div
      className={css.container}
      data-elb="section"
      data-elb-section="name:footerButtom;position:13"
      data-elbaction="visible:footer supportedBy"
    >
      <div className={css.title}>
        <FormattedMessage id="footer.main.title" />
      </div>
      <div className={css.logosDesktop}>
        <LogoWithLink src={un} alt="un" href={logoLinks.un} elbwalker={'unTourism'} />
        <LogoWithLink src={giz} alt="giz" href={logoLinks.giz} elbwalker={'giz'} />
        <LogoWithLink src={forum} alt="forum" href={logoLinks.forum} elbwalker={'forum'} />
        <LogoWithLink
          src={bundesmin}
          alt="bundesmin"
          href={logoLinks.bundesmin}
          elbwalker={'bundesmin'}
        />
        <LogoWithLink src={ltc} alt="ltc" href={logoLinks.ltc} elbwalker={'ltc'} />
        <LogoWithLink src={ecpat} alt="ecpat" href={logoLinks.ecpat} elbwalker={'ecpat'} />
      </div>
      <div className={css.logosMobile}>
        <div className={css.logosMobileRow}>
          <LogoWithLink src={un} alt="un" href={logoLinks.un} elbwalker={'unTourism'} />
        </div>
        <div className={css.logosMobileRow}>
          <LogoWithLink src={ltc} alt="ltc" href={logoLinks.ltc} elbwalker={'ltc'} />
          <LogoWithLink src={ecpat} alt="ecpat" href={logoLinks.ecpat} elbwalker={'ecpat'} />
        </div>
        <div className={css.logosMobileRow}>
          <LogoWithLink src={forum} alt="forum" href={logoLinks.forum} elbwalker={'forum'} />
        </div>
        <div className={css.logosMobileRow}>
          <LogoWithLink src={giz} alt="giz" href={logoLinks.giz} elbwalker={'giz'} />
          <LogoWithLink
            src={bundesmin}
            alt="bundesmin"
            href={logoLinks.bundesmin}
            elbwalker={'bundesmin'}
          />
        </div>
      </div>
    </div>
  );
};
