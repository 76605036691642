import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { intlShape } from '../../../util/reactIntl';
import classNames from 'classnames';
// import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../../util/types';
import { Avatar, NamedLink } from '../../../components';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

import user from './user.png';

import MenuDrawer from './MenuDrawer';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router';
import OldLogo from '../../OldLogo/OldLogo';
import LanguageSwitcher from '../../LanguageSwitcherMobile/LanguageSwitcher';
const cookies = new Cookies();

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;

  const [mounted, setMounted] = useState(false);
  const [sectionOne, setSectionOne] = useState(true);
  const [sectionTwo, setSectionTwo] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [rootCss, setRootCss] = useState({ backgroundColor: 'transparent' });
  const history = useHistory();
  var cookiesMaybe = cookies?.get('language') ? cookies?.get('language') : 'en';
  useEffect(() => {
    if (typeof window != 'undefined') {
      window.onscroll = function() {
        if (window.pageYOffset * 2 < window.innerHeight) {
          setSectionTwo(false);
          setSectionOne(true);
        } else {
          setSectionOne(false);
          setSectionTwo(true);
          if (window.pageYOffset > window.innerHeight * 0.6) {
            setSectionTwo(true);
          } else {
            setSectionTwo(false);
          }
        }
      };
    }
  }, []);

  useEffect(() => {
    /*  if (cookiesMaybe == 'en') {
      cookies.set('language', 'de', {
        path: '/',
        maxAge: 60 * 60 * 24 * 90,
      });

      if (typeof window !== 'undefined'){
        window.location.reload();
      }
    } */
    setMounted(true);
    return () => {};
  }, [mounted]);

  //Language selector

  // const [lang, setLang] = cookies.get('language');
  // const languageOptions =
  //   cookies.get('language') === 'en' ? (
  //     <>
  //       <option style={{ fontFamily: 'poppins, Helvetica, Arial, sans-serif' }} value="Englisch">
  //         {intl.formatMessage({ id: 'languageSetting.english' })}
  //       </option>
  //       <option style={{ fontFamily: 'poppins, Helvetica, Arial, sans-serif' }} value="Deutsch">
  //         {intl.formatMessage({ id: 'languageSetting.german' })}
  //       </option>{' '}
  //     </>
  //   ) : (
  //     <>
  //       <option value="Deutsch">{intl.formatMessage({ id: 'languageSetting.german' })}</option>
  //       <option value="Englisch">{intl.formatMessage({ id: 'languageSetting.english' })}</option>
  //     </>
  //   );

  // const handleChange = async event => {
  //   if (event.target.value === 'Englisch') {
  //     cookies.set('language', 'en', {
  //       path: '/',
  //       maxAge: 60 * 60 * 24 * 90,
  //     });
  //     if (typeof window !== 'undefined') window.location.reload();
  //   } else {
  //     cookies.set('language', 'de', {
  //       path: '/',
  //       maxAge: 60 * 60 * 24 * 90,
  //     });
  //     if (typeof window !== 'undefined') window.location.reload();
  //   }
  // };
  // //End of Language Selector

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  /*   const classes = classNames(rootClassName || css.root, className); */

  let mediaQuery = '';

  if (typeof window !== 'undefined') {
    mediaQuery = window.matchMedia('(min-width: 768px)');
  }
  const search = (
    <TopbarSearchForm
      className={
        typeof window !== 'undefined'
          ? window?.location.pathname === '/s'
            ? css.searchLink_active
            : css.searchLink
          : css.searchLink
      }
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      handleLocationChange={() => {}}
    />
  );

  const becomePartner =
    !isAuthenticatedOrJustHydrated && mediaQuery.matches ? (
      <div
        className={
          typeof window !== 'undefined'
            ? window?.location.pathname === '/' ||
              window?.location.pathname === '/about' ||
              window?.location.pathname === '/FAQ' ||
              window?.location.pathname === '/Contact'
              ? css.becomePartnerWrapper
              : css.becomePartnerWrapper_hide
            : css.becomePartnerWrapper_hide
        }
      >
        <NamedLink
          className={
            typeof window !== 'undefined'
              ? window?.location.pathname === '/I'
                ? css.active
                : scrollPosition > 250
                ? css.becomePartnerLinkScrolled
                : css.becomePartnerLink
              : css.becomePartnerLink
          }
          name="BecomeHostPage"
        >
          <span className={css.becomePartner} data-elbaction={'click:click topbar-becomehost'}>
            <FormattedMessage id="TopbarDesktop.becomePartner" />
          </span>
        </NamedLink>
      </div>
    ) : null;

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const inboxLink =
    authenticatedOnClientSide && mediaQuery.matches && currentUserHasListings != null ? (
      <NamedLink
        className={css.inboxLinkScrolled}
        name="InboxPage"
        params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
      >
        <span className={css.inbox} data-elbaction={'click:click inboxClick'}>
          <FormattedMessage id="TopbarDesktop.inbox" />
          {notificationDot}
        </span>
      </NamedLink>
    ) : null;

  const profileMenu =
    authenticatedOnClientSide && mediaQuery.matches ? (
      <NamedLink
        name="ProfileSettingsPage"
        className="" /* {css.signupLink */
        /*           typeof window !== 'undefined'
            ? window?.location.pathname === '/profile-settings' ||
              window?.location.pathname === '/listings' ||
              window?.location.pathname === '/account/contact-details' ||
              window?.location.pathname === '/account/change-password'
              ?  css.signupLink
              : css.signupLink
            : css.signupLink
        }*/
      >
        {' '}
        <div data-elbaction="click:click topbar-profileicon">
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
          {window.location.pathname == '/listings' &&
            !currentUserHasListings &&
            currentUser?.attributes?.profile?.protectedData?.type == 'traveler' &&
            history.push({ pathname: '/profile-settings' })}
        </div>
      </NamedLink>
    ) : null;

  const loginLink =
    !isAuthenticatedOrJustHydrated && mediaQuery.matches ? (
      <NamedLink
        name="LoginPage"
        className={
          typeof window !== 'undefined'
            ? window?.location.pathname === '/login'
              ? css.active
              : css.loginLink
            : css.loginLink
        }
      >
        <img
          src={user}
          className={
            typeof window !== 'undefined'
              ? (window?.location.pathname === '/' || window?.location.pathname === '/about') &&
                sectionOne
                ? css.userIcon2
                : css.userIcon
              : css.userIcon
          }
          alt="user avatar socialbnb"
          data-elbaction="click:click topbar-profileicon"
        ></img>
      </NamedLink>
    ) : null;

  const checkDestinations = mediaQuery.matches ? (
    <div
      className={
        typeof window !== 'undefined'
          ? window.location.pathname === '/l/:slug/:id' ||
            ((window?.location.pathname === '/' || window?.location.pathname === '/about') &&
              sectionTwo) ||
            (window?.location.pathname === '/' && scrollPosition > 250)
            ? css.check
            : css.check_hide
          : css.check_hide
      }
      data-elbaction={'click:click topbar-accomodationscta'}
    >
      <NamedLink
        name="SearchPage"
        to={{ search: '?bounds=85.051129%2C179.00736739%2C-75.14077719%2C-160.60200761' }}
        className={css.heroButton}
      >
        <FormattedMessage id="TopbarDesktop.destinationsCTA" />
      </NamedLink>
    </div>
  ) : (
    <div
      className={
        typeof window !== 'undefined'
          ? window?.location.pathname === '/l/:slug/:id' ||
            ((window?.location.pathname === '/' || window?.location.pathname === '/about') &&
              sectionTwo) ||
            (window?.location.pathname === '/' && scrollPosition > 220)
            ? css.check
            : css.check_hide
          : css.check_hide
      }
    >
      <NamedLink name="SearchPage" className={css.heroButtonMobile}>
        <FormattedMessage id="TopbarDesktop.destinationsCTAshort" />
      </NamedLink>
    </div>
  );

  var touchMoveEvent = null;
  var wheelEvent = null;
  var position = 0;
  useEffect(() => {
    if (typeof window !== 'undefined' && window?.document) {
      touchMoveEvent = 'ontouchmove' in document?.documentElement ? 'touchmove' : null;
      wheelEvent = 'onwheel' in document?.createElement('div') ? 'wheel' : 'mousewheel';
      window.addEventListener(wheelEvent, changeCss, false);
      window.addEventListener(touchMoveEvent, changeCss, { passive: true });
    }
  }, []);

  const changeCss = () => {
    if (typeof window !== 'undefined' && window?.document) {
      position = document?.body?.scrollTop
        ? document?.body?.scrollTop
        : position !== 0
        ? position
        : 0;
      setScrollPosition(position);
      if (position < 250) {
        setRootCss({ backgroundColor: 'transparent', boxShadow: 'none' });
      } else if (position >= 250) {
        setRootCss({ backgroundColor: 'white', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)' });
      }
    }
  };

  return (
    /*  <div className={position < 250 ? css.notScrolled : css.scrolled}> */
    <nav className={css.rootPaper} style={rootCss}>
      <NamedLink className={css.logoLink} name={'LandingPage'}>
        <OldLogo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          data-elbaction="click:click topbar-logo"
        />
      </NamedLink>

      <div className={css.searchOuter}>{search}</div>
      {checkDestinations}
      {inboxLink}
      {profileMenu}
      <div>{becomePartner}</div>
      {loginLink}
      <div
        className={css.line}
        style={{
          float: 'left',
        }}
      ></div>
      <div data-elbaction={'click:click topbar-menuopen'}>
        <MenuDrawer
          props={props}
          authenticatedOnClientSide={authenticatedOnClientSide}
          currentUserHasListings={currentUserHasListings}
          notificationCount={notificationCount}
          onLogout={onLogout}
          intl={intl}
        ></MenuDrawer>
      </div>
      {typeof window !== 'undefined' ? (
        window?.innerWidth > 750 ? (
          <LanguageSwitcher />
        ) : null
      ) : null}
    </nav>
    /*     </div> */
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
