import React from 'react';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';

const ResponsiveImage = props => {
  const { className, rootClassName, alt, noImageMessage, image, variants, onLoad, ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (image == null || variants.length === 0) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);
    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }

  const imageVariants = image.attributes.variants;
  const srcSet = variants
    .map(variantName => {
      const variant = imageVariants[variantName];
      if (!variant) {
        return null;
      }
      return `${variant.url} ${variant.width}w`;
    })
    .filter(v => v != null)
    .join(', ');

  const imgProps = {
    className: classes,
    alt,
    srcSet,
    onLoad,
    ...rest,
  };
  if (onLoad === undefined) {
    return (
      <img
        {...imgProps}
        alt="nachhaltiger Tourismus/sustainable tourism"
        onLoad={imgProps.onHandleLoad}
      />
    );
  } else {
    return <img {...imgProps} alt="nachhaltiger Tourismus/sustainable tourism" onLoad={onLoad} />;
  }
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  noImageMessage: null,
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: propTypes.image,
  variants: arrayOf(string).isRequired,
  noImageMessage: string,
};

export default ResponsiveImage;
