import React from 'react';
import css from './AmbassadorPage.module.css';
import quotationIcon from '../../assets/icons/quotationLeft.svg';
import { FormattedMessage } from 'react-intl';

const TestimonialsCard = () => {
  const testiMonialData = [
    {
      id: 1,
      firstName: 'Markus',
      lastName: 'Markus',
      socialBnbLocation: <FormattedMessage id="Testimonial.location1" />,
      testimonialsMsg: <FormattedMessage id="Testimonial.msg1" />,
      imgSrc: require('../../assets/ambassadorPage/test1.webp'),
    },
    {
      id: 2,
      firstName: 'Linda',
      lastName: 'Linda',
      socialBnbLocation: <FormattedMessage id="Testimonial.location2" />,
      testimonialsMsg: <FormattedMessage id="Testimonial.msg2" />,
      imgSrc: require('../../assets/ambassadorPage/test2.webp'),
    },
    {
      id: 3,
      firstName: 'Christoph',
      lastName: 'Christoph',
      socialBnbLocation: <FormattedMessage id="Testimonial.location3" />,
      testimonialsMsg: <FormattedMessage id="Testimonial.msg3" />,
      imgSrc: require('../../assets/ambassadorPage/test3.webp'),
    },
  ];

  return (
    <div className={css.wrapperPosition}>
      <div className={css.testimonialsSection}>
        <div className={css.sectionTitle}>
          <FormattedMessage id="SectionTestimonials.title" />
        </div>

        <div className={css.testiMonialsWrapper}>
          {testiMonialData.map(data => (
            <div key={data.id} className={css.cardWrapper}>
              <div className={css.cardHeader}>
                <img style={{ height: '40px' }} src={quotationIcon} alt="qoute" />
              </div>
              <div className={css.cardText}>
                <span>"</span>
                {data.testimonialsMsg} <span>"</span>
              </div>
              <div className={css.cardUserInfo}>
                <div className={css.userPhoto}>
                  <img className={css.userImg} src={data.imgSrc} alt="user avatar" />
                </div>
                <p className={css.userInfoTxt}>
                  <span className={css.locationTxt}>{data.socialBnbLocation}</span>
                  <span className={css.userNameTxt}>{data.lastName}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCard;
