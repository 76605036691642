import dimage0 from '../../../../assets/impactAccomodationsPics/categoryDefault/dimage0.webp';
import dimage1 from '../../../../assets/impactAccomodationsPics/categoryDefault/dimage1.webp';
import dimage2 from '../../../../assets/impactAccomodationsPics/categoryDefault/dimage2.webp';
import dimage3 from '../../../../assets/impactAccomodationsPics/categoryDefault/dimage3.webp';
import dimage5 from '../../../../assets/impactAccomodationsPics/categoryDefault/dimage5.webp';

import c0image1 from '../../../../assets/impactAccomodationsPics/category0/1_.jpg';
import c0image2 from '../../../../assets/impactAccomodationsPics/category0/1.jpg';
import c0image3 from '../../../../assets/impactAccomodationsPics/category0/2.jpg';
import c0image3b from '../../../../assets/impactAccomodationsPics/category0/3.jpg';
import c0image4 from '../../../../assets/impactAccomodationsPics/category0/4.jpg';
import c0image5 from '../../../../assets/impactAccomodationsPics/category0/5.jpg';
import c0image6 from '../../../../assets/impactAccomodationsPics/category0/6.jpg';
import c0image7 from '../../../../assets/impactAccomodationsPics/category0/7.jpg';
import c0image8 from '../../../../assets/impactAccomodationsPics/category0/8.jpg';
import c0image9 from '../../../../assets/impactAccomodationsPics/category0/9.jpg';

import image1 from '../../../../assets/impactAccomodationsPics/category1/1.jpg';
import image2 from '../../../../assets/impactAccomodationsPics/category1/2.jpg';
import image3 from '../../../../assets/impactAccomodationsPics/category1/3.jpg';
import image4 from '../../../../assets/impactAccomodationsPics/category1/4.jpg';
import image5 from '../../../../assets/impactAccomodationsPics/category1/5.jpg';
import image8 from '../../../../assets/impactAccomodationsPics/category1/8.jpg';
import image9 from '../../../../assets/impactAccomodationsPics/category1/9.jpg';
import image10 from '../../../../assets/impactAccomodationsPics/category1/10.jpg';
import image11 from '../../../../assets/impactAccomodationsPics/category1/11.jpg';

import c2image1 from '../../../../assets/impactAccomodationsPics/category2/1.jpg';

import c3image1 from '../../../../assets/impactAccomodationsPics/category3/1.jpg';
import c3image2 from '../../../../assets/impactAccomodationsPics/category3/2.jpg';
import c3image3 from '../../../../assets/impactAccomodationsPics/category3/3.jpg';
import c3image4 from '../../../../assets/impactAccomodationsPics/category3/4.jpg';
import c3image5 from '../../../../assets/impactAccomodationsPics/category3/5.jpg';
import c3image6 from '../../../../assets/impactAccomodationsPics/category3/6.jpg';
import c3image7 from '../../../../assets/impactAccomodationsPics/category3/7.jpg';
import c3image8 from '../../../../assets/impactAccomodationsPics/category3/8.jpg';
import c3image9 from '../../../../assets/impactAccomodationsPics/category3/9.jpg';
import c3image10 from '../../../../assets/impactAccomodationsPics/category3/10.jpg';
import c3image11 from '../../../../assets/impactAccomodationsPics/category3/11.jpg';
import c3image12 from '../../../../assets/impactAccomodationsPics/category3/12.jpg';
import c3image13 from '../../../../assets/impactAccomodationsPics/category3/13.jpg';

import c4image1 from '../../../../assets/impactAccomodationsPics/category4/1.jpg';
import c4image2 from '../../../../assets/impactAccomodationsPics/category4/2.jpg';
import c4image3 from '../../../../assets/impactAccomodationsPics/category4/3.jpg';
import c4image4 from '../../../../assets/impactAccomodationsPics/category4/4.jpg';
import c4image5 from '../../../../assets/impactAccomodationsPics/category4/5.jpg';
import c4image6 from '../../../../assets/impactAccomodationsPics/category4/6.jpg';
import c4image7 from '../../../../assets/impactAccomodationsPics/category4/7.jpg';
import c4image8 from '../../../../assets/impactAccomodationsPics/category4/8.jpg';
import c4image9 from '../../../../assets/impactAccomodationsPics/category4/9.jpg';
import c4image10 from '../../../../assets/impactAccomodationsPics/category4/10.jpg';
import c4image11 from '../../../../assets/impactAccomodationsPics/category4/11.jpg';
import c4image12 from '../../../../assets/impactAccomodationsPics/category4/12.jpg';
import c4image13 from '../../../../assets/impactAccomodationsPics/category4/13.jpg';
import c4image14 from '../../../../assets/impactAccomodationsPics/category4/14.jpg';

import c5image1 from '../../../../assets/impactAccomodationsPics/category5/1.jpg';
import c5image2 from '../../../../assets/impactAccomodationsPics/category5/2.jpg';
import c5image3 from '../../../../assets/impactAccomodationsPics/category5/3.jpg';
import c5image5 from '../../../../assets/impactAccomodationsPics/category5/5.jpg';
import c5image6 from '../../../../assets/impactAccomodationsPics/category5/6.jpg';
import c5image7 from '../../../../assets/impactAccomodationsPics/category5/7.jpg';
import c5image8 from '../../../../assets/impactAccomodationsPics/category5/8.jpg';
import c5image9 from '../../../../assets/impactAccomodationsPics/category5/9.jpg';
import c5image10 from '../../../../assets/impactAccomodationsPics/category5/10.jpg';
import c5image11 from '../../../../assets/impactAccomodationsPics/category5/11.jpg';
import c5image12 from '../../../../assets/impactAccomodationsPics/category5/12.jpg';
import c5image13 from '../../../../assets/impactAccomodationsPics/category5/13.jpg';
import c5image14 from '../../../../assets/impactAccomodationsPics/category5/14.jpg';

import c6image1 from '../../../../assets/impactAccomodationsPics/category6/1.jpg';
import c6image2 from '../../../../assets/impactAccomodationsPics/category6/2.jpg';
import c6image3 from '../../../../assets/impactAccomodationsPics/category6/3.jpg';
import c6image4 from '../../../../assets/impactAccomodationsPics/category6/4.jpg';
import c6image5 from '../../../../assets/impactAccomodationsPics/category6/5.jpg';
import c6image6 from '../../../../assets/impactAccomodationsPics/category6/6.jpg';
import c6image7 from '../../../../assets/impactAccomodationsPics/category6/7.jpg';
import c6image8 from '../../../../assets/impactAccomodationsPics/category6/8.jpg';
import c6image9 from '../../../../assets/impactAccomodationsPics/category6/9.jpg';
import c6image10 from '../../../../assets/impactAccomodationsPics/category6/10.jpg';
import c6image11 from '../../../../assets/impactAccomodationsPics/category6/11.jpg';

import c7image1 from '../../../../assets/impactAccomodationsPics/category7/1.jpg';
import c7image2 from '../../../../assets/impactAccomodationsPics/category7/2.jpg';
import c7image3 from '../../../../assets/impactAccomodationsPics/category7/3.jpg';
import c7image4 from '../../../../assets/impactAccomodationsPics/category7/4.jpg';
import c7image5 from '../../../../assets/impactAccomodationsPics/category7/5.jpg';
import c7image6 from '../../../../assets/impactAccomodationsPics/category7/6.jpg';
import c7image7 from '../../../../assets/impactAccomodationsPics/category7/7.jpg';
import c7image8 from '../../../../assets/impactAccomodationsPics/category7/8.jpg';
import c7image9 from '../../../../assets/impactAccomodationsPics/category7/9.jpg';
import c7image10 from '../../../../assets/impactAccomodationsPics/category7/10.jpg';
import c7image11 from '../../../../assets/impactAccomodationsPics/category7/11.jpg';

import c8image1 from '../../../../assets/impactAccomodationsPics/category8/1.jpg';
import c8image2 from '../../../../assets/impactAccomodationsPics/category8/2.jpg';
import c8image3 from '../../../../assets/impactAccomodationsPics/category8/3.jpg';
import c8image5 from '../../../../assets/impactAccomodationsPics/category8/5.jpg';
import c8image6 from '../../../../assets/impactAccomodationsPics/category8/6.jpg';
import c8image7 from '../../../../assets/impactAccomodationsPics/category8/7.jpg';
import c8image8 from '../../../../assets/impactAccomodationsPics/category8/8.jpg';
import c8image9 from '../../../../assets/impactAccomodationsPics/category8/9.jpg';

import c9image1 from '../../../../assets/impactAccomodationsPics/category9/1.jpg';
import c9image2 from '../../../../assets/impactAccomodationsPics/category9/2.jpg';
import c9image3 from '../../../../assets/impactAccomodationsPics/category9/3.jpg';
import c9image4 from '../../../../assets/impactAccomodationsPics/category9/4.jpg';
import c9image5 from '../../../../assets/impactAccomodationsPics/category9/5.jpg';
import c9image6 from '../../../../assets/impactAccomodationsPics/category9/6.jpg';
import c9image7 from '../../../../assets/impactAccomodationsPics/category9/7.jpg';
import c9image8 from '../../../../assets/impactAccomodationsPics/category9/8.jpg';
import c9image9 from '../../../../assets/impactAccomodationsPics/category9/9.jpg';
import c9image10 from '../../../../assets/impactAccomodationsPics/category9/10.jpg';

import c10image1 from '../../../../assets/impactAccomodationsPics/category10/1.jpg';
import c10image2 from '../../../../assets/impactAccomodationsPics/category10/2.jpg';
import c10image3 from '../../../../assets/impactAccomodationsPics/category10/3.jpg';
import c10image4 from '../../../../assets/impactAccomodationsPics/category10/4.jpg';
import c10image5 from '../../../../assets/impactAccomodationsPics/category10/5.jpg';
import c10image6 from '../../../../assets/impactAccomodationsPics/category10/6.jpg';
import c10image7 from '../../../../assets/impactAccomodationsPics/category10/7.jpg';
import c10image8 from '../../../../assets/impactAccomodationsPics/category10/8.jpg';
import c10image9 from '../../../../assets/impactAccomodationsPics/category10/9.jpg';
import c10image10 from '../../../../assets/impactAccomodationsPics/category10/10.jpg';

import c11image1 from '../../../../assets/impactAccomodationsPics/category11/1.jpg';
import c11image2 from '../../../../assets/impactAccomodationsPics/category11/2.jpg';
import c11image3 from '../../../../assets/impactAccomodationsPics/category11/3.jpg';
import c11image4 from '../../../../assets/impactAccomodationsPics/category11/4.jpg';
import c11image5 from '../../../../assets/impactAccomodationsPics/category11/5.jpg';
import c11image6 from '../../../../assets/impactAccomodationsPics/category11/6.jpg';
import c11image7 from '../../../../assets/impactAccomodationsPics/category11/7.jpg';
import c11image8 from '../../../../assets/impactAccomodationsPics/category11/8.jpg';
import c11image9 from '../../../../assets/impactAccomodationsPics/category11/9.jpg';
import c11image10 from '../../../../assets/impactAccomodationsPics/category11/10.jpg';
import c11image11 from '../../../../assets/impactAccomodationsPics/category11/11.jpg';
import c11image12 from '../../../../assets/impactAccomodationsPics/category11/12.jpg';
import c11image13 from '../../../../assets/impactAccomodationsPics/category11/13.jpg';
import c11image14 from '../../../../assets/impactAccomodationsPics/category11/14.jpg';

import c12image1 from '../../../../assets/impactAccomodationsPics/category12/1.jpg';
import c12image2 from '../../../../assets/impactAccomodationsPics/category12/2.jpg';
import c12image3 from '../../../../assets/impactAccomodationsPics/category12/3.jpg';
import c12image4 from '../../../../assets/impactAccomodationsPics/category12/4.jpg';
import c12image5 from '../../../../assets/impactAccomodationsPics/category12/5.jpg';
import c12image6 from '../../../../assets/impactAccomodationsPics/category12/6.jpg';
import c12image7 from '../../../../assets/impactAccomodationsPics/category12/7.jpg';
import c12image8 from '../../../../assets/impactAccomodationsPics/category12/8.jpg';
import c12image9 from '../../../../assets/impactAccomodationsPics/category12/9.jpg';

import c13image1 from '../../../../assets/impactAccomodationsPics/category13/1.jpg';
import c13image2 from '../../../../assets/impactAccomodationsPics/category13/2.jpg';
import c13image3 from '../../../../assets/impactAccomodationsPics/category13/3.jpg';
import c13image4 from '../../../../assets/impactAccomodationsPics/category13/4.jpg';
import c13image5 from '../../../../assets/impactAccomodationsPics/category13/5.jpg';
import c13image6 from '../../../../assets/impactAccomodationsPics/category13/6.jpg';
import c13image7 from '../../../../assets/impactAccomodationsPics/category13/7.jpg';
import c13image8 from '../../../../assets/impactAccomodationsPics/category13/8.jpg';
import c13image9 from '../../../../assets/impactAccomodationsPics/category13/9.jpg';
import c13image10 from '../../../../assets/impactAccomodationsPics/category13/10.jpg';
import c13image11 from '../../../../assets/impactAccomodationsPics/category13/11.jpg';
import c13image12 from '../../../../assets/impactAccomodationsPics/category13/12.jpg';
import c13image13 from '../../../../assets/impactAccomodationsPics/category13/13.jpg';
import c13image14 from '../../../../assets/impactAccomodationsPics/category13/14.jpg';

import c14image1 from '../../../../assets/impactAccomodationsPics/category14/1.jpg';
import c14image2 from '../../../../assets/impactAccomodationsPics/category14/2.jpg';
import c14image3 from '../../../../assets/impactAccomodationsPics/category14/3.jpg';
import c14image4 from '../../../../assets/impactAccomodationsPics/category14/4.jpg';

import c15image1 from '../../../../assets/impactAccomodationsPics/category15/1.jpg';
import c15image2 from '../../../../assets/impactAccomodationsPics/category15/2.jpg';
import c15image3 from '../../../../assets/impactAccomodationsPics/category15/4.jpg';
import c15image4 from '../../../../assets/impactAccomodationsPics/category15/4.jpg';

import c16image1 from '../../../../assets/impactAccomodationsPics/category16/c16image1.jpg';
import c16image2 from '../../../../assets/impactAccomodationsPics/category16/c16image2.jpg';
import c16image3 from '../../../../assets/impactAccomodationsPics/category16/c16image3.jpg';
import c16image4 from '../../../../assets/impactAccomodationsPics/category16/c16image4.jpg';
// projectType: [] has to be in every Listing. Very Important !!

export const categoryDefault = [
  {
    link: '/l/finca-la-selvita-doublebedroom/64d56d78-7c8e-4b2e-9398-a90e6edb40be',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'finca-la-selvita-doublebedroom',
      id: '64d56d78-7c8e-4b2e-9398-a90e6edb40be',
    },
    name: 'Finca La Selvita • Double room',
    price: '  65€ ',
    projectType: ['nature'],
    location: 'Santa Marta, Colombia',
    image: dimage0,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link:
      '/l/naturschutzstation-ostliche-oberlausitz-doublebedroom/62ba9f2d-f348-4467-9d82-ed33b970644d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'naturschutzstation-ostliche-oberlausitz-doublebedroom',
      id: '62ba9f2d-f348-4467-9d82-ed33b970644d',
    },
    name: 'Naturschutzstation Östliche Oberlausitz • Double room',
    price: ' 71€ ',
    location: 'Mücka, Germany',
    projectType: ['nature', 'education'],
    image: dimage1,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link: '/l/casa-in-mezzo-zonca-entire-accomodation/67054f87-f314-4ca0-ac1f-d7e8449ab102',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'casa-in-mezzo-zonca-entire-accomodation',
      id: '67054f87-f314-4ca0-ac1f-d7e8449ab102',
    },
    name: 'Casa in Mezzo • Entire accommodation',
    price: '  75€ ',
    location: 'Montescheno, Italy',
    projectType: ['education', 'nature'],
    image: dimage2,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/global-tribe-tent/62a099e0-2f16-4714-aad6-4e6d38da4185',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'global-tribe-tent',
      id: '62a099e0-2f16-4714-aad6-4e6d38da4185',
    },
    name: 'Global Tribe • Tent',
    price: '  50€',
    location: 'Almonte, Spain',
    projectType: ['nature'],
    image: dimage3,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link: '/l/enriquezarte-entire-accomodation/606c6f7f-54c3-461e-a711-0ffcd2e19bc7',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'enriquezarte-entire-accomodation',
      id: '606c6f7f-54c3-461e-a711-0ffcd2e19bc7',
    },
    name: 'EnriquezArte • Entire accommodation',
    price: '  60€ ',
    location: 'Barcelona, Spain',
    projectType: ['equality', 'education'],
    image: c16image4,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/outdoor-artists-doublebedroom/61027bc1-cffc-4b23-bf8d-c0763b67cd3c',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'outdoor-artists-doublebedroom',
      id: '61027bc1-cffc-4b23-bf8d-c0763b67cd3c',
    },
    name: 'Outdoor Artists • Double room',
    price: ' 35€ ',
    location: 'Marsa, Malta',
    projectType: ['equality', 'education'],
    image: dimage5,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link: '/l/bioagriturismo-podere-montisi-doublebedroom/63eabf21-34e1-4e52-bc90-3e812f2aee00',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'bioagriturismo-podere-montisi-doublebedroom',
      id: '63eabf21-34e1-4e52-bc90-3e812f2aee00',
    },
    name: 'Bioagriturismo Podere Montisi • Double room',
    price: '  140€ ',
    location: 'Calenzano, Italy',
    projectType: ['nature', 'education'],
    image: c8image7,
    finalReviews: 4.9,
    totalFinalReviews: 5,
  },
  {
    link: '/l/penduka-trust-doublebedroom/60e462cb-1cd7-469a-b724-8c7ad5713ad5',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'penduka-trust-doublebedroom',
      id: '60e462cb-1cd7-469a-b724-8c7ad5713ad5',
    },
    name: 'Penduka Trust • Entire accommodation',
    price: '  56€ ',
    location: 'Windhuk, Namibia',
    projectType: ['education', 'nature'],
    image: image1,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/pepy-empowering-youth-singlebedroom/60f35304-19ba-465f-b45d-7b534f67d9f5',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'pepy-empowering-youth-singlebedroom',
      id: '60f35304-19ba-465f-b45d-7b534f67d9f5',
    },
    name: 'PEPY Empowering Youth • Single room',
    price: '  13€ ',
    location: 'Siem Reap, Cambodia',
    projectType: ['sports', 'education'],
    image: c11image11,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/al-numiera-environmental-association-camping/61f95018-53db-4fdb-b7be-7ac78a7a93d4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'al-numiera-environmental-association-camping',
      id: '61f95018-53db-4fdb-b7be-7ac78a7a93d4',
    },
    name: 'Al Numiera Environmental Association • Tent',
    price: '  12€ ',
    location: 'Gawr al-Mazraah, Jordan',
    projectType: ['nature', 'education'],
    image: c13image6,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/orto-dell-idro-camping/62a0715b-39ee-4d9e-b6f9-020c014e0de6',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'orto-dell-idro-camping',
      id: '62a0715b-39ee-4d9e-b6f9-020c014e0de6',
    },
    name: 'Orto DellIdro • Tent',
    price: '  25€ ',
    location: 'Otranto, Italy',
    projectType: ['nature', 'sports'],
    image: c0image6,
    finalReviews: 2,
    totalFinalReviews: 1,
  },
  {
    link: '/l/biloba-organic-twobedroom/6322e038-87fc-4487-97de-3ee260d9ab30',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'biloba-organic-twobedroom',
      id: '6322e038-87fc-4487-97de-3ee260d9ab30',
    },
    name: 'Biloba Organic • Twin room',
    price: '  45€ ',
    location: 'Nawalpur, Nepal',
    projectType: ['nature', 'education'],
    image: c13image3,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link: '/l/mana-earthly-paradise-male-dorm-shared-bedroom/606ecffd-5fb6-4a77-a519-2c6fa25a1323',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'biloba-organic-twobedroom',
      id: '6322e038-87fc-4487-97de-3ee260d9ab30',
    },
    name: 'Mana Earthly Paradise - male dorm • Dormitory',
    price: ' 23€ ',
    location: 'Ubud, Indonesia',
    projectType: [],
    image: c7image10,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link: '/l/oye-lena-camping/629f6799-92ed-43f7-8bd1-9f05e7e66abf',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'oye-lena-camping',
      id: '629f6799-92ed-43f7-8bd1-9f05e7e66abf',
    },
    name: 'Oye LENA • Tent',
    price: '  7€ ',
    location: 'Curahuasi, Peru',
    projectType: ['equality', 'education'],
    image: c0image7,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/casa-kolping-quito-shared-bedroom/63e65f60-e361-4530-bf0f-4298337a32a0',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'casa-kolping-quito-shared-bedroom',
      id: '63e65f60-e361-4530-bf0f-4298337a32a0',
    },
    name: 'Casa Kolping Quito • Dormitory',
    price: '  20€ ',
    location: 'QUITO , Ecuador',
    projectType: ['equality', 'education'],
    image: c0image8,
    finalReviews: 4.5,
    totalFinalReviews: 0,
  },
  {
    link: '/l/kollektiv-schicksaal-shared-bedroom/618bcc25-566e-4f31-a37a-601454643eb4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'kollektiv-schicksaal-shared-bedroom',
      id: '618bcc25-566e-4f31-a37a-601454643eb4',
    },
    name: 'Kollektiv schickSAAL* • Dormitory',
    price: '  30€ ',
    location: 'Lübeck, Germany',
    projectType: [],
    image: c0image9,
    finalReviews: 4.7,
    totalFinalReviews: 0,
  },
];

export const category0 = [
  {
    link: '/l/woven-connect-shared-bedroom/61d450b7-9483-44b3-8691-f4dbaef76ba0',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'woven-connect-shared-bedroom',
      id: '61d450b7-9483-44b3-8691-f4dbaef76ba0',
    },
    name: 'Woven Connect • Dormitory',
    price: '  12€ ',
    projectType: ['equality', 'education'],
    location: 'Arusha, Tanzania',
    image: c0image1,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/leppapirtti-shared-bedroom/63aecd80-c92a-40bd-a4a5-de146250852b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'leppapirtti-shared-bedroom',
      id: '63aecd80-c92a-40bd-a4a5-de146250852b',
    },
    name: 'Leppäpirtti • Dormitory',
    price: ' 35€ ',
    location: 'Leppäjärvi, Finland',
    projectType: ['nature'],
    image: c0image2,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/talking-through-art-entire-accomodation/63f4ce2a-abc5-47b4-bfce-ae57f681fab2',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'talking-through-art-entire-accomodation',
      id: '63f4ce2a-abc5-47b4-bfce-ae57f681fab2',
    },
    name: 'Talking Through Art • Entire accommodation',
    price: '  30€ ',
    location: 'Kigali, Rwanda',
    projectType: ['equality', 'education'],
    image: c0image3,
    finalReviews: 4.6,
    totalFinalReviews: 5,
  },
  {
    link: '/l/ecotopia-park-entire-accomodation/63c838bd-6326-4c19-ab2f-d65ab8e46340',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ecotopia-park-entire-accomodation',
      id: '63c838bd-6326-4c19-ab2f-d65ab8e46340',
    },
    name: 'Ecotopía Park • Entire accommodation',
    price: '  23€',
    location: 'Las Terrenas, Dominican Republic',
    projectType: ['nature', 'education'],
    image: c15image3,
    finalReviews: 4.1,
    totalFinalReviews: 5,
  },
  {
    link: '/l/etiv-do-brasil-doublebedroom/633c2467-e8f8-4888-b992-86e3ef7f6bc3',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'etiv-do-brasil-doublebedroom',
      id: '633c2467-e8f8-4888-b992-86e3ef7f6bc3',
    },
    name: 'ETIV do Brasil • Entire accommodation',
    price: ' 25€ ',
    location: 'Itacaré, Brazil',
    projectType: ['nature', 'education'],
    image: c0image3b,
    finalReviews: 4.8,
    totalFinalReviews: 5,
  },
  {
    link: '/l/muhaan-doublebedroom/6375d30d-fee0-4ac4-8999-b29b98c55648',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'muhaan-doublebedroom',
      id: '6375d30d-fee0-4ac4-8999-b29b98c55648',
    },
    name: 'Muhaan • Double room',
    price: ' 30€ ',
    location: 'Kalimpong, India',
    projectType: ['equality', 'education'],
    image: c0image4,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/project-wings-twobedroom/62deafa0-5661-4bb7-a058-ea7c1166cf3b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'project-wings-twobedroom',
      id: '62deafa0-5661-4bb7-a058-ea7c1166cf3b',
    },
    name: 'Project Wings • Twin room',
    price: '  11€ ',
    location: 'Bukit Lawang, Indonesia',
    projectType: ['nature', 'education'],
    image: c0image5,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link: '/l/lurreko-aromaticas-y-biodiversidad-camping/63092f17-0285-4456-8c0c-40acc84e8efe',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'lurreko-aromaticas-y-biodiversidad-camping',
      id: '63092f17-0285-4456-8c0c-40acc84e8efe',
    },
    name: 'Lurreko Aromáticas y Biodiversidad • Tent',
    price: '  18€ ',
    location: 'Préjano, Spain',
    projectType: ['nature', 'education'],
    image: c14image1,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link: '/l/pepy-empowering-youth-singlebedroom/60f35304-19ba-465f-b45d-7b534f67d9f5',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'pepy-empowering-youth-singlebedroom',
      id: '60f35304-19ba-465f-b45d-7b534f67d9f5',
    },
    name: 'PEPY Empowering Youth • Single room',
    price: '  13€ ',
    location: 'Siem Reap, Cambodia',
    projectType: ['sports', 'education'],
    image: c11image11,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/al-numiera-environmental-association-camping/61f95018-53db-4fdb-b7be-7ac78a7a93d4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'al-numiera-environmental-association-camping',
      id: '61f95018-53db-4fdb-b7be-7ac78a7a93d4',
    },
    name: 'Al Numiera Environmental Association • Tent',
    price: '  12€ ',
    location: 'Gawr al-Mazraah, Jordan',
    projectType: ['nature', 'education'],
    image: c13image6,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/orto-dell-idro-camping/62a0715b-39ee-4d9e-b6f9-020c014e0de6',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'orto-dell-idro-camping',
      id: '62a0715b-39ee-4d9e-b6f9-020c014e0de6',
    },
    name: 'Orto DellIdro • Tent',
    price: '  25€ ',
    location: 'Otranto, Italy',
    projectType: ['nature', 'sports'],
    image: c0image6,
    finalReviews: 2,
    totalFinalReviews: 1,
  },
  {
    link: '/l/biloba-organic-twobedroom/6322e038-87fc-4487-97de-3ee260d9ab30',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'biloba-organic-twobedroom',
      id: '6322e038-87fc-4487-97de-3ee260d9ab30',
    },
    name: 'Biloba Organic • Twin room',
    price: '  45€ ',
    location: 'Nawalpur, Nepal',
    projectType: ['nature', 'education'],
    image: c13image3,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link: '/l/mana-earthly-paradise-male-dorm-shared-bedroom/606ecffd-5fb6-4a77-a519-2c6fa25a1323',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mana-earthly-paradise-male-dorm-shared-bedroom',
      id: '606ecffd-5fb6-4a77-a519-2c6fa25a1323',
    },
    name: 'Mana Earthly Paradise - male dorm • Dormitory',
    price: ' 23€ ',
    location: 'Ubud, Indonesia',
    projectType: [],
    image: c7image10,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/oye-lena-camping/629f6799-92ed-43f7-8bd1-9f05e7e66abf',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'oye-lena-camping',
      id: '629f6799-92ed-43f7-8bd1-9f05e7e66abf',
    },
    name: 'Oye LENA • Tent',
    price: '  7€ ',
    location: 'Curahuasi, Peru',
    projectType: ['equality', 'education'],
    image: c0image7,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/casa-kolping-quito-shared-bedroom/63e65f60-e361-4530-bf0f-4298337a32a0',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'casa-kolping-quito-shared-bedroom',
      id: '63e65f60-e361-4530-bf0f-4298337a32a0',
    },
    name: 'Casa Kolping Quito • Dormitory',
    price: '  20€ ',
    location: 'QUITO , Ecuador',
    projectType: ['equality', 'education'],
    image: c0image8,
    finalReviews: 4.5,
    totalFinalReviews: 0,
  },
  {
    link: '/l/kollektiv-schicksaal-shared-bedroom/618bcc25-566e-4f31-a37a-601454643eb4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'kollektiv-schicksaal-shared-bedroom',
      id: '618bcc25-566e-4f31-a37a-601454643eb4',
    },
    name: 'Kollektiv schickSAAL* • Dormitory',
    price: '  30€ ',
    location: 'Lübeck, Germany',
    projectType: [],
    image: c0image9,
    finalReviews: 4.7,
    totalFinalReviews: 0,
  },
];

export const category1 = [
  {
    link: '/l/cooperativa-turistica-de-yaxunah-doublebedroom/60dde256-e06b-4019-bbdb-8837b4f704f2',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'cooperativa-turistica-de-yaxunah-doublebedroom',
      id: '60dde256-e06b-4019-bbdb-8837b4f704f2',
    },
    name: 'Cooperativa Turistica de Yaxunah • Double room',
    price: '  32€ ',
    location: 'Yaxunah, Mexico',
    projectType: ['nature', 'equality'],
    image: image9,
    finalReviews: 4.8,
    totalFinalReviews: 5,
  },
  {
    link: '/l/penduka-trust-entire-accomodation/60e46a38-2f08-458d-8361-0e804f9247c8',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'penduka-trust-entire-accomodation',
      id: '60e46a38-2f08-458d-8361-0e804f9247c8',
    },
    name: 'Penduka Trust • Entire accommodation',
    price: '  91€ ',
    location: 'Windhuk, Namibia',
    projectType: ['education', 'nature'],
    image: image1,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link:
      '/l/ecoturismo-comunitario-yunguilla-entire-accomodation/635ac02d-0848-4099-b85a-add5e4aaf799',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ecoturismo-comunitario-yunguilla-entire-accomodation',
      id: '635ac02d-0848-4099-b85a-add5e4aaf799',
    },
    name: 'Ecoturismo Comunitario Yunguilla • Entire accommodation',
    price: '  30€ ',
    location: 'Quito, Ecuador',
    projectType: ['nature', 'equality'],
    image: image3,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/pina-palmera-shared-bedroom/606c82f1-ac06-49ec-9fbe-5c1abe9f89bd',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'pina-palmera-shared-bedroom',
      id: '606c82f1-ac06-49ec-9fbe-5c1abe9f89bd',
    },
    name: 'Piña Palmera • Dormitory',
    price: '  16€ ',
    location: 'Playa Zipolite, Mexico',
    projectType: ['education', 'equality'],
    image: image4,
    finalReviews: 4.7,
    totalFinalReviews: 0,
  },
  {
    link: '/l/fundacion-atasim-twobedroom/62821fdc-a162-4876-a1ca-9a6e51c213d8',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'fundacion-atasim-twobedroom',
      id: '62821fdc-a162-4876-a1ca-9a6e51c213d8',
    },
    name: 'Fundación ATASIM • Twin room',
    price: '  24€ ',
    location: 'San Isidro, Ecuador',
    projectType: ['education', 'equality'],
    image: image8,
    finalReviews: 4.3,
    totalFinalReviews: 0,
  },
  {
    link: '/l/finca-hazlotumism-twobedroom/61c616f4-4ef3-4ed7-bf80-42989ef90169',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'finca-hazlotumism-twobedroom',
      id: '61c616f4-4ef3-4ed7-bf80-42989ef90169',
    },
    name: 'Finca hazloTumism@ • Twin room',
    price: '  20€ ',
    location: 'Cáqueza - Cundinamarca, Colombia',
    projectType: ['equality', 'nature'],
    image: image10,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/mbunga-community-camp-twobedroom/623885ca-157b-4c7e-ab61-e557c37c2000',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mbunga-community-camp-twobedroom',
      id: '623885ca-157b-4c7e-ab61-e557c37c2000',
    },
    name: 'Mbunga Community Camp • Twin room',
    price: '  47€ ',
    location: 'Kasese, Uganda',
    image: image11,
    projectType: ['equality', 'education'],
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/red-rocks-intercultural-center-entire-accomodation/61387322-0cea-4748-8815-6ed46be2a572',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'red-rocks-intercultural-center-entire-accomodation',
      id: '61387322-0cea-4748-8815-6ed46be2a572',
    },
    name: 'Red Rocks Intercultural Center • Entire accommodation',
    price: '  50€ ',
    location: 'Nyakinama, Rwanda',
    projectType: [],
    image: image2,
    finalReviews: 4.4,
    totalFinalReviews: 0,
  },
  {
    link: '/l/casa-pousadoira-twobedroom/625fbccc-5367-437f-9c29-35071ab25b80',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'casa-pousadoira-twobedroom',
      id: '625fbccc-5367-437f-9c29-35071ab25b80',
    },
    name: 'Casa Pousadoira • Twin room',
    price: '  71€ ',
    location: 'Miño, Spain',
    projectType: [],
    image: image5,
    finalReviews: 4.6,
    totalFinalReviews: 0,
  },
];

export const category2 = [
  {
    link: '/l/borgo-del-ginepro-entire-accomodation/624b0eb7-42ca-4174-81cb-892f9e72fce5',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'borgo-del-ginepro-entire-accomodation',
      id: '624b0eb7-42ca-4174-81cb-892f9e72fce5',
    },
    name: 'Borgo del Ginepro • Entire accommodation',
    price: '  140€ ',
    location: 'Castiglione Messer Raimondo, Italy',
    projectType: ['nature', 'equality'],
    image: c8image5,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/wampendobl-paradies-entire-accomodation/624a91f2-0c05-4b1f-939a-169d8a210e16',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'wampendobl-paradies-entire-accomodation',
      id: '624a91f2-0c05-4b1f-939a-169d8a210e16',
    },
    name: 'Wampendobl Paradies • Entire accommodation',
    price: '  218€ ',
    location: 'Egglham, Germany',
    projectType: ['animal', 'health'],
    image: c8image6,
    finalReviews: 4.9,
    totalFinalReviews: 5,
  },
  {
    link: '/l/bioagriturismo-podere-montisi-doublebedroom/63eabf21-34e1-4e52-bc90-3e812f2aee00',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'bioagriturismo-podere-montisi-doublebedroom',
      id: '63eabf21-34e1-4e52-bc90-3e812f2aee00',
    },
    name: 'Bioagriturismo Podere Montisi • Double room',
    price: '  140€ ',
    location: 'Calenzano, Italy',
    projectType: ['nature', 'education'],
    image: c8image7,
    finalReviews: 4.9,
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/khwa-ttu-san-culture-and-education-centre-entire-accomodation/62bae7da-7886-4565-b267-1d0f4a007bde',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'khwa-ttu-san-culture-and-education-centre-entire-accomodation',
      id: '62bae7da-7886-4565-b267-1d0f4a007bde',
    },
    name: '!Khwa ttu San Culture and Education Centre • Entire accommodation',
    price: ' 144€ ',
    location: 'Yzerfontein, South Africa',
    projectType: ['nature', 'education'],
    image: c2image1,
    finalReviews: 4.7,
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/greenscape-resort-bangladesch-mati-bangladesh-singlebedroom/614b586d-5a53-4b3a-acfd-1db5ac700d6d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'greenscape-resort-bangladesch-mati-bangladesh-singlebedroom',
      id: '614b586d-5a53-4b3a-acfd-1db5ac700d6d',
    },
    name: 'Greenscape Resort Bangladesch (MATI Bangladesh) • Single room',
    price: '  25€ ',
    location: 'Mymensingh, Bangladesh',
    projectType: ['equality', 'nature'],
    image: c7image9,
    finalReviews: 4.2,
    totalFinalReviews: 5,
  },
  {
    link: '/l/cooperativa-turistica-de-yaxunah-doublebedroom/60dde256-e06b-4019-bbdb-8837b4f704f2',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'cooperativa-turistica-de-yaxunah-doublebedroom',
      id: '60dde256-e06b-4019-bbdb-8837b4f704f2',
    },
    name: 'Cooperativa Turistica de Yaxunah • Double room',
    price: ' 32€ ',
    location: 'Yaxunah, Mexico',
    projectType: ['nature', 'equality'],
    image: image9,
    finalReviews: 4.8,
    totalFinalReviews: 5,
  },
  {
    link: '/l/finca-hazlotumism-twobedroom/61c616f4-4ef3-4ed7-bf80-42989ef90169',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'finca-hazlotumism-twobedroom',
      id: '61c616f4-4ef3-4ed7-bf80-42989ef90169',
    },
    name: 'Finca hazloTumism@ • Twin room',
    price: ' 20€ ',
    location: 'Cáqueza - Cundinamarca, Colombia',
    projectType: ['nature', 'education'],
    image: image10,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/mbunga-community-camp-twobedroom/623885ca-157b-4c7e-ab61-e557c37c2000',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mbunga-community-camp-twobedroom',
      id: '623885ca-157b-4c7e-ab61-e557c37c2000',
    },
    name: 'Mbunga Community Camp • Twin room',
    price: ' 47€ ',
    location: 'Kasese, Uganda',
    projectType: ['equality', 'education'],
    image: image11,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/red-rocks-intercultural-center-entire-accomodation/61387322-0cea-4748-8815-6ed46be2a572',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'red-rocks-intercultural-center-entire-accomodation',
      id: '61387322-0cea-4748-8815-6ed46be2a572',
    },
    name: 'Red Rocks Intercultural Center • Entire accommodation',
    price: ' 50€ ',
    location: 'Nyakinama, Rwanda',
    projectType: [],
    image: image2,
    finalReviews: 4.4,
    totalFinalReviews: 0,
  },
  {
    link: '/l/casa-pousadoira-twobedroom/625fbccc-5367-437f-9c29-35071ab25b80',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'casa-pousadoira-twobedroom',
      id: '625fbccc-5367-437f-9c29-35071ab25b80',
    },
    name: 'Casa Pousadoira • Twin room',
    price: '  71€ ',
    location: 'Miño, Spain',
    projectType: [],
    image: image5,
    finalReviews: 4.6,
    totalFinalReviews: 0,
  },
  {
    link: '/l/reserva-natural-xieti-entire-accomodation/60fe3a9d-e47f-44e9-aeb4-90981cef49cb',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'reserva-natural-xieti-entire-accomodation',
      id: '60fe3a9d-e47f-44e9-aeb4-90981cef49cb',
    },
    name: 'Reserva Natural Xieti • Entire accommodation',
    price: ' 180€ ',
    location: 'Tota, Colombia',
    projectType: [],
    image: c12image1,
    finalReviews: 4.9,
    totalFinalReviews: 0,
  },
];

export const category3 = [
  {
    link:
      '/l/khwa-ttu-san-culture-and-education-centre-entire-accomodation/62bae7da-7886-4565-b267-1d0f4a007bde',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'khwa-ttu-san-culture-and-education-centre-entire-accomodation',
      id: '62bae7da-7886-4565-b267-1d0f4a007bde',
    },
    name: 'Khwa ttu San Culture and Education Centre • Entire accommodation',
    price: ' 144€ ',
    location: 'Yzerfontein, South Africa',
    projectType: ['nature', 'education'],
    image: c3image3,
    finalReviews: 4.7,
    totalFinalReviews: 5,
  },
  {
    link: '/l/sina-village-singlebedroom/62834867-d9d3-4ae5-854a-ff7b8216101f',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'sina-village-singlebedroom',
      id: '62834867-d9d3-4ae5-854a-ff7b8216101f',
    },
    name: 'SINA VILLAGE • Entire accommodation',
    price: ' 35€ ',
    location: 'Mpigi, Uganda',
    projectType: ['nature', 'education'],
    image: c3image4,
    finalReviews: 4.4,
    totalFinalReviews: 0,
  },
  {
    link: '/l/hobatere-lodge-doublebedroom/62a1fbe3-6b0d-42f8-931c-338b05cef525',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hobatere-lodge-doublebedroom',
      id: '62a1fbe3-6b0d-42f8-931c-338b05cef525',
    },
    name: 'Hobatere Lodge • Double room',
    price: ' 304€ ',
    projectType: ['animal', 'nature'],
    location: 'Kamanjab, Namibia',
    image: c3image8,
    finalReviews: 4.4,
    totalFinalReviews: 0,
  },
  {
    link: '/l/penduka-trust-entire-accomodation/60e46a38-2f08-458d-8361-0e804f9247c8',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'penduka-trust-entire-accomodation',
      id: '60e46a38-2f08-458d-8361-0e804f9247c8',
    },
    name: 'Penduka Trust • Entire accommodation',
    price: ' 91€ ',
    location: 'Windhuk, Namibia',
    projectType: ['equality', 'nature'],
    image: c3image1,
    finalReviews: 4.2,
    totalFinalReviews: 0,
  },
  {
    link: '/l/cochas-eco-lodge-camp-entire-accomodation/60f85552-461c-428c-ae7e-408c5922c1a4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'cochas-eco-lodge-camp-entire-accomodation',
      id: '60f85552-461c-428c-ae7e-408c5922c1a4',
    },
    name: 'Cochas Eco Lodge & Camp • Entire accommodation',
    price: ' 235€ ',
    location: 'Zuleta, Ecuador',
    projectType: ['nature', 'education'],
    image: c3image10,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/kapawi-shared-bedroom/606d85bf-7a75-41e1-b694-0dc98ba68971',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'kapawi-shared-bedroom',
      id: '606d85bf-7a75-41e1-b694-0dc98ba68971',
    },
    name: 'Kapawi Ecolodge • Twin room',
    price: ' 265€ ',
    location: 'Pastaza Province, Ecuador',
    projectType: ['nature', 'education'],
    image: c3image11,
    finalReviews: 4.7,
    totalFinalReviews: 24,
  },
  {
    link: '/l/litro-de-luz-camping/6123f604-6315-4f7b-bde2-fa6d040d6525',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'litro-de-luz-camping',
      id: '6123f604-6315-4f7b-bde2-fa6d040d6525',
    },
    name: 'Litro de Luz • Camping',
    price: ' 20€ ',
    location: 'Rio Grande Abajo, DR',
    projectType: ['nature', 'education'],
    image: c3image9,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/ecoturismo-comunitario-yunguilla-entire-accomodation/635ac02d-0848-4099-b85a-add5e4aaf799',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ecoturismo-comunitario-yunguilla-entire-accomodation',
      id: '635ac02d-0848-4099-b85a-add5e4aaf799',
    },
    name: 'Ecoturismo Comunitario Yunguilla • Entire accommodation',
    price: ' 30€ ',
    location: 'Quito, Ecuador',
    projectType: ['nature', 'equality'],
    image: c3image12,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/fundacion-atasim-entire-accomodation/627e75f6-4800-4f02-bb7c-6d54a27fe8d2',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'fundacion-atasim-entire-accomodation',
      id: '627e75f6-4800-4f02-bb7c-6d54a27fe8d2',
    },
    name: 'Fundación ATASIM • Entire accommodation',
    price: ' 30€ ',
    location: 'Macas, Ecuador',
    projectType: ['equality', 'education'],
    image: c3image13,
    finalReviews: 4.6,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/red-rocks-initiatives-for-sustainable-development-twobedroom/607829c7-b018-48f6-90a1-99732cc108f2',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'red-rocks-initiatives-for-sustainable-development-twobedroom',
      id: '607829c7-b018-48f6-90a1-99732cc108f2',
    },
    name: 'Red Rocks Intercultural Center • Twin room',
    price: ' 40€ ',
    location: 'Nyakinama, Rwanda',
    projectType: ['nature', 'education'],
    image: c3image2,
    finalReviews: 4.4,
    totalFinalReviews: 0,
  },
  {
    link: '/l/intercultural-development-agency-singlebedroom/6073f16c-0727-4ea2-abb8-1ae73d8abdf5',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'intercultural-development-agency-singlebedroom',
      id: '6073f16c-0727-4ea2-abb8-1ae73d8abdf5',
    },
    name: 'Intercultural Development Agency (IDA) • Entire accommodation',
    price: ' 82€ ',
    location: 'Kampala, Uganda',
    projectType: ['nature', 'education'],
    image: c3image5,
    finalReviews: 4,
    totalFinalReviews: 0,
  },
  {
    link: '/l/mbunga-community-camp-twobedroom/623885ca-157b-4c7e-ab61-e557c37c2000',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mbunga-community-camp-twobedroom',
      id: '623885ca-157b-4c7e-ab61-e557c37c2000',
    },
    name: 'Mbunga Community Camp • Twin room',
    price: ' 47€ ',
    location: 'Kasese, Uganda',
    projectType: ['education', 'equality'],
    image: c3image6,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/better-women-foundation-shared-bedroom/628a52d3-a7f9-41b3-b6ea-384adcdbb0e7',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'better-women-foundation-shared-bedroom',
      id: '628a52d3-a7f9-41b3-b6ea-384adcdbb0e7',
    },
    name: 'Better Women Foundation • Dormitory',
    price: ' 45€ ',
    location: 'RUBIRIZI, Uganda',
    projectType: [],
    image: c3image7,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
];

export const category4 = [
  {
    link: '/l/tirrintippin-entire-accomodation/63a41e4e-00b4-41e2-b721-45f9a15a0e39',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'tirrintippin-entire-accomodation',
      id: '63a41e4e-00b4-41e2-b721-45f9a15a0e39',
    },
    name: 'Tirrintippin • Entire accomodation',
    price: ' 81€ ',
    location: 'Elands, Australia',
    projectType: ['nature', 'education'],
    image: c4image6,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/hobatere-lodge-doublebedroom/62a1fbe3-6b0d-42f8-931c-338b05cef525',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hobatere-lodge-doublebedroom',
      id: '62a1fbe3-6b0d-42f8-931c-338b05cef525',
    },
    name: 'Hobatere Lodge • Double room',
    price: ' 304€ ',
    location: 'Kamanjab, Namibia',
    projectType: ['animal', 'nature'],
    image: c4image10,
    finalReviews: 4.4,
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/khwa-ttu-san-culture-and-education-centre-entire-accomodation/62bae7da-7886-4565-b267-1d0f4a007bde',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'khwa-ttu-san-culture-and-education-centre-entire-accomodation',
      id: '62bae7da-7886-4565-b267-1d0f4a007bde',
    },
    name: 'Khwa ttu San Culture and Education Centre • Entire accommodation',
    price: ' 144€ ',
    location: 'Yzerfontein, South Africa',
    projectType: ['nature', 'education'],
    image: c4image3,
    finalReviews: 4.7,
    totalFinalReviews: 5,
  },
  {
    link: '/l/sina-village-singlebedroom/62834867-d9d3-4ae5-854a-ff7b8216101f',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'sina-village-singlebedroom',
      id: '62834867-d9d3-4ae5-854a-ff7b8216101f',
    },
    name: 'SINA VILLAGE • Entire accommodation',
    price: ' 35€ ',
    location: 'Mpigi, Uganda',
    projectType: ['nature', 'education'],
    image: c4image5,
    finalReviews: 4.4,
    totalFinalReviews: 5,
  },
  {
    link: '/l/grootberg-lodge-doublebedroom/62a0ce34-b8bf-4f85-9365-58bff1a12257',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'grootberg-lodge-doublebedroom',
      id: '62a0ce34-b8bf-4f85-9365-58bff1a12257',
    },
    name: 'Grootberg Lodge • Double room',
    price: ' 374€ ',
    location: 'Palmwag, Namibia',
    projectType: ['animal', 'nature'],
    image: c4image11,
    finalReviews: 4.6,
    totalFinalReviews: 5,
  },
  {
    link: '/l/casa-speranto-entire-accomodation/65f34f9e-2975-4931-ab4d-8144eb85686b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'casa-speranto-entire-accomodation',
      id: '65f34f9e-2975-4931-ab4d-8144eb85686b',
    },
    name: 'Casa sPERANTO • Entire accomodation',
    price: ' 111€ ',
    location: 'Canow, Germany',
    projectType: ['nature', 'education'],
    image: c4image4,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/intercultural-development-agency-singlebedroom/6073f16c-0727-4ea2-abb8-1ae73d8abdf5',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'intercultural-development-agency-singlebedroom',
      id: '6073f16c-0727-4ea2-abb8-1ae73d8abdf5',
    },
    name: 'Intercultural Development Agency (IDA) • Entire accommodation',
    price: ' 82€ ',
    location: 'Canow, Germany',
    projectType: ['education', 'equality'],
    image: c4image7,
    finalReviews: 4,
    totalFinalReviews: 3,
  },
  {
    link: '/l/simabo-onlus-twobedroom/60744db3-e69d-4ef5-81b4-15047b532759',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'simabo-onlus-twobedroom',
      id: '60744db3-e69d-4ef5-81b4-15047b532759',
    },
    name: 'Simabo ONLUS • Twin room',
    price: ' 35€ ',
    location: 'Mindelo, Cape Verde',
    projectType: ['animal', 'nature'],
    image: c4image1,
    finalReviews: 4.5,
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/red-rocks-initiatives-for-sustainable-development-twobedroom/607829c7-b018-48f6-90a1-99732cc108f2',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'red-rocks-initiatives-for-sustainable-development-twobedroom',
      id: '607829c7-b018-48f6-90a1-99732cc108f2',
    },
    name: 'Red Rocks Intercultural Center • Twin room',
    price: ' 40€ ',
    location: 'Nyakinama, Rwanda',
    projectType: ['nature', 'education'],
    image: c4image2,
    finalReviews: 4.4,
    totalFinalReviews: 0,
  },
  {
    link: '/l/better-women-foundation-shared-bedroom/628a52d3-a7f9-41b3-b6ea-384adcdbb0e7',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'better-women-foundation-shared-bedroom',
      id: '628a52d3-a7f9-41b3-b6ea-384adcdbb0e7',
    },
    name: 'Better Women Foundation • Dormitory',
    price: ' 45€ ',
    location: 'RUBIRIZI, Uganda',
    projectType: ['nature', 'equality'],
    image: c4image8,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/hoada-campsite-camping/62a1da2b-1c62-4ed1-8469-09227bb896a1',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hoada-campsite-camping',
      id: '62a1da2b-1c62-4ed1-8469-09227bb896a1',
    },
    name: 'Hoada Campsite • Camping',
    price: ' 60€ ',
    location: 'Kamanjab, Namibia',
    projectType: ['animal', 'nature'],
    image: c4image9,
    finalReviews: 4.7,
    totalFinalReviews: 5,
  },
  {
    link: '/l/litro-de-luz-camping/6123f604-6315-4f7b-bde2-fa6d040d6525',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'litro-de-luz-camping',
      id: '6123f604-6315-4f7b-bde2-fa6d040d6525',
    },
    name: 'Litro de Luz • Camping',
    price: ' 20€ ',
    location: 'Rio Grande Abajo, DR',
    projectType: ['nature', 'education'],
    image: c4image12,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/cochas-eco-lodge-camp-entire-accomodation/60f85552-461c-428c-ae7e-408c5922c1a4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'cochas-eco-lodge-camp-entire-accomodation',
      id: '60f85552-461c-428c-ae7e-408c5922c1a4',
    },
    name: 'Cochas Eco Lodge & Camp • Entire accommodation',
    price: ' 235€ ',
    location: 'Zuleta, Ecuador',
    projectType: [],
    image: c4image13,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/kapawi-shared-bedroom/606d85bf-7a75-41e1-b694-0dc98ba68971',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'kapawi-shared-bedroom',
      id: '606d85bf-7a75-41e1-b694-0dc98ba68971',
    },
    name: 'Kapawi Ecolodge •',
    price: ' 265€ ',
    location: 'Pastaza Province, Ecuador',
    projectType: [],
    image: c4image14,
    finalReviews: 4.7,
    totalFinalReviews: 24,
  },
];

export const category5 = [
  {
    link:
      '/l/khwa-ttu-san-culture-and-education-centre-entire-accomodation/62bae7da-7886-4565-b267-1d0f4a007bde',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'khwa-ttu-san-culture-and-education-centre-entire-accomodation',
      id: '62bae7da-7886-4565-b267-1d0f4a007bde',
    },
    name: 'Khwa ttu San Culture and Education Centre • Entire accommodation',
    price: ' 144€ ',
    location: 'Yzerfontein, South Africa',
    projectType: ['nature', 'education'],
    image: c5image3,
    finalReviews: 4.7,
    totalFinalReviews: 5,
  },
  {
    link: '/l/casa-speranto-entire-accomodation/65f34f9e-2975-4931-ab4d-8144eb85686b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'casa-speranto-entire-accomodation',
      id: '65f34f9e-2975-4931-ab4d-8144eb85686b',
    },
    name: 'Casa sPERANTO • Entire accomodation',
    price: ' 111€ ',
    location: 'Canow, Germany',
    projectType: ['nature', 'education'],
    image: c4image4,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/cochas-eco-lodge-camp-entire-accomodation/60f85552-461c-428c-ae7e-408c5922c1a4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'cochas-eco-lodge-camp-entire-accomodation',
      id: '60f85552-461c-428c-ae7e-408c5922c1a4',
    },
    name: 'Cochas Eco Lodge & Camp • Entire accommodation',
    price: ' 235€ ',
    location: 'Zuleta, Ecuador',
    projectType: ['nature', 'education'],
    image: c5image6,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/oye-lena-doublebedroom/606d654c-a79b-42bf-bb74-95a96ac69712',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'oye-lena-doublebedroom',
      id: '606d654c-a79b-42bf-bb74-95a96ac69712',
    },
    name: 'Oye LENA • Double room',
    price: ' 48€ ',
    location: 'Curahuasi, Peru',
    projectType: ['education', 'equality'],
    image: c5image11,
    finalReviews: '5.0',
    totalFinalReviews: 3,
  },
  {
    link: '/l/dos-aguas-doublebedroom/6264284b-17cf-440c-8dc0-31fda6cc844a',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'dos-aguas-doublebedroom',
      id: '6264284b-17cf-440c-8dc0-31fda6cc844a',
    },
    name: 'Dos Aguas Lodge • Double room',
    price: ' 95€ ',
    location: 'San Onofre, Colombia',
    projectType: ['nature', 'equality'],
    image: c5image9,
    finalReviews: 4.8,
    totalFinalReviews: 5,
  },
  {
    link: '/l/intiwawa-arequipa-intihouse-shared-bedroom/60781b58-6b2c-4506-80b5-213855343cef',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'intiwawa-arequipa-intihouse-shared-bedroom',
      id: '60781b58-6b2c-4506-80b5-213855343cef',
    },
    name: 'Intiwawa Arequipa & Colca - Intihouse • Single room',
    price: ' 10€ ',
    location: 'Arequipa, Peru',
    projectType: [],
    image: c5image10,
    finalReviews: 4.9,
    totalFinalReviews: 4,
  },
  {
    link: '/l/kapawi-shared-bedroom/606d85bf-7a75-41e1-b694-0dc98ba68971',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'kapawi-shared-bedroom',
      id: '606d85bf-7a75-41e1-b694-0dc98ba68971',
    },
    name: 'Kapawi Ecolodge •',
    price: ' 265€ ',
    location: 'Pastaza Province, Ecuador',
    projectType: ['nature', 'education'],
    image: c5image7,
    finalReviews: 4.7,
    totalFinalReviews: 24,
  },
  {
    link: '/l/proyecto-hazlotumism-doublebedroom/61c616f4-4ef3-4ed7-bf80-42989ef90169',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'proyecto-hazlotumism-doublebedroom',
      id: '61c616f4-4ef3-4ed7-bf80-42989ef90169',
    },
    name: 'Finca hazloTumism@ • Twin room',
    price: ' 20€ ',
    location: 'Cáqueza - Cundinamarca, Colombia',
    projectType: ['nature', 'education'],
    image: c5image12,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/reserva-biologica-caoba-doublebedroom/62337420-e9af-43f0-98d3-8efd7edbe433',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'reserva-biologica-caoba-doublebedroom',
      id: '62337420-e9af-43f0-98d3-8efd7edbe433',
    },
    name: 'Reserva Biologica Caoba - Casa Jardin • Double room',
    price: ' 46€ ',
    location: 'Santa Marta, Colombia',
    projectType: ['animal', 'nature'],
    image: c5image13,
    finalReviews: 4.7,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/ospitalita-rurale-casale-il-sughero-entire-accomodation/63e10db5-5aec-454c-a271-60a52e044141',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ospitalita-rurale-casale-il-sughero-entire-accomodation',
      id: '63e10db5-5aec-454c-a271-60a52e044141',
    },
    name: 'Ospitalità Rurale Casale Il Sughero • Entire accommodation',
    price: '  60€ ',
    location: 'Vibonati, Italy',
    projectType: ['sports', 'nature'],
    image: c5image14,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/simabo-onlus-twobedroom/60744db3-e69d-4ef5-81b4-15047b532759',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'simabo-onlus-twobedroom',
      id: '60744db3-e69d-4ef5-81b4-15047b532759',
    },
    name: 'Simabo ONLUS • Twin room',
    price: ' 35€ ',
    location: 'Mindelo, Cape Verde',
    projectType: ['animal', 'nature'],
    image: c5image1,
    finalReviews: 4.5,
    totalFinalReviews: 0,
  },
  {
    link: '/l/turismo-comunitario-salasaka-twobedroom/6065ebc4-9565-40d7-a9b3-7eab1a5d6169',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'turismo-comunitario-salasaka-twobedroom',
      id: '6065ebc4-9565-40d7-a9b3-7eab1a5d6169',
    },
    name: 'Turismo Comunitario Salasaka • Twin room',
    price: ' 76€ ',
    location: 'Salasaca, Ecuador',
    projectType: ['nature', 'education'],
    image: c5image8,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/mbunga-community-camp-twobedroom/623885ca-157b-4c7e-ab61-e557c37c2000',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mbunga-community-camp-twobedroom',
      id: '623885ca-157b-4c7e-ab61-e557c37c2000',
    },
    name: 'Mbunga Community Camp • Twin room',
    price: ' 47€ ',
    location: 'Kasese, Uganda',
    projectType: [],
    image: c5image5,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/red-rocks-initiatives-for-sustainable-development-twobedroom/607829c7-b018-48f6-90a1-99732cc108f2',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'red-rocks-initiatives-for-sustainable-development-twobedroom',
      id: '607829c7-b018-48f6-90a1-99732cc108f2',
    },
    name: 'Red Rocks Intercultural Center • Twin room',
    price: ' 40€ ',
    location: 'Nyakinama, Rwanda',
    projectType: [],
    image: c5image2,
    finalReviews: 4.4,
    totalFinalReviews: 0,
  },
];

export const category6 = [
  {
    link: '/l/dos-aguas-lodge-doublebedroom/6264284b-17cf-440c-8dc0-31fda6cc844a',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'dos-aguas-lodge-doublebedroom',
      id: '6264284b-17cf-440c-8dc0-31fda6cc844a',
    },
    name: 'Dos Aguas Lodge • Double room',
    price: ' 95€ ',
    location: 'San Onofre, Colombia',
    projectType: ['nature', 'equality'],
    image: c6image11,
    finalReviews: 4.8,
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/tinos-ecolodge-little-stone-stable-entire-accomodation/63205ffd-8860-406f-ba26-b212dff41215',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'tinos-ecolodge-little-stone-stable-entire-accomodation',
      id: '63205ffd-8860-406f-ba26-b212dff41215',
    },
    name: 'Tinos Ecolodge - little stone stable • Entire accommodation',
    price: '  95€ ',
    location: 'Tinos, Greece',
    projectType: ['nature', 'education'],
    image: c6image7,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/ospitalita-rurale-casale-il-sughero-entire-accomodation/63e10db5-5aec-454c-a271-60a52e044141',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ospitalita-rurale-casale-il-sughero-entire-accomodation',
      id: '63e10db5-5aec-454c-a271-60a52e044141',
    },
    name: 'Ospitalità Rurale Casale Il Sughero • Entire accommodation',
    price: '  60€ ',
    location: 'Vibonati, Italy',
    projectType: ['nature', 'sports'],
    image: c6image1,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link:
      '/l/chumbe-island-coral-park-seafront-bungalow-entire-accomodation/63b2da2b-f705-4315-9b93-d82302d6c0e4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'chumbe-island-coral-park-seafront-bungalow-entire-accomodation',
      id: '63b2da2b-f705-4315-9b93-d82302d6c0e4',
    },
    name: 'Chumbe Island Coral Park - Seafront Bungalow • Entire accommodation',
    price: ' 720€ ',
    location: 'Zanzibar, Tanzania',
    projectType: ['nature', 'animal'],
    image: c6image10,
    finalReviews: 4.8,
    totalFinalReviews: 5,
  },
  {
    link: '/l/la-zagara-eco-entire-accomodation/623af249-cc7e-4fe4-8223-b825f4a96d71',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'la-zagara-eco-entire-accomodation',
      id: '623af249-cc7e-4fe4-8223-b825f4a96d71',
    },
    name: 'La Zagara Eco • Entire accommodation',
    price: '  94€ ',
    location: 'Furci Siculo, Italy',
    projectType: ['education', 'health'],
    image: c6image3,
    finalReviews: 4.4,
    totalFinalReviews: 31,
  },
  {
    link: '/l/outdoor-artists-doublebedroom/61027bc1-cffc-4b23-bf8d-c0763b67cd3c',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'outdoor-artists-doublebedroom',
      id: '61027bc1-cffc-4b23-bf8d-c0763b67cd3c',
    },
    name: 'Outdoor Artists • Double room',
    price: '  23€ ',
    location: 'Marsa, Malta',
    projectType: ['education', 'equality'],
    image: c6image4,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link: '/l/evolving-cycles-calme-garden-shared-bedroom/6352c04e-75bf-4910-b024-42e6dacfdcea',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'evolving-cycles-calme-garden-shared-bedroom',
      id: '6352c04e-75bf-4910-b024-42e6dacfdcea',
    },
    name: 'Evolving Cycles (Calme Garden) • Dormitory',
    price: '  39€ ',
    location: 'Lagonisi, Greece',
    projectType: ['nature', 'education'],
    image: c6image6,
    finalReviews: 4.7,
    totalFinalReviews: 5,
  },
  {
    link: '/l/serenity-aguatavar-singlebedroom/63dfaddd-f783-42aa-ab8e-80c851756e39',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'serenity-aguatavar-singlebedroom',
      id: '63dfaddd-f783-42aa-ab8e-80c851756e39',
    },
    name: 'Serenity Aguatavar • Single room',
    price: '  20€ ',
    location: 'Tijarafe, Spain',
    projectType: ['health', 'nature'],
    image: c6image9,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/orto-dell-idro-camping/62a0715b-39ee-4d9e-b6f9-020c014e0de6',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'orto-dell-idro-camping',
      id: '62a0715b-39ee-4d9e-b6f9-020c014e0de6',
    },
    name: "Orto Dell'Idro • Camping",
    price: '  25€ ',
    location: 'Otranto, Italy',
    projectType: [],
    image: c5image8,
    finalReviews: 2,
    totalFinalReviews: 1,
  },
  {
    link: '/l/hermitage-sykaminea-entire-accomodation/63dce373-3fb2-48ed-b9c5-2403e9d93805',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hermitage-sykaminea-entire-accomodation',
      id: '63dce373-3fb2-48ed-b9c5-2403e9d93805',
    },
    name: 'Hermitage Sykaminea • Entire accommodation',
    price: '  240€ ',
    location: 'Skala Sykamineas, Greece',
    projectType: [],
    image: c6image8,
    finalReviews: 4.5,
    totalFinalReviews: 2,
  },
];

export const category7 = [
  {
    link: '/l/dos-aguas-lodge-doublebedroom/6264284b-17cf-440c-8dc0-31fda6cc844a',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'dos-aguas-lodge-doublebedroom',
      id: '6264284b-17cf-440c-8dc0-31fda6cc844a',
    },
    name: 'Dos Aguas Lodge • Double room',
    price: ' 95€ ',
    location: 'San Onofre, Colombia',
    projectType: ['nature', 'equality'],
    image: c7image1,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/sala-bai-hotel-restaurant-school-sala-bai-suite-doublebedroom/618ce29a-8eb6-47ae-ae0b-cf55c37f8280',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'sala-bai-hotel-restaurant-school-sala-bai-suite-doublebedroom',
      id: '618ce29a-8eb6-47ae-ae0b-cf55c37f8280',
    },
    name: 'Sala Baï Hotel & Restaurant School - Sala Baï Suite • Double room',
    price: ' 60€ ',
    location: 'Siem Reap, Cambodia',
    projectType: ['education'],
    image: c7image8,
    finalReviews: 4.8,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/greenscape-resort-bangladesch-mati-bangladesh-singlebedroom/614b586d-5a53-4b3a-acfd-1db5ac700d6d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'greenscape-resort-bangladesch-mati-bangladesh-singlebedroom',
      id: '614b586d-5a53-4b3a-acfd-1db5ac700d6d',
    },
    name: 'Greenscape Resort Bangladesch (MATI Bangladesh) • Single room',
    price: '  25€ ',
    location: 'Mymensingh, Bangladesh',
    projectType: ['nature', 'equality'],
    image: c7image9,
    finalReviews: 4.2,
    totalFinalReviews: 5,
  },
  {
    link: '/l/mana-earthly-paradise-male-dorm-shared-bedroom/606ecffd-5fb6-4a77-a519-2c6fa25a1323',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mana-earthly-paradise-male-dorm-shared-bedroom',
      id: '606ecffd-5fb6-4a77-a519-2c6fa25a1323',
    },
    name: 'Mana Earthly Paradise - male dorm • Dormitory',
    price: ' 23€ ',
    location: 'Ubud, Indonesia',
    projectType: [],
    image: c7image10,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/mekong-rustic-twobedroom/64085a80-4b4e-44d8-af45-e5174faf41b2',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mekong-rustic-twobedroom',
      id: '64085a80-4b4e-44d8-af45-e5174faf41b2',
    },
    name: 'Mekong Rustic • Twin room',
    price: ' 65€ ',
    location: 'Can Tho, Viet Nam',
    projectType: ['nature', 'equality'],
    image: c7image11,
    finalReviews: 4.1,
    totalFinalReviews: 0,
  },
  {
    link: '/l/serenity-eco-guesthouse-and-yoga-doublebedroom/62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'serenity-eco-guesthouse-and-yoga-doublebedroom',
      id: '62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    },
    name: 'Serenity Eco Guesthouse and Yoga • Double room',
    price: ' 36€ ',
    location: 'Denpasar, Indonesia',
    projectType: ['nature', 'health'],
    image: c7image3,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/nui-tuong-village-stay-twobedroom/62dbb77f-1946-4599-a79e-29af90c2fafe',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'nui-tuong-village-stay-twobedroom',
      id: '62dbb77f-1946-4599-a79e-29af90c2fafe',
    },
    name: 'Nui Tuong Village Stay • Twin room',
    price: ' 52€',
    location: 'Dong Nai, Viet Nam',
    projectType: ['nature', 'education'],
    image: c7image4,
    finalReviews: 4.6,
    totalFinalReviews: 5,
  },
  {
    link: '/l/makabata-guesthouse-and-cafe-doublebedroom/63620426-1853-48e2-bbb2-10bc7ade9285',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'makabata-guesthouse-and-cafe-doublebedroom',
      id: '63620426-1853-48e2-bbb2-10bc7ade9285',
    },
    name: 'Makabata Guesthouse and Cafe • Double room',
    price: ' 47€ ',
    location: 'Manila, Philippines',
    projectType: ['education', 'equality'],
    image: c7image5,
    finalReviews: 4,
    totalFinalReviews: 0,
  },
  {
    link: '/l/project-wings-twobedroom/62deafa0-5661-4bb7-a058-ea7c1166cf3b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'project-wings-twobedroom',
      id: '62deafa0-5661-4bb7-a058-ea7c1166cf3b',
    },
    name: 'Project Wings • Twin room',
    price: '  11€ ',
    location: 'Bukit Lawang, Indonesia',
    projectType: ['nature', 'education'],
    image: c7image2,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link: '/l/resilient-womens-organization-doublebedroom/6253f6b2-c652-406d-86b9-8e408f95d00d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'resilient-womens-organization-doublebedroom',
      id: '6253f6b2-c652-406d-86b9-8e408f95d00d',
    },
    name: 'Resilient Women’s Organization • Double room',
    price: ' 36€ ',
    location: 'Entebbe, Uganda',
    projectType: [],
    image: c7image6,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/villa-viva-guesthouse-cape-town-twobedroom/620a85d3-c831-44e4-ad5f-2a4b4f2d9a2f',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'villa-viva-guesthouse-cape-town-twobedroom',
      id: '620a85d3-c831-44e4-ad5f-2a4b4f2d9a2f',
    },
    name: 'Villa Viva Guesthouse Cape Town • Twin room',
    price: '  62€ ',
    location: 'Cape Town, South Africa',
    projectType: [],
    image: c7image7,
    finalReviews: 4.5,
    totalFinalReviews: 5,
  },
];

export const category8 = [
  {
    link:
      '/l/naturschutzstation-ostliche-oberlausitz-doublebedroom/62ba9f2d-f348-4467-9d82-ed33b970644d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'naturschutzstation-ostliche-oberlausitz-doublebedroom',
      id: '62ba9f2d-f348-4467-9d82-ed33b970644d',
    },
    name: 'Naturschutzstation Östliche Oberlausitz • Double room',
    price: '  71€ ',
    location: 'Mücka, Germany',
    projectType: ['nature', 'education'],
    image: c8image1,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/masseria-agriturismo-moschella-stanza-olivo-entire-accomodation/62948640-5641-4269-8189-2f8dee66343d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'masseria-agriturismo-moschella-stanza-olivo-entire-accomodation',
      id: '62948640-5641-4269-8189-2f8dee66343d',
    },
    name: 'Masseria Agriturismo Moschella - Stanza Olivo • Entire accommodation',
    price: '  130€ ',
    location: 'Moschella, Italy',
    projectType: ['nature', 'education'],
    image: c8image2,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link:
      '/l/esperanza-zentrum-fur-tierunterstutzte-padagogik-entire-accomodation/628e8233-d722-4d54-ada5-2a660f8e9515',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'esperanza-zentrum-fur-tierunterstutzte-padagogik-entire-accomodation',
      id: '628e8233-d722-4d54-ada5-2a660f8e9515',
    },
    name: 'Esperanza - Zentrum für tierunterstützte Pädagogik • Entire accommodation',
    price: '  100€ ',
    location: 'Oberndorf an der Melk, Austria',
    projectType: ['animal', 'education'],
    image: c8image3,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/borgo-del-ginepro-entire-accomodation/624b0eb7-42ca-4174-81cb-892f9e72fce5',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'borgo-del-ginepro-entire-accomodation',
      id: '624b0eb7-42ca-4174-81cb-892f9e72fce5',
    },
    name: 'Borgo del Ginepro • Entire accommodation',
    price: '  140€ ',
    location: 'Castiglione Messer Raimondo, Italy',
    projectType: ['nature', 'equality'],
    image: c8image5,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/wampendobl-paradies-entire-accomodation/624a91f2-0c05-4b1f-939a-169d8a210e16',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'wampendobl-paradies-entire-accomodation',
      id: '624a91f2-0c05-4b1f-939a-169d8a210e16',
    },
    name: 'Wampendobl Paradies • Entire accommodation',
    price: '  218€ ',
    location: 'Egglham, Germany',
    projectType: ['animal', 'health'],
    image: c8image6,
    finalReviews: 4.9,
    totalFinalReviews: 5,
  },
  {
    link: '/l/bioagriturismo-podere-montisi-doublebedroom/63eabf21-34e1-4e52-bc90-3e812f2aee00',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'bioagriturismo-podere-montisi-doublebedroom',
      id: '63eabf21-34e1-4e52-bc90-3e812f2aee00',
    },
    name: 'Bioagriturismo Podere Montisi • Double room',
    price: '  140€ ',
    location: 'Calenzano, Italy',
    projectType: ['nature', 'education'],
    image: c8image7,
    finalReviews: 4.9,
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/permaculture-farm-camping-bogata-suma-rich-forest-entire-accomodation/62e38e49-3c21-449d-b910-bf45f3584ef9',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'permaculture-farm-camping-bogata-suma-rich-forest-entire-accomodation',
      id: '62e38e49-3c21-449d-b910-bf45f3584ef9',
    },
    name: 'Permaculture farm & camping Bogata Šuma ("Rich Forest") • Entire accommodation',
    price: '  55€ ',
    location: 'VOJNIĆ, Croatia',
    projectType: ['nature', 'education'],
    image: c8image8,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link:
      '/l/antoniushof-kompetenzzentrum-fur-nachhaltigkeit-und-pravention-entire-accomodation/60be7ff5-e970-4951-a69d-d0b8d2b5d8fb',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'antoniushof-kompetenzzentrum-fur-nachhaltigkeit-und-pravention-entire-accomodation',
      id: '60be7ff5-e970-4951-a69d-d0b8d2b5d8fb',
    },
    name:
      'Antoniushof​,​ Kompetenzzentrum für Nachhaltigkeit und Prävention • Entire accommodation',
    price: '  75€ ',
    projectType: ['education'],
    location: 'Heidelberg, Germany',
    image: c8image9,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
];

export const category9 = [
  {
    link: '/l/project-wings-twobedroom/62deafa0-5661-4bb7-a058-ea7c1166cf3b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'project-wings-twobedroom',
      id: '62deafa0-5661-4bb7-a058-ea7c1166cf3b',
    },
    name: 'Project Wings • Twin room',
    price: '  11€ ',
    location: 'Bukit Lawang, Indonesia',
    projectType: ['nature', 'education'],
    image: c9image1,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link:
      '/l/naucrates-sea-turtle-and-island-conservation-koh-phra-thong-entire-accomodation/62f676a1-3a55-48ff-96a8-bb2e1bbe83f1',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'naucrates-sea-turtle-and-island-conservation-koh-phra-thong-entire-accomodation',
      id: '62f676a1-3a55-48ff-96a8-bb2e1bbe83f1',
    },
    name: 'Naucrates Sea Turtle and Island Conservation Koh Phra Thong • Entire accommodation',
    price: '  37€ ',
    location: 'Phang nga, Thailand',
    projectType: ['nature', 'education'],
    image: c9image2,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/steps-for-children-doublebedroom/6308a9b5-9ab0-4633-a195-3712519922d8',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'steps-for-children-doublebedroom',
      id: '6308a9b5-9ab0-4633-a195-3712519922d8',
    },
    name: 'Steps for children • Double room',
    price: ' 32€ ',
    location: 'Gobabis, Namibia',
    projectType: ['education', 'equality'],
    image: c9image7,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/esperanza-zentrum-fur-tierunterstutzte-padagogik-entire-accomodation/628e8233-d722-4d54-ada5-2a660f8e9515',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'esperanza-zentrum-fur-tierunterstutzte-padagogik-entire-accomodation',
      id: '628e8233-d722-4d54-ada5-2a660f8e9515',
    },
    name: 'Esperanza - Zentrum für tierunterstützte Pädagogik • Entire accommodation',
    price: '  100€ ',
    location: 'Oberndorf an der Melk, Austria',
    projectType: ['animal', 'education'],
    image: c9image3,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/global-tribe-doublebedroom/6405ea74-e4f2-4ca3-9015-4389cf2fb5fa',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'global-tribe-doublebedroom',
      id: '6405ea74-e4f2-4ca3-9015-4389cf2fb5fa',
    },
    name: 'Global Tribe • Double room',
    price: '  59€ ',
    projectType: ['nature'],
    location: 'Almonte, Spain',
    image: c9image4,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link:
      '/l/permaculture-farm-camping-bogata-suma-rich-forest-entire-accomodation/62e38e49-3c21-449d-b910-bf45f3584ef9',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'permaculture-farm-camping-bogata-suma-rich-forest-entire-accomodation',
      id: '62e38e49-3c21-449d-b910-bf45f3584ef9',
    },
    name: 'Permaculture farm & camping Bogata Šuma ("Rich Forest") • Entire accommodation',
    price: '  55€ ',
    location: 'VOJNIĆ, Croatia',
    projectType: ['education', 'nature'],
    image: c9image5,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link:
      '/l/ospitalita-rurale-casale-il-sughero-entire-accomodation/63e10db5-5aec-454c-a271-60a52e044141',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ospitalita-rurale-casale-il-sughero-entire-accomodation',
      id: '63e10db5-5aec-454c-a271-60a52e044141',
    },
    name: 'Ospitalità Rurale Casale Il Sughero • Entire accommodation',
    price: '  60€ ',
    location: 'Vibonati, Italy',
    projectType: ['sports', 'nature'],
    image: c9image6,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/mount-of-oaks-doublebedroom/62a23d42-7275-40ba-a9fc-b272a4bdc0cb',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mount-of-oaks-doublebedroom',
      id: '62a23d42-7275-40ba-a9fc-b272a4bdc0cb',
    },
    name: 'Mount of Oaks • Double room',
    price: '  58€ ',
    location: 'Povoa de Atalaia, Portugal',
    projectType: ['nature', 'education'],
    image: c9image8,
    finalReviews: 4.7,
    totalFinalReviews: 5,
  },
  {
    link: '/l/hobatere-lodge-doublebedroom/62a200a5-fcb9-419c-a655-23eb877807d3',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hobatere-lodge-doublebedroom',
      id: '62a200a5-fcb9-419c-a655-23eb877807d3',
    },
    name: 'Hobatere Lodge • Double room',
    price: ' 499€ ',
    location: 'Kamanjab, Namibia',
    projectType: ['nature', 'animal'],
    image: c9image9,
    finalReviews: 4.4,
    totalFinalReviews: 5,
  },
  {
    link: '/l/mbunga-community-camp-twobedroom/623885ca-157b-4c7e-ab61-e557c37c2000',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mbunga-community-camp-twobedroom',
      id: '623885ca-157b-4c7e-ab61-e557c37c2000',
    },
    name: 'Mbunga Community Camp • Twin room',
    price: ' 47€ ',
    location: 'Kasese, Uganda',
    projectType: [],
    image: c9image10,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
];

export const category10 = [
  {
    link: '/l/the-cove-camping/6263df59-a693-4121-ab9d-32b62449a124',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'the-cove-camping',
      id: '6263df59-a693-4121-ab9d-32b62449a124',
    },
    name: 'The Cove • Camping',
    price: ' 35€ ',
    location: 'Chitende, Malawi',
    projectType: ['nature', 'education'],
    image: c10image1,
    finalReviews: 4.6,
    totalFinalReviews: 5,
  },
  {
    link: '/l/al-numiera-environmental-association-camping/61f95018-53db-4fdb-b7be-7ac78a7a93d4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'al-numiera-environmental-association-camping',
      id: '61f95018-53db-4fdb-b7be-7ac78a7a93d4',
    },
    name: 'Al Numiera Environmental Association • Camping',
    price: '  12€ ',
    location: 'Gawr al-Mazraah, Jordan',
    projectType: ['education', 'nature'],
    image: c10image2,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/hoada-campsite-camping/62a1da2b-1c62-4ed1-8469-09227bb896a1',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hoada-campsite-camping',
      id: '62a1da2b-1c62-4ed1-8469-09227bb896a1',
    },
    name: 'Hoada Campsite • Camping',
    price: ' 60€ ',
    location: 'Kamanjab, Namibia',
    projectType: ['animal', 'nature'],
    image: c10image4,
    finalReviews: 4.7,
    totalFinalReviews: 5,
  },
  {
    link: '/l/vineyard-eladio-black-pearl-shared-bedroom/6390d65a-3f01-4752-b05b-f52df834a112',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'vineyard-eladio-black-pearl-shared-bedroom',
      id: '6390d65a-3f01-4752-b05b-f52df834a112',
    },
    name: 'Vineyard Eladio - Black Pearl • Dormitory',
    price: '  30€ ',
    location: 'Granadilla de Abona, Spain',
    projectType: ['sports', 'nature'],
    image: c10image6,
    finalReviews: 4.9,
    totalFinalReviews: 43,
  },
  {
    link: '/l/lurreko-aromaticas-y-biodiversidad-camping/63092f17-0285-4456-8c0c-40acc84e8efe',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'lurreko-aromaticas-y-biodiversidad-camping',
      id: '63092f17-0285-4456-8c0c-40acc84e8efe',
    },
    name: 'Lurreko Aromáticas y Biodiversidad • Camping',
    price: '  18€ ',
    location: 'Préjano, Spain',
    projectType: ['nature', 'education'],
    image: c10image7,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link: '/l/mount-of-oaks-camping/62a76357-78b8-4fbc-98c2-c4576f102602',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mount-of-oaks-camping',
      id: '62a76357-78b8-4fbc-98c2-c4576f102602',
    },
    name: 'Mount of Oaks • Camping',
    price: '  18€ ',
    location: 'Povoa de Atalaia, Portugal',
    projectType: ['education', 'nature'],
    image: c10image8,
    finalReviews: 4.7,
    totalFinalReviews: 5,
  },
  {
    link: '/l/global-tribe-camping/6375f03d-bd10-46d3-b795-adad06d82e09',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'global-tribe-camping',
      id: '6375f03d-bd10-46d3-b795-adad06d82e09',
    },
    name: 'Global Tribe • Camping',
    price: '  10€ ',
    location: 'Huelva, Spain',
    projectType: ['nature'],
    image: c10image5,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link: '/l/hobatere-lodge-doublebedroom/62a200a5-fcb9-419c-a655-23eb877807d3',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hobatere-lodge-doublebedroom',
      id: '62a200a5-fcb9-419c-a655-23eb877807d3',
    },
    name: 'Hobatere Lodge • Double room',
    price: ' 499€ ',
    location: 'Kamanjab, Namibia',
    projectType: ['animal', 'nature'],
    image: c10image9,
    finalReviews: 4.4,
    totalFinalReviews: 5,
  },
  {
    link: '/l/al-numiera-environmental-association-twobedroom/62bea348-855f-4183-94f9-215e41d6116d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'al-numiera-environmental-association-twobedroom',
      id: '62bea348-855f-4183-94f9-215e41d6116d',
    },
    name: 'Al Numiera Environmental Association • Twin room',
    price: '  31€ ',
    location: 'Gawr al-Mazraah, Jordan',
    projectType: ['education', 'nature'],
    image: c10image10,
    finalReviews: 4.2,
    totalFinalReviews: 0,
  },
  {
    link: '/l/red-rocks-intercultural-center-camping/613874f8-8a3e-4932-a385-0a6961b325fd',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'red-rocks-intercultural-center-camping',
      id: '613874f8-8a3e-4932-a385-0a6961b325fd',
    },
    name: 'Red Rocks Intercultural Center • Camping',
    price: ' 25€ ',
    location: 'Nyakinama, Rwanda',
    projectType: [],
    image: c10image3,
    finalReviews: 4.4,
    totalFinalReviews: 0,
  },
];

export const category11 = [
  {
    link: '/l/dos-aguas-lodge-doublebedroom/6264284b-17cf-440c-8dc0-31fda6cc844a',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'dos-aguas-lodge-doublebedroom',
      id: '6264284b-17cf-440c-8dc0-31fda6cc844a',
    },
    name: 'Dos Aguas Lodge • Double room',
    price: ' 95€ ',
    location: 'San Onofre, Colombia',
    projectType: ['nature', 'equality'],
    image: c11image1,
    finalReviews: 4.8,
    totalFinalReviews: 5,
  },
  {
    link: '/l/go-pang-na-doublebedroom/6070342c-f804-4c95-ab9d-13aace1ebbb2',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'go-pang-na-doublebedroom',
      id: '6070342c-f804-4c95-ab9d-13aace1ebbb2',
    },
    name: 'Go Pang Na • Double room',
    price: ' 24€ ',
    location: 'Pang Na, Cambodia',
    projectType: ['education', 'equality'],
    image: c11image6,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/pepy-empowering-youth-singlebedroom/60f35304-19ba-465f-b45d-7b534f67d9f5',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'pepy-empowering-youth-singlebedroom',
      id: '60f35304-19ba-465f-b45d-7b534f67d9f5',
    },
    name: 'PEPY Empowering Youth • Single room',
    price: ' 13€ ',
    location: 'Siem Reap, Cambodia',
    projectType: ['sports', 'education'],
    image: c11image11,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/vineyard-eladio-black-pearl-shared-bedroom/6390d65a-3f01-4752-b05b-f52df834a112',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'vineyard-eladio-black-pearl-shared-bedroom',
      id: '6390d65a-3f01-4752-b05b-f52df834a112',
    },
    name: 'Vineyard Eladio - Black Pearl • Dormitory',
    price: '  30€ ',
    location: 'Granadilla de Abona, Spain',
    projectType: ['sports', 'nature'],
    image: c11image12,
    finalReviews: 4.9,
    totalFinalReviews: 43,
  },
  {
    link: '/l/penduka-trust-shared-bedroom/60e46898-fe5d-4e36-8785-c758711ccda7',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'penduka-trust-shared-bedroom',
      id: '60e46898-fe5d-4e36-8785-c758711ccda7',
    },
    name: 'Penduka Trust • Dormitory',
    price: ' 13€ ',
    location: 'Windhuk, Namibia',
    projectType: ['education', 'nature'],
    image: c11image7,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/the-life-development-center-shared-bedroom/6071746e-c745-4acb-94c2-e7c70ee9f13d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'the-life-development-center-shared-bedroom',
      id: '6071746e-c745-4acb-94c2-e7c70ee9f13d',
    },
    name: 'The Life Development Center • Dormitory',
    price: ' 10€ ',
    location: 'Chiang Mai, Thailand',
    projectType: ['nature', 'equality'],
    image: c11image9,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/global-tribe-shared-bedroom/62bd838d-c3b1-49a6-9265-7d690e1a225b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'global-tribe-shared-bedroom',
      id: '62bd838d-c3b1-49a6-9265-7d690e1a225b',
    },
    name: 'Global Tribe • Dormitory',
    price: '  12€ ',
    location: 'Almonte, Spain',
    projectType: ['nature'],
    image: c11image10,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link:
      '/l/youth-in-action-against-poverty-and-hiv-aids-yaapha-shared-bedroom/6076c95c-4d1c-42b2-bbcd-383f0220db68',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'youth-in-action-against-poverty-and-hiv-aids-yaapha-shared-bedroom',
      id: '6076c95c-4d1c-42b2-bbcd-383f0220db68',
    },
    name: 'Youth In Action Against Poverty and HIV​/​AIDS (YAAPHA) • Dormitory',
    price: ' 25€ ',
    location: 'Moshi, Tanzania',
    projectType: [],
    image: c11image8,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/makabata-guesthouse-and-cafe-doublebedroom/63620426-1853-48e2-bbb2-10bc7ade9285',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'makabata-guesthouse-and-cafe-doublebedroom',
      id: '63620426-1853-48e2-bbb2-10bc7ade9285',
    },
    name: 'Makabata Guesthouse and Cafe • Double room',
    price: ' 47€ ',
    location: 'Manila, Philippines',
    projectType: ['education', 'equality'],
    image: c11image2,
    finalReviews: 4,
    totalFinalReviews: 0,
  },
  {
    link: '/l/better-women-foundation-shared-bedroom/628a52d3-a7f9-41b3-b6ea-384adcdbb0e7',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'better-women-foundation-shared-bedroom',
      id: '628a52d3-a7f9-41b3-b6ea-384adcdbb0e7',
    },
    name: 'Better Women Foundation • Dormitory',
    price: ' 45€ ',
    location: 'RUBIRIZI, Uganda',
    projectType: ['nature', 'equality'],
    image: c11image3,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/villa-viva-guesthouse-cape-town-twobedroom/620a85d3-c831-44e4-ad5f-2a4b4f2d9a2f',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'villa-viva-guesthouse-cape-town-twobedroom',
      id: '620a85d3-c831-44e4-ad5f-2a4b4f2d9a2f',
    },
    name: 'Villa Viva Guesthouse Cape Town',
    price: '  62€ ',
    location: 'Cape Town, South Africa',
    projectType: ['health', 'education'],
    image: c11image4,
    finalReviews: 4.5,
    totalFinalReviews: 5,
  },
  {
    link: '/l/mana-earthly-paradise-male-dorm-shared-bedroom/606ecffd-5fb6-4a77-a519-2c6fa25a1323',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mana-earthly-paradise-male-dorm-shared-bedroom',
      id: '606ecffd-5fb6-4a77-a519-2c6fa25a1323',
    },
    name: 'Mana Earthly Paradise - male dorm • Dormitory',
    price: ' 23€ ',
    location: 'Ubud, Indonesia',
    projectType: [],
    image: c11image5,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/rio-muchacho-carpintero-shared-bedroom/62827b0e-6c9b-4d56-a355-7b79a06b0d6f',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'rio-muchacho-carpintero-shared-bedroom',
      id: '62827b0e-6c9b-4d56-a355-7b79a06b0d6f',
    },
    name: 'Río Muchacho - Carpintero • Dormitory',
    price: ' 12€ ',
    projectType: [],
    location: 'Canoa, Ecuador',
    image: c11image14,
    finalReviews: 4.5,
    totalFinalReviews: 0,
  },
  {
    link: '/l/al-numiera-environmental-association-twobedroom/62bea348-855f-4183-94f9-215e41d6116d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'al-numiera-environmental-association-twobedroom',
      id: '62bea348-855f-4183-94f9-215e41d6116d',
    },
    name: 'Al Numiera Environmental Association • Twin room',
    price: '  31€ ',
    projectType: [],
    location: 'Gawr al-Mazraah, Jordan',
    image: c11image13,
    finalReviews: 4.2,
    totalFinalReviews: 0,
  },
];

export const category12 = [
  {
    link: '/l/reserva-natural-xieti-entire-accomodation/60fe3a9d-e47f-44e9-aeb4-90981cef49cb',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'reserva-natural-xieti-entire-accomodation',
      id: '60fe3a9d-e47f-44e9-aeb4-90981cef49cb',
    },
    name: 'Reserva Natural Xieti • Entire accommodation',
    price: ' 180€ ',
    location: 'Tota, Colombia',
    projectType: ['nature', 'education'],
    image: c12image1,
    finalReviews: 4.9,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/responsible-travel-nuratau-community-tourism-shared-bedroom/6303b10c-9860-4a21-884b-de9e0ebcf8c6',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'responsible-travel-nuratau-community-tourism-shared-bedroom',
      id: '6303b10c-9860-4a21-884b-de9e0ebcf8c6',
    },
    name: 'Responsible Travel - Nuratau Community Tourism • Dormitory',
    price: ' 35€ ',
    location: 'Sentob village, Uzbekistan',
    projectType: ['nature', 'equality'],
    image: c12image2,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link: '/l/ceylon-food-trails-doublebedroom/61e905e2-ae1b-4c56-8997-7809578090cd',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ceylon-food-trails-doublebedroom',
      id: '61e905e2-ae1b-4c56-8997-7809578090cd',
    },
    name: 'Ceylon Food Trails • Double room',
    price: ' 45€ ',
    location: 'Dambulla, Sri Lanka',
    projectType: ['health', 'equality'],
    image: c12image3,
    finalReviews: 4.4,
    totalFinalReviews: 1053,
  },
  {
    link:
      '/l/sala-bai-hotel-restaurant-school-sala-bai-suite-doublebedroom/618ce29a-8eb6-47ae-ae0b-cf55c37f8280',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'sala-bai-hotel-restaurant-school-sala-bai-suite-doublebedroom',
      id: '618ce29a-8eb6-47ae-ae0b-cf55c37f8280',
    },
    name: 'Sala Baï Hotel & Restaurant School - Sala Baï Suite • Double room',
    price: ' 60€',
    location: 'Siem Reap, Cambodia',
    projectType: ['education'],
    image: c12image4,
    finalReviews: 4.8,
    totalFinalReviews: 0,
  },
  {
    link: '/l/better-women-foundation-shared-bedroom/628a52d3-a7f9-41b3-b6ea-384adcdbb0e7',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'better-women-foundation-shared-bedroom',
      id: '628a52d3-a7f9-41b3-b6ea-384adcdbb0e7',
    },
    name: 'Better Women Foundation • Dormitory',
    price: ' 45€',
    location: 'RUBIRIZI, Uganda',
    projectType: ['nature', 'equality'],
    image: c12image5,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/better-women-foundation-shared-bedroom/628a52d3-a7f9-41b3-b6ea-384adcdbb0e7',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'better-women-foundation-shared-bedroom',
      id: '628a52d3-a7f9-41b3-b6ea-384adcdbb0e7',
    },
    name: 'Better Women Foundation • Dormitory',
    price: ' 45€',
    location: 'RUBIRIZI, Uganda',
    projectType: ['nature', 'equality'],
    image: c12image6,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/the-cove-tent/6263df59-a693-4121-ab9d-32b62449a124',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'the-cove-tent',
      id: '6263df59-a693-4121-ab9d-32b62449a124',
    },
    name: 'The Cove • Tent',
    price: ' 35€',
    location: 'Chitende, Malawi',
    projectType: ['nature', 'education'],
    image: c12image7,
    finalReviews: 4.6,
    totalFinalReviews: 5,
  },
  {
    link: '/l/resilient-womens-organization-doublebedroom/6253f6b2-c652-406d-86b9-8e408f95d00d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'resilient-womens-organization-doublebedroom',
      id: '6253f6b2-c652-406d-86b9-8e408f95d00d',
    },
    name: 'Resilient Women’s Organization • Double room',
    price: ' 36€',
    location: 'Entebbe, Uganda',
    projectType: ['education', 'equality'],
    image: c12image8,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/masseria-agriturismo-moschella-stanza-olivo-entire-accomodation/62948640-5641-4269-8189-2f8dee66343d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'masseria-agriturismo-moschella-stanza-olivo-entire-accomodation',
      id: '62948640-5641-4269-8189-2f8dee66343d',
    },
    name: 'Masseria Agriturismo Moschella - Stanza Olivo • Entire accommodation',
    price: '  130€ ',
    location: 'Moschella, Italy',
    projectType: ['nature', 'education'],
    image: c12image9,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
];

export const category13 = [
  {
    link: '/l/serenity-eco-guesthouse-and-yoga-doublebedroom/62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'serenity-eco-guesthouse-and-yoga-doublebedroom',
      id: '62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    },
    name: 'Serenity Eco Guesthouse and Yoga • Double room',
    price: ' 36€',
    location: 'Denpasar, Indonesia',
    projectType: ['nature', 'health'],
    image: c13image1,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/bioagriturismo-podere-montisi-doublebedroom/63eabf21-34e1-4e52-bc90-3e812f2aee00',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'bioagriturismo-podere-montisi-doublebedroom',
      id: '63eabf21-34e1-4e52-bc90-3e812f2aee00',
    },
    name: 'Bioagriturismo Podere Montisi • Double room',
    price: '  140€ ',
    location: 'Calenzano, Italy',
    projectType: ['nature', 'education'],
    image: c8image7,
    finalReviews: 4.9,
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/himalayan-citrus-garden-riverside-boutique-permaculture-farm-stay-twobedroom/62b04aab-e9e7-4f7e-8901-40be6816d593',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'himalayan-citrus-garden-riverside-boutique-permaculture-farm-stay-twobedroom',
      id: '62b04aab-e9e7-4f7e-8901-40be6816d593',
    },
    name: 'Himalayan Citrus Garden Riverside Boutique Permaculture Farm-stay • Twin room',
    price: ' 84€',
    location: 'Mandal, Chamoli - Uttrakhand, India',
    projectType: ['nature', 'equality'],
    image: c13image12,
    finalReviews: 4.6,
    totalFinalReviews: 5,
  },
  {
    link: '/l/biloba-organic-twobedroom/6322e038-87fc-4487-97de-3ee260d9ab30',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'biloba-organic-twobedroom',
      id: '6322e038-87fc-4487-97de-3ee260d9ab30',
    },
    name: 'Biloba Organic • Twin room',
    price: '  45€ ',
    location: 'Nawalpur, Nepal',
    projectType: ['nature', 'education'],
    image: c13image3,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link: '/l/reserva-jaguarapira-entire-accomodation/627e7b24-2460-4add-b6a8-d5a386be1f9b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'reserva-jaguarapira-entire-accomodation',
      id: '627e7b24-2460-4add-b6a8-d5a386be1f9b',
    },
    name: 'Reserva Jaguarapira • Entire accommodation',
    price: ' 120€',
    location: 'Quatro Barras, Brazil',
    projectType: ['nature', 'education'],
    image: c13image4,
    finalReviews: 4.8,
    totalFinalReviews: 0,
  },
  {
    link: '/l/the-cove-camping/6263df59-a693-4121-ab9d-32b62449a124',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'the-cove-camping',
      id: '6263df59-a693-4121-ab9d-32b62449a124',
    },
    name: 'The Cove • Tent',
    price: ' 35€',
    location: 'Chitende, Malawi',
    projectType: ['nature', 'education'],
    image: c13image5,
    finalReviews: 4.6,
    totalFinalReviews: 5,
  },
  {
    link: '/l/al-numiera-environmental-association-camping/61f95018-53db-4fdb-b7be-7ac78a7a93d4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'al-numiera-environmental-association-camping',
      id: '61f95018-53db-4fdb-b7be-7ac78a7a93d4',
    },
    name: 'Al Numiera Environmental Association • Tent',
    price: '  12€ ',
    location: 'Gawr al-Mazraah, Jordan',
    projectType: ['nature', 'education'],
    image: c13image6,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link:
      '/l/sustainable-amazon-finca-las-piedras-entire-accomodation/620d6054-f3dc-40d7-8913-014a0dd5b239',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'sustainable-amazon-finca-las-piedras-entire-accomodation',
      id: '620d6054-f3dc-40d7-8913-014a0dd5b239',
    },
    name: 'Sustainable Amazon - Finca Las Piedras • Entire accommodation',
    price: ' 150€',
    location: 'Las Piedras, Peru',
    projectType: ['nature', 'education'],
    image: c13image7,
    finalReviews: '5.0',
    totalFinalReviews: 3,
  },
  {
    link: '/l/la-zagara-eco-entire-accomodation/623af249-cc7e-4fe4-8223-b825f4a96d71',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'la-zagara-eco-entire-accomodation',
      id: '623af249-cc7e-4fe4-8223-b825f4a96d71',
    },
    name: 'La Zagara Eco • Entire accommodation',
    price: '  94€',
    location: 'Furci Siculo, Italy',
    projectType: ['health', 'education'],
    image: c13image8,
    finalReviews: 4.4,
    totalFinalReviews: 31,
  },
  {
    link: '/l/kawsay-biological-station-shared-bedroom/60d8c7ca-2ab1-414c-9cca-1702bb52adfb',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'kawsay-biological-station-shared-bedroom',
      id: '60d8c7ca-2ab1-414c-9cca-1702bb52adfb',
    },
    name: 'Kawsay Biological Station • Dormitory',
    price: ' 60€',
    location: 'Puerto Maldonado, Peru',
    projectType: ['nature', 'education'],
    image: c13image9,
    finalReviews: 4.8,
    totalFinalReviews: 0,
  },
  {
    link:
      '/l/responsible-travel-nuratau-community-tourism-shared-bedroom/6303a3be-6333-43e7-962d-86b2dc06cb4e',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'responsible-travel-nuratau-community-tourism-shared-bedroom',
      id: '6303a3be-6333-43e7-962d-86b2dc06cb4e',
    },
    name: 'Responsible Travel - Nuratau Community Tourism • Dormitory',
    price: ' 35€',
    location: 'Hayat, Uzbekistan',
    projectType: ['nature'],
    image: c13image10,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link: '/l/reserva-jaguarapira-entire-accomodation/627e7b24-2460-4add-b6a8-d5a386be1f9b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'reserva-jaguarapira-entire-accomodation',
      id: '627e7b24-2460-4add-b6a8-d5a386be1f9b',
    },
    name: 'Reserva Jaguarapira • Entire accommodation',
    price: ' 120€',
    location: 'Quatro Barras, Brazil',
    projectType: ['nature', 'education'],
    image: c13image11,
    finalReviews: 4.8,
    totalFinalReviews: 0,
  },
  {
    link: '/l/grootberg-lodge-entire-accomodation/62a0d453-481e-4333-8a33-777b9bf87a6b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'grootberg-lodge-entire-accomodation',
      id: '62a0d453-481e-4333-8a33-777b9bf87a6b',
    },
    name: 'Grootberg Lodge • Entire accommodation',
    price: ' 561€',
    location: 'Palmwag, Namibia',
    projectType: [],
    image: c13image13,
    finalReviews: 4.6,
    totalFinalReviews: 0,
  },
  {
    link: '/l/eco-caminhos-singlebedroom/6065a516-9ce9-4739-9395-758523cee0d2',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'eco-caminhos-singlebedroom',
      id: '6065a516-9ce9-4739-9395-758523cee0d2',
    },
    name: 'Eco Caminhos • Single room',
    price: ' 53€',
    location: 'Nova Friburgo, Brazi',
    projectType: [],
    image: c13image14,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
];

export const category14 = [
  {
    link: '/l/lurreko-aromaticas-y-biodiversidad-camping/63092f17-0285-4456-8c0c-40acc84e8efe',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'lurreko-aromaticas-y-biodiversidad-camping',
      id: '63092f17-0285-4456-8c0c-40acc84e8efe',
    },
    name: 'Lurreko Aromáticas y Biodiversidad • Tent',
    price: '  18€ ',
    location: 'Préjano, Spain',
    projectType: ['education', 'nature'],
    image: c14image1,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link: '/l/astungkara-way-doublebedroom/6139ba6a-b1f3-4eb0-9bca-a7016bc8c11f',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'astungkara-way-doublebedroom',
      id: '6139ba6a-b1f3-4eb0-9bca-a7016bc8c11f',
    },
    name: 'Astungkara Way • Double room',
    price: ' 40€',
    location: 'Denpasar, Indonesia',
    projectType: ['education', 'nature'],
    image: c14image2,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/la-hesperia-cloud-forest-reserve-shared-bedroom/615238ce-1aa1-49ae-a497-dc071500ede6',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'la-hesperia-cloud-forest-reserve-shared-bedroom',
      id: '615238ce-1aa1-49ae-a497-dc071500ede6',
    },
    name: 'La Hesperia Cloud Forest Reserve • Dormitory',
    price: ' 23€',
    location: 'Manuel Cornejo Astorga, Ecuador',
    projectType: ['education', 'nature'],
    image: c14image3,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/cochas-eco-lodge-camp-entire-accomodation/60f85552-461c-428c-ae7e-408c5922c1a4',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'cochas-eco-lodge-camp-entire-accomodation',
      id: '60f85552-461c-428c-ae7e-408c5922c1a4',
    },
    name: 'Cochas Eco Lodge & Camp • Entire accommodation',
    price: ' 253€',
    location: 'Zuleta, Ecuador',
    projectType: ['education', 'nature'],
    image: c14image4,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/bioagriturismo-podere-montisi-doublebedroom/63eabf21-34e1-4e52-bc90-3e812f2aee00',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'bioagriturismo-podere-montisi-doublebedroom',
      id: '63eabf21-34e1-4e52-bc90-3e812f2aee00',
    },
    name: 'Bioagriturismo Podere Montisi • Double room',
    price: '  140€ ',
    location: 'Calenzano, Italy',
    projectType: ['nature', 'education'],
    image: c8image7,
    finalReviews: 4.9,
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/naturschutzstation-ostliche-oberlausitz-doublebedroom/62ba9f2d-f348-4467-9d82-ed33b970644d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'naturschutzstation-ostliche-oberlausitz-doublebedroom',
      id: '62ba9f2d-f348-4467-9d82-ed33b970644d',
    },
    name: 'Naturschutzstation Östliche Oberlausitz • Double room',
    price: '  71€ ',
    location: 'Mücka, Germany',
    projectType: ['education', 'nature'],
    image: c8image1,
    finalReviews: '5.0',
    totalFinalReviews: 5,
  },
  {
    link: '/l/wampendobl-paradies-entire-accomodation/624a91f2-0c05-4b1f-939a-169d8a210e16',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'wampendobl-paradies-entire-accomodation',
      id: '624a91f2-0c05-4b1f-939a-169d8a210e16',
    },
    name: 'Wampendobl Paradies • Entire accommodation',
    price: '  218€ ',
    location: 'Egglham, Germany',
    projectType: ['animal', 'health'],
    image: c8image6,
    finalReviews: 4.9,
    totalFinalReviews: 5,
  },
  {
    link: '/l/casa-pousadoira-twobedroom/625fbccc-5367-437f-9c29-35071ab25b80',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'casa-pousadoira-twobedroom',
      id: '625fbccc-5367-437f-9c29-35071ab25b80',
    },
    name: 'Casa Pousadoira • Twin room',
    price: '  71€ ',
    location: 'Miño, Spain',
    projectType: [],
    image: image5,
    finalReviews: 4.6,
    totalFinalReviews: 0,
  },
  {
    link: '/l/serenity-aguatavar-singlebedroom/63dfaddd-f783-42aa-ab8e-80c851756e39',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'serenity-aguatavar-singlebedroom',
      id: '63dfaddd-f783-42aa-ab8e-80c851756e39',
    },
    name: 'Serenity Aguatavar • Single room',
    price: '  20€  ',
    location: 'Tijarafe, Spain',
    projectType: [],
    image: c6image9,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
];

export const category15 = [
  {
    link: '/l/chumbe-island-coral-park-doublebedroom/6076f135-d5ac-46a0-9694-45f04752505e',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'chumbe-island-coral-park-doublebedroom',
      id: '6076f135-d5ac-46a0-9694-45f04752505e',
    },
    name: 'Chumbe Island Coral Park - Garden Bungalow • Entire accommodation',
    price: ' 560€',
    location: 'Zanzibar, Tanzania',
    projectType: ['education', 'nature'],
    image: c15image1,
    finalReviews: 4.8,
    totalFinalReviews: 0,
  },
  {
    link: '/l/bloo-lagoon-eco-village-entire-accomodation/6319a89b-d28b-4e3e-9c8e-3a3cf34554dd',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'bloo-lagoon-eco-village-entire-accomodation',
      id: '6319a89b-d28b-4e3e-9c8e-3a3cf34554dd',
    },
    name: 'Bloo Lagoon Eco Village • Entire accommodation',
    price: ' 145€',
    location: 'Karangasem, Indonesia',
    projectType: ['animal', 'nature'],
    image: c15image2,
    finalReviews: 4.6,
    totalFinalReviews: 0,
  },
  {
    link: '/l/ubuntu-bali-doublebedroom/633f1dc7-70ce-4a5e-863f-d5e49cbf9e15',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ubuntu-bali-doublebedroom',
      id: '633f1dc7-70ce-4a5e-863f-d5e49cbf9e15',
    },
    name: 'Ubuntu Bali • Double room',
    price: '  63€ ',
    location: 'Canggu, Indonesia',
    projectType: ['education', 'nature'],
    image: c15image3,
    finalReviews: 4.8,
    totalFinalReviews: 5,
  },
  {
    link: '/l/la-zagara-eco-entire-accomodation/623af249-cc7e-4fe4-8223-b825f4a96d71',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'la-zagara-eco-entire-accomodation',
      id: '623af249-cc7e-4fe4-8223-b825f4a96d71',
    },
    name: 'La Zagara Eco • Entire accommodation',
    price: '  94€  ',
    location: 'Furci Siculo, Italy',
    projectType: ['education', 'health'],
    image: c6image3,
    finalReviews: 4.4,
    totalFinalReviews: 31,
  },
  {
    link:
      '/l/tinos-ecolodge-little-stone-stable-entire-accomodation/63205ffd-8860-406f-ba26-b212dff41215',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'tinos-ecolodge-little-stone-stable-entire-accomodation',
      id: '63205ffd-8860-406f-ba26-b212dff41215',
    },
    name: 'Tinos Ecolodge - little stone stable • Entire accommodation',
    price: '  95€ ',
    projectType: ['education', 'nature'],
    location: 'Tinos, Greece',
    image: c6image7,
    finalReviews: '5.0',
    totalFinalReviews: 0,
  },
  {
    link: '/l/outdoor-artists-doublebedroom/61027bc1-cffc-4b23-bf8d-c0763b67cd3c',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'outdoor-artists-doublebedroom',
      id: '61027bc1-cffc-4b23-bf8d-c0763b67cd3c',
    },
    name: 'Outdoor Artists • Double room',
    price: '  23€ ',
    location: 'Marsa, Malta',
    projectType: ['education', 'equality'],
    image: c6image4,
    finalReviews: '5.0',
    totalFinalReviews: 0,
  },
  {
    link: '/l/serenity-eco-guesthouse-and-yoga-doublebedroom/62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'serenity-eco-guesthouse-and-yoga-doublebedroom',
      id: '62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    },
    name: 'Serenity Eco Guesthouse and Yoga • Double room',
    price: ' 36€ ',
    location: 'Denpasar, Indonesia',
    projectType: ['nature', 'health'],
    image: c7image3,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link:
      '/l/naucrates-sea-turtle-and-island-conservation-koh-phra-thong-entire-accomodation/62f676a1-3a55-48ff-96a8-bb2e1bbe83f1',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'naucrates-sea-turtle-and-island-conservation-koh-phra-thong-entire-accomodation',
      id: '62f676a1-3a55-48ff-96a8-bb2e1bbe83f1',
    },
    name: 'Naucrates Sea Turtle and Island Conservation Koh Phra Thong • Entire accommodation',
    price: '  37€ ',
    location: 'Phang nga, Thailand',
    projectType: ['education', 'nature'],
    image: c9image2,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/serenity-aguatavar-singlebedroom/63dfaddd-f783-42aa-ab8e-80c851756e39',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'serenity-aguatavar-singlebedroom',
      id: '63dfaddd-f783-42aa-ab8e-80c851756e39',
    },
    name: 'Serenity Aguatavar • Single room',
    price: '  20€ ',
    location: 'Tijarafe, Spain',
    projectType: ['nature', 'health'],
    image: c6image9,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/ecotopia-park-entire-accomodation/63c838bd-6326-4c19-ab2f-d65ab8e46340',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ecotopia-park-entire-accomodation',
      id: '63c838bd-6326-4c19-ab2f-d65ab8e46340',
    },
    name: 'Ecotopía Park • Entire accommodation',
    price: ' 23€',
    location: 'Las Terrenas, Dominican Republic',
    projectType: [],
    image: c15image4,
    finalReviews: 4.1,
    totalFinalReviews: 5,
  },
];

export const category16 = [
  {
    link: '/l/global-tribe-twobedroom/6405e21f-39e0-4971-9acb-9797d9057c78',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'global-tribe-twobedroom',
      id: '6405e21f-39e0-4971-9acb-9797d9057c78',
    },
    name: 'Global Tribe • Twin room',
    price: '  79€ ',
    location: 'Almonte, Spain',
    projectType: ['nature'],
    image: c16image1,
    finalReviews: '5.0',
    totalFinalReviews: 2,
  },
  {
    link: '/l/hermitage-sykaminea-entire-accomodation/63dce373-3fb2-48ed-b9c5-2403e9d93805',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hermitage-sykaminea-entire-accomodation',
      id: '63dce373-3fb2-48ed-b9c5-2403e9d93805',
    },
    name: 'Hermitage Sykaminea • Entire accommodation',
    price: '  240€ ',
    location: 'Skala Sykamineas, Greece',
    projectType: ['nature'],
    image: c6image8,
    finalReviews: 4.5,
    totalFinalReviews: 2,
  },
  {
    link: '/l/ubuntu-bali-doublebedroom/633f1dc7-70ce-4a5e-863f-d5e49cbf9e15',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ubuntu-bali-doublebedroom',
      id: '633f1dc7-70ce-4a5e-863f-d5e49cbf9e15',
    },
    name: 'Ubuntu Bali • Double room',
    price: '  63€ ',
    location: 'Canggu, Indonesia',
    projectType: ['education', 'nature'],
    image: c15image3,
    finalReviews: 4.8,
    totalFinalReviews: 5,
  },
  {
    link: '/l/villa-viva-guesthouse-cape-town-twobedroom/620a85d3-c831-44e4-ad5f-2a4b4f2d9a2f',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'villa-viva-guesthouse-cape-town-twobedroom',
      id: '620a85d3-c831-44e4-ad5f-2a4b4f2d9a2f',
    },
    name: 'Villa Viva Guesthouse Cape Town',
    price: '  62€ ',
    location: 'Cape Town, South Africa',
    projectType: ['education', 'health'],
    image: c11image4,
    finalReviews: 4.5,
    totalFinalReviews: 5,
  },
  {
    link: '/l/vineyard-eladio-black-pearl-shared-bedroom/6390d65a-3f01-4752-b05b-f52df834a112',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'vineyard-eladio-black-pearl-shared-bedroom',
      id: '6390d65a-3f01-4752-b05b-f52df834a112',
    },
    name: 'Vineyard Eladio - Black Pearl • Dormitory',
    price: '  30€ ',
    location: 'Granadilla de Abona, Spain',
    projectType: ['nature', 'sports'],
    image: c11image12,
    finalReviews: 4.9,
    totalFinalReviews: 43,
  },
  {
    link: '/l/steps-for-children-twobedroom/62c8458d-ccf9-4a7a-bea6-fcc4bc6a635a',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'steps-for-children-twobedroom',
      id: '62c8458d-ccf9-4a7a-bea6-fcc4bc6a635a',
    },
    name: 'steps for children • Twin room',
    price: '  40€ ',
    location: 'Gobabis, Namibia',
    projectType: ['education', 'equality'],
    image: c9image7,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/serenity-eco-guesthouse-and-yoga-doublebedroom/62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'serenity-eco-guesthouse-and-yoga-doublebedroom',
      id: '62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    },
    name: 'Serenity Eco Guesthouse and Yoga • Double room',
    price: '  36€ ',
    location: 'Denpasar, Indonesia',
    projectType: ['nature', 'health'],
    image: c7image3,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/dos-aguas-lodge-doublebedroom/6264284b-17cf-440c-8dc0-31fda6cc844a',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'dos-aguas-lodge-doublebedroom',
      id: '6264284b-17cf-440c-8dc0-31fda6cc844a',
    },
    name: 'Dos Aguas Lodge • Double room',
    price: '  95€ ',
    location: 'San Onofre, Colombia',
    projectType: ['nature', 'equality'],
    image: c11image1,
    finalReviews: 4.8,
    totalFinalReviews: 5,
  },
  {
    link: '/l/niyana-doublebedroom/6478dd66-a858-4f89-8e5b-238bd6169f2e',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'niyana-doublebedroom',
      id: '6478dd66-a858-4f89-8e5b-238bd6169f2e',
    },
    name: 'Niyana • Double room',
    price: '  86€ ',
    location: 'Oaxaca, Mexico',
    projectType: ['education', 'nature'],
    image: c16image2,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/earth-company-shared-bedroom/606ecffd-5fb6-4a77-a519-2c6fa25a1323',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'earth-company-shared-bedroom',
      id: '606ecffd-5fb6-4a77-a519-2c6fa25a1323',
    },
    name: 'Earth Company • Dormitory',
    price: '  23€ ',
    location: 'Ubud, Indonesia',
    projectType: [],
    image: c16image3,
    finalReviews: 0,
    totalFinalReviews: 0,
  },
  {
    link: '/l/enriquezarte-entire-accomodation/606c6f7f-54c3-461e-a711-0ffcd2e19bc7',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'enriquezarte-entire-accomodation',
      id: '606c6f7f-54c3-461e-a711-0ffcd2e19bc7',
    },
    name: 'EnriquezArte • Entire accommodation',
    price: '  60€ ',
    location: 'Barcelona, Spain',
    projectType: ['equality', 'education'],
    image: c16image4,
    finalReviews: '5.0',
    totalFinalReviews: 1,
  },
  {
    link: '/l/oye-lena-doublebedroom/606d654c-a79b-42bf-bb74-95a96ac69712',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'oye-lena-doublebedroom',
      id: '606d654c-a79b-42bf-bb74-95a96ac69712',
    },
    name: 'Oye LENA • Double room',
    price: '  48€ ',
    location: 'Curahuasi, Peru',
    projectType: [],
    image: c5image11,
    finalReviews: '5.0',
    totalFinalReviews: 3,
  },
];
