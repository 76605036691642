import React from 'react';
import { bool, object } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, formatPriceFromUnitPurchase } from '../../util/currency';
import { propTypes } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

import css from './OrderBreakdown.module.css';

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);


  const isExperienceType =
    props.currentListing?.attributes?.publicData?.listingType == 'impact-experience';

  let providerTotalMessageId = isExperienceType ? "transactionPage.experienceTotalPrice" : 'BookingBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const formattedTotalPrice = formatPriceFromUnitPurchase(intl, totalPrice.amount);
  const strikeThroughTotal = props?.strikeThroughTotal ? props?.strikeThroughTotal : false;
  const discountExist =
    transaction?.attributes?.protectedData?.discountData?.discountData != undefined &&
    !location.pathname.endsWith('/checkout');
  const seatsMaybe = transaction?.attributes?.lineItems ? transaction?.attributes?.lineItems[0].seats : null;


  const createPeoplePriceArray = data => {
    const peoplePrice = [];

    if (data.price) {
      peoplePrice.push({ key: 'price', value: data.price.amount, guests: 1 });
    }

    const prices = ['price2P', 'price3P', 'price4P'];
    const guestKeys = [2, 3, 4];
    prices.forEach((priceKey, index) => {
      if (data.publicData[priceKey]) {
        peoplePrice.push({
          key: priceKey,
          value: data.publicData[priceKey].amount,
          guests: guestKeys[index],
        });
      }
    });

    return peoplePrice;
  };
  const availablePeopleMDE = props.currentListing?.attributes
    ? createPeoplePriceArray(props.currentListing?.attributes)
    : [];

  const getPriceForSeats = seats => {
    return availablePeopleMDE.find(MDE => MDE.guests == seats);
  };
  const experiencePrice = seatsMaybe !== null ? getPriceForSeats(seatsMaybe) : {};
  const totalExperiencePrice = '€' + Number(experiencePrice?.value) / 100;

  return (
    <>
      <div className={css.lineItemTotal}>
        <div
          className={css.totalLabel}
          style={
            strikeThroughTotal || discountExist
              ? { textDecoration: 'line-through', color: 'gray' }
              : null
          }
        >
          {totalLabel}
        </div>
        <div
          className={css.totalPrice}
          style={
            strikeThroughTotal || discountExist
              ? { textDecoration: 'line-through', color: 'gray' }
              : null
          }
        >
            {isExperienceType ? totalExperiencePrice :formattedTotalPrice}
        </div>
      </div>
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
  currentListing: object,
};

export default LineItemTotalPrice;
