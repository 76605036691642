export const extractDaysAndNights = input => {
  const regexDaysNights = /(\d+)\s*Days?\s*(\d+)\s*Nights?/i;
  const regexWeeks = /(\d+)\s*Weeks?/i;

  const matchDaysNights = input.match(regexDaysNights);
  const matchWeeks = input.match(regexWeeks);

  if (matchDaysNights) {
    return {
      days: parseInt(matchDaysNights[1], 10),
      nights: parseInt(matchDaysNights[2], 10),
    };
  } else if (matchWeeks) {
    const weeks = parseInt(matchWeeks[1], 10);
    return {
      days: weeks * 7,
      nights: weeks * 7 - 1,
    };
  } else {
    return null;
  }
};


export const getPriceBasedOnSeats = (data, seats) => {
  if (typeof seats !== 'number' || seats < 1 || seats > 12) {
      throw new Error('Invalid seats value. Seats must be a number between 1 and 12.');
  }

  if (seats === 1) {
      return data.price.amount;
  }

  const priceKey = `price${seats}P`;
  if (data.publicData[priceKey] && data.publicData[priceKey].amount) {
      return data.publicData[priceKey].amount;
  } else {
      throw new Error(`Price for ${seats} seats not available.`);
  }
}
