import React from 'react';
import { FormattedMessage } from 'react-intl';
import NamedLinkNewTab from '../../../../components/NamedLink/NamedLinkNewTab';
import ExternalLink from '../../../ExternalLink/ExternalLink';
import insta from '../../../../assets/icons/socialmedia/instagram-iconTenerif.png';
import fb from '../../../../assets/icons/socialmedia/facebook-iconTenerif.png';
import linkedIn from '../../../../assets/icons/socialmedia/linkiedin-iconTenerif.png';
import titktok from '../../../../assets/icons/socialmedia/tiktok-icon.png';

export const FirstColumn = ({ scrollToTop, css }) => {
  return (
    <div
      className={css.list1}
      data-elb="section"
      data-elb-section="name:footerFirstColumn;position:1"
      data-elbaction="visible:footer first column visible"
    >
      <ul className={css.leftList}>
        <li className={css.listItemHeader}>
          <h3 className={css.heading}>Socialbnb</h3>
        </li>
        <div className={css.footerTopText}>
          <div className={css.socialMediaBtns}>
            <ExternalLink
              href="https://www.instagram.com/socialbnb/"
              className={css.link}
              data-elbaction="click:click footer-social-instagram"
            >
              <img src={insta} className={css.socialMediaIcon} alt="socialbnb instagram" />
            </ExternalLink>
            <ExternalLink
              href="https://www.facebook.com/Socialbnb"
              className={css.link}
              data-elbaction="click:click footer-social-facebook"
            >
              <img src={fb} className={css.socialMediaIcon} alt="social bnb facebook" />
            </ExternalLink>
            <ExternalLink
              href="https://www.linkedin.com/company/socialbnb/"
              className={css.link}
              data-elbaction="click:click footer-social-linkedin"
            >
              <img src={linkedIn} className={css.socialMediaIcon} alt="social travel linkedin" />
            </ExternalLink>
            <ExternalLink
              href="https://www.tiktok.com/@socialbnb"
              className={css.link}
              data-elbaction="click:click footer-social-tiktok"
            >
              <img src={titktok} className={css.socialMediaIcon} alt="social experience tiktok" />
            </ExternalLink>
          </div>
        </div>
        <li className={css.listItem} data-elbaction="click:click footer-aboutpage">
          <NamedLinkNewTab name="AboutPage" className={css.link} onClick={scrollToTop}>
            <FormattedMessage id="TopbarDesktop.about" />
          </NamedLinkNewTab>
        </li>
        <li className={css.listItem} data-elbaction="click:click footer-voucher">
          <NamedLinkNewTab name="CMSPage" params={{ pageId: 'gutschein' }} className={css.link}>
            <FormattedMessage id="newsletter.voucher" />
          </NamedLinkNewTab>
        </li>
        <li className={css.listItem} data-elbaction="click:click footer-ambassador">
          <NamedLinkNewTab name="ambassadorPage" className={css.link}>
            <FormattedMessage id="ImpactAmb.link" />
          </NamedLinkNewTab>
        </li>
        <li className={css.listItem} data-elbaction="click:click footer-jobs">
          <ExternalLink
            href="https://jobboard.catch-talents.de/companies/136?pageIndex=0"
            className={css.link}
          >
            <FormattedMessage id="Footer.jobs" />
          </ExternalLink>
        </li>
      </ul>
    </div>
  );
};
