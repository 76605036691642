import React, { Component } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '..';
import { SecondaryButton } from '../../components';
import css from './ImpactRepSection.module.css';
import image2022 from '../../assets/aboutPage/ImpactReports/2022.webp';


import { connect } from 'react-redux';
const file2022de = 'https://drive.google.com/uc?export=download&id=1uLYtbR0XRa25XjPH3BU7Iw3kjf_wS1QU';
const file2022en = 'https://drive.google.com/uc?export=download&id=1gnQX3QFcWdZMrtgcdNiTiOnb2Zrams-P';

export class ImpactRepSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleClass: css.none,
      visibleLessButton: false,
      visibleMoreButton: true,
    };
  }

  render() {
    const { currentLanguage } = this.props;
    return (
      <>
        <div className={css.sectionWrapper} data-elb="section" data-elb-section="name:impactReport;position:8" data-elbaction="visible">
          <h1 className={css.sectionHeader}>
            <FormattedMessage id="about.impactReportHeadline" />
          </h1>
          <div>
            {currentLanguage === 'en' ? (
              <div className={css.contentContainer}>
                <div className={css.imageContainer}>
                  <ExternalLink href={file2022en} data-elbaction="click:click ImpactReportEN">
                    <div class={css.container}>
                      <img
                        className={css.reportImage}
                        src={image2022}
                        alt="Impact Report: See the Positive Influence of Every socialbnb Booking"
                      />
                      <img
                        className={css.reportImage}
                        src={image2022}
                        alt="Unveiling the Impact: Discover the Difference Made by Your socialbnb Stay"
                      />
                      <img
                        className={css.reportImage}
                        src={image2022}
                        alt="Visualizing Impact: Explore Our First Impact Report - socialbnb"
                      />
                      <img
                        className={css.reportImage}
                        src={image2022}
                        alt="From Booking to Impact: Dive into Our Accommodation Impact Report - socialbnb"
                      />
                    </div>
                  </ExternalLink>
                </div>
                <div className={css.textContainer}>
                  <div>
                    <FormattedMessage id="about.impactText" />
                  </div>
                  <br />
                  <div data-elbaction="click:click ImpactReportEN">
                    <ExternalLink href={file2022en}>
                      <SecondaryButton>
                        <FormattedMessage id="about.impactButton" />
                      </SecondaryButton>
                    </ExternalLink>
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.contentContainer}>
                <div className={css.imageContainer}>
                  <ExternalLink href={file2022de} data-elbaction="click:click ImpactReportDE">
                    <div class={css.container}>
                      <img className={css.reportImage} src={image2022} alt="Impact-Bericht: Sehen Sie die positive Auswirkung jeder sozialen Übernachtung auf die Region - socialbnb" />
                      <img className={css.reportImage} src={image2022} alt="Der Einfluss sichtbar gemacht: Entdecken Sie den Unterschied, den Ihre soziale Übernachtung mit socialbnb ausmacht" />
                      <img className={css.reportImage} src={image2022} alt="Wirkung visualisieren: Erkunden Sie unseren ersten Impact-Bericht - socialbnb" />
                      <img className={css.reportImage} src={image2022} alt="Von der Buchung bis zur Auswirkung: Tauchen Sie ein in unseren Unterkunftsimpact-Bericht - socialbnb" />
                    </div>
                  </ExternalLink>
                </div>
                <div className={css.textContainer}>
                  <div>
                    <FormattedMessage id="about.impactText" />
                  </div>
                  <br />
                  <div>
                    <ExternalLink href={file2022de} data-elbaction="click:click ImpactReportDE">
                      <SecondaryButton>
                        <FormattedMessage id="about.impactButton" />
                      </SecondaryButton>
                    </ExternalLink>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    currentLanguage: state.ui.language,
  };
};
export default connect(mapStateToProps)(ImpactRepSection);
