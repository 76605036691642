import React, { useEffect } from 'react';
import enFlag from './icons/enFlag.png';
import deFlag from './icons/deFlag.png';
import css from './LanguageSwitcher.module.css';
import { useHistory, useParams } from 'react-router-dom';
import { Menu, MenuLabel, MenuContent, MenuItem } from '..';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { handleSwitchLanguage } from '../../ducks/ui.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const LanguageSwitcherComponent = props => {
  const {
    onHandleSwitchNewLanguage,
    currentUser,
    language,
    onUpdateProfile,
    onMobile,
    externalClassName,
  } = props;
  //set language to saved value if any
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { lang: currentLang } = useParams();
  const reduxLanguage = useSelector(state => state.ui.language);

  // Update Redux language when URL changes
  useEffect(() => {
    if (currentLang && currentLang !== reduxLanguage) {
      dispatch(handleSwitchLanguage(currentLang));
    }
  }, [currentLang, reduxLanguage, dispatch]);

  let flagLabel = reduxLanguage === 'de' ? deFlag : enFlag;

  useEffect(async () => {
    const savedLanguageMaybe = cookies.get('language')
      ? cookies.get('language')
      : window.sessionStorage.getItem('sessionLanguage')
      ? window.sessionStorage.getItem('sessionLanguage')
      : navigator?.languages[0];
    let newLang = savedLanguageMaybe == 'de-DE' || savedLanguageMaybe == 'de' ? 'de' : 'en';
    await onHandleSwitchNewLanguage(newLang);
    setNewLang(newLang);
  }, []);

  const setNewLang = lang => {
    let newLang = lang == 'de-DE' || lang == 'de' ? 'de' : 'en';
    cookies.set('language', newLang, {
      path: '/',
      maxAge: 60 * 60 * 24 * 90,
    });
  };
  const handleLanguageSwitch = async lang => {
    setMenuOpen(current => !current);
    await onHandleSwitchNewLanguage(lang);
    dispatch(handleSwitchLanguage(lang));

    setNewLang(lang);
    if (typeof window !== 'undefined') {
      window.emitter.emit('sessionLanguage', lang);
    }

    const currentPath = window.location.pathname
      .split('/')
      .slice(2)
      .join('/');
    const currentSearch = window.location.search;

    // Update the URL with the selected language and keep query parameters
    history.push(`/${lang}/${currentPath}${currentSearch}`);

    // Update currentUser language
    if (currentUser?.id?.uuid) {
      await onUpdateProfile({
        protectedData: {
          language: lang,
        },
      });
    } else {
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('sessionLanguage', lang);
      }
    }
  };

  const currentPath = window.location.pathname
    .split('/')
    .slice(2)
    .join('/');
  const currentSearch = window.location.search;

  return (
    <div
      className={
        externalClassName
          ? externalClassName
          : onMobile
          ? css.langSwitchWrapperMobile
          : css.langSwitchWrapper
      }
      data-elbaction={'click:click topbar-languageswitch'}
    >
      <Menu isOpen={isMenuOpen} onToggleActive={setMenuOpen}>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <img src={flagLabel} className={css.icon} />
          <span className={css.mobileLabelMobile}>
            <FormattedMessage id={'languageSwitch.label'} />
          </span>
        </MenuLabel>
        <MenuContent className={onMobile ? css.profileMenuContentMobile : css.profileMenuContent}>
          <MenuItem key="en">
            <a
              className={css.labelWrapper}
              onClick={e => {
                e.preventDefault();
                handleLanguageSwitch('en');
              }}
              href={`/en/${currentPath}${currentSearch}`}
            >
              <img
                src={enFlag}
                className={css.icon}
                alt="nachhaltiger Tourismus/sustainable tourism"
              />
              <span className={css.mobileLabel}>
                <FormattedMessage id={'LanguageSwither.en'} />
              </span>
            </a>
          </MenuItem>
          <MenuItem key="de">
            <a
              className={css.labelWrapper}
              onClick={e => {
                e.preventDefault();
                handleLanguageSwitch('de');
              }}
              href={`/de/${currentPath}${currentSearch}`}
            >
              <img src={deFlag} className={css.icon} alt="nachhaltiger bnb/sustainable bnb" />
              <span className={css.mobileLabel}>
                <FormattedMessage id={'LanguageSwither.de'} />
              </span>
            </a>
          </MenuItem>
        </MenuContent>
      </Menu>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { language } = state.ui;

  return {
    currentUser,
    language,
  };
};

const mapDispatchToProps = dispatch => ({
  onHandleSwitchNewLanguage: newLanguage => dispatch(handleSwitchLanguage(newLanguage)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const LanguageSwitcher = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LanguageSwitcherComponent);

export default LanguageSwitcher;
