import css from './menuNew.module.css';
import { FormattedMessage } from 'react-intl';
import askQuestions from '../../assets/askQuestions.png';
import inbox from '../../assets/inbox.png';
import listingsIcon from '../../assets/listings.png';
import logoutSmallIcon from '../../assets/logoutSmall.png';
import profileIcon from '../../assets/profile.png';
import loginIcon from '../../assets/login.png';
import hostIcon from '../../assets/host.png';
import classNames from 'classnames';
import { LanguageSwitcher } from '../../..';
import { useParams } from 'react-router-dom';

export const MenuNew = props => {
  const { currentUser, history, onLogout, setMenuOpen } = props;
  const { lang } = useParams();

  const handleNavigation = targetPage => {
    setMenuOpen(false);
    if (targetPage) {
      history.push(`/${lang}/${targetPage}`);
    }
  };

  const isHost = currentUser?.attributes?.profile?.protectedData?.type === 'host';
  const hostListingMenu = {
    label: <FormattedMessage id="navbar.myListings" />,
    icon: listingsIcon,
    path: 'listings',
  };
  const menuItems = currentUser
    ? [
        {
          label: <FormattedMessage id="navbar.profile" />,
          icon: profileIcon,
          path: 'profile-settings',
        },
        {
          label: <FormattedMessage id="TopbarDesktop.inbox" />,
          icon: inbox,
          path: isHost ? 'inbox/sales' : 'inbox/orders',
        },
      ]
    : [
        {
          label: <FormattedMessage id="navbar.loginRegister" />,
          icon: loginIcon,
          path: 'login',
        },
      ];
  if (isHost) {
    menuItems.push(hostListingMenu);
  }

  return (
    <div className={css.menuContainer}>
      <div className={css.input}>
        {menuItems.map((item, index) => (
          <a
            key={index}
            className={classNames(css.value, item.className)}
            href={`/${lang}/${item.path}`}
            onClick={e => {
              e.preventDefault();
              handleNavigation(item.path);
            }}
            data-elbaction={
              item.label.props.id === 'navbar.loginRegister'
                ? 'click:click topbar-login/register'
                : null
            }
          >
            <img src={item.icon} className={css.icon} alt={`${item.label} icon`} />
            {item.label}
          </a>
        ))}
        <div className={css.languageSwitcherMobile}>
          {' '}
          <LanguageSwitcher onMobile={true} />
        </div>
        {!currentUser && (
          <a
            className={classNames(css.value, css.hostMenuItem)}
            href={`/${lang}/BecomeHostPage`}
            onClick={e => {
              e.preventDefault();
              handleNavigation('BecomeHostPage');
            }}
            data-elbaction="click:click topbar-becomehost"
          >
            <img src={hostIcon} className={css.icon} />
            {'Become a host'}
          </a>
        )}
        <a
          className={classNames(css.value, css.questionIconClass)}
          href={`/${lang}/Contact`}
          onClick={e => {
            e.preventDefault();
            handleNavigation('Contact');
          }}
          data-elbaction="click:click topbar-askQuestion"
        >
          <img src={askQuestions} className={css.icon} />
          <FormattedMessage id="navbar.askQuestion" />
        </a>
        {currentUser && (
          <button
            className={classNames(css.value, css.logoutButton)}
            onClick={() => {
              setMenuOpen(false);
              onLogout();
            }}
            data-elbaction="click:click topbar-logout"
          >
            <img src={logoutSmallIcon} className={css.icon} alt="Logout icon" />
            <FormattedMessage id="TopbarDesktop.logout" />
          </button>
        )}
      </div>
    </div>
  );
};
