import React, { useState } from 'react';
import classNames from 'classnames';
import css from './ForYouSection.module.css';
import img from './image.webp';
import img1 from './image1.webp';
import img2 from './image2.webp';
import { FormattedMessage, useIntl } from 'react-intl';
import { getEmailBody } from './emailPayload';
import { sendCustomEmail } from '../../../../util/sendEmails';
import Notification from '../../../../components/notification-popup/notification';

const ButtonStates = {
  ANIMAL_NATURE: 'ANIMAL_NATURE',
  SPORTS_HIKES: 'SPORTS_HIKES',
  SCIENCE: 'SCIENCE',
  ECOLOGICAL_FARMS: 'ECOLOGICAL_FARMS',
  CULTURE_COOKING: 'CULTURE_COOKING',
  YOGA: 'YOGA',
  OTHER: 'OTHER',
};

export const ForYouSection = () => {
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [notification, setNotification] = useState(false);
  const [formValues, setFormValues] = useState({
    destination: '',
    dates: '',
    email: '',
  });
  const [errors, setErrors] = useState({});
  const intl = useIntl();

  const handleButtonClick = value => {
    setSelectedButtons(prevSelected => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter(button => button !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const [extraType, setExtraType] = useState('');
  const handleTripSubjectInputChange = e => {
    const { value } = e.target;
    setExtraType(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const newErrors = {};

    if (!formValues.destination)
      newErrors.destination = <FormattedMessage id="foryousection.destinationError" />;
    if (!formValues.dates) newErrors.dates = <FormattedMessage id="foryousection.datesError" />;
    if (!formValues.email) {
      newErrors.email = <FormattedMessage id="foryousection.emailError" />;
    } else if (!validateEmail(formValues.email)) {
      newErrors.email = <FormattedMessage id="foryousection.emailErrorInvalid" />;
    }
    if (selectedButtons.length === 0)
      newErrors.selectedButtons = <FormattedMessage id="foryousection.selectedButtonError" />;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      let emailBody = getEmailBody({
        ...formValues,
        selectedActivities:
          extraType?.length > 0 ? [...selectedButtons, extraType] : selectedButtons,
      });

      setErrors({});
      sendCustomEmail('booking@socialbnb.de', 'New Form Submission on ForYouSection', emailBody);
      setFormValues({
        destination: '',
        dates: '',
        email: '',
      });
      setExtraType('');
      setSelectedButtons([]);
      const notif = intl.formatMessage({ id: 'forYousection.submitNotification' });
      setNotification(notif);
    }
  };

  return (
    <div className={css.mainWrapper}>
      {notification ? (
        <Notification message={notification} onClose={() => setNotification(null)} />
      ) : null}
      <div className={css.imgCardContainer}>
        <div className={css.imgWrapper}>
          <div className={css.img1}>
            <img src={img2} className={css.imgClass} />{' '}
          </div>
          <div className={css.img2}>
            <img src={img} className={css.imgClass} />{' '}
          </div>
          <div className={css.img3}>
            <img src={img1} className={css.imgClass} />{' '}
          </div>
        </div>
      </div>
      <div className={css.formWrapper}>
        <form onSubmit={handleSubmit}>
          <label className={css.inputLabel}>
            <span className={css.formLabel}>
              <FormattedMessage id="foryousection.firstQuestion" />
            </span>
            <input
              type="text"
              name="destination"
              placeholder={intl.formatMessage({ id: 'foryousection.interestPlaceholder' })}
              className={css.formInput}
              value={formValues.destination}
              onChange={handleInputChange}
            />
            {errors.destination && <div className={css.error}>{errors.destination}</div>}
          </label>
          <label className={css.inputLabel2}>
            <span className={css.formLabel}>
              <FormattedMessage id="foryousection.thirdQuestion" />
            </span>
            <input
              type="text"
              name="dates"
              placeholder={intl.formatMessage({ id: 'foryousection.datePlaceholder' })}
              className={css.formInput}
              value={formValues.dates}
              onChange={handleInputChange}
            />
            {errors.dates && <div className={css.error}>{errors.dates}</div>}
          </label>
          <label className={css.btnsWrapper}>
            <span className={css.formLabel}>
              <FormattedMessage id="foryousection.secondQuestion" />
            </span>
            <div className={css.buttonGroup}>
              <button
                type="button"
                className={classNames(css.btn, {
                  [css.selectedBtn]: selectedButtons.includes(ButtonStates.ANIMAL_NATURE),
                })}
                onClick={e => {
                  e.preventDefault();
                  handleButtonClick(ButtonStates.ANIMAL_NATURE);
                }}
              >
                <FormattedMessage id="foryousection.firstOption" />
              </button>
              <button
                type="button"
                className={classNames(css.btn, {
                  [css.selectedBtn]: selectedButtons.includes(ButtonStates.SPORTS_HIKES),
                })}
                onClick={e => {
                  e.preventDefault();
                  handleButtonClick(ButtonStates.SPORTS_HIKES);
                }}
              >
                <FormattedMessage id="foryousection.secondOption" />
              </button>
              <button
                type="button"
                className={classNames(css.btn, {
                  [css.selectedBtn]: selectedButtons.includes(ButtonStates.SCIENCE),
                })}
                onClick={e => {
                  e.preventDefault();
                  handleButtonClick(ButtonStates.SCIENCE);
                }}
              >
                <FormattedMessage id="foryousection.thirdOption" />
              </button>
              <button
                type="button"
                className={classNames(css.btn, {
                  [css.selectedBtn]: selectedButtons.includes(ButtonStates.ECOLOGICAL_FARMS),
                })}
                onClick={e => {
                  e.preventDefault();
                  handleButtonClick(ButtonStates.ECOLOGICAL_FARMS);
                }}
              >
                <FormattedMessage id="foryousection.fourthOption" />
              </button>
              <button
                type="button"
                className={classNames(css.btn, {
                  [css.selectedBtn]: selectedButtons.includes(ButtonStates.CULTURE_COOKING),
                })}
                onClick={e => {
                  e.preventDefault();
                  handleButtonClick(ButtonStates.CULTURE_COOKING);
                }}
              >
                <FormattedMessage id="foryousection.fifthOption" />
              </button>
              <button
                type="button"
                className={classNames(css.btn, {
                  [css.selectedBtn]: selectedButtons.includes(ButtonStates.YOGA),
                })}
                onClick={e => {
                  e.preventDefault();
                  handleButtonClick(ButtonStates.YOGA);
                }}
              >
                <FormattedMessage id="foryousection.sixthOption" />
              </button>
              <div className={css.extraInput}>
                <input
                  type="text"
                  name="extraType"
                  placeholder={intl.formatMessage({ id: 'foryousection.extraTypePlaceholder' })}
                  className={css.formInput}
                  value={extraType}
                  onChange={handleTripSubjectInputChange}
                />
              </div>
              {errors.extraType && <div className={css.error}>{errors.extraType}</div>}
            </div>
            {errors.selectedButtons && <div className={css.error}>{errors.selectedButtons}</div>}
          </label>
          <label className={css.inputLabel2}>
            <span className={css.formLabel}>
              <FormattedMessage id="foryousection.emailInput" />
            </span>
            <input
              type="email"
              name="email"
              placeholder={intl.formatMessage({ id: 'foryousection.emailPlaceholder' })}
              className={css.formInput}
              value={formValues.email}
              onChange={handleInputChange}
            />
            {errors.email && <div className={css.error}>{errors.email}</div>}
          </label>
          <button type="submit" className={css.submitBtn}>
            <FormattedMessage id="foryousection.submitBtn" />
          </button>
        </form>
      </div>
    </div>
  );
};
