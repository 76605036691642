/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

// import Cookies from 'universal-cookie';
import React from 'react';

import { FormattedMessage } from './util/reactIntl';
import { currencies } from './currencyNames';

// const cookies = new Cookies();

export const filters = [
  {
    id: 'category',
    label: (
      <>
        <FormattedMessage id="filter.topic" />
      </>
    ),
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      // img are the icons/images which belong to the project backround (for example key:Bildung)
      searchMode: 'has_any',
      options: [
        {
          key: 'Bildung',
          label: (
            <>
              <img src={require('./assets/categories/education.webp')} width="40px" alt="Empowering Education Initiatives - socialbnb"></img>{' '}
              <FormattedMessage id="filter.category.education" />
            </>
          ),
        },
        {
          key: 'Naturschutz',
          label: (
            <>
              <img src={require('./assets/categories/environment.webp')} width="40px" alt="Protecting Nature's Beauty - socialbnb"></img>{' '}
              <FormattedMessage id="filter.category.environment" />
            </>
          ),
        },
        {
          key: 'Tierschutz',
          label: (
            <>
              <img src={require('./assets/categories/animal.webp')} width="40px" alt="Advocating for Animal Welfare - socialbnb"></img>{' '}
              <FormattedMessage id="filter.category.animalCare" />
            </>
          ),
        },
        {
          key: 'Equality',
          label: (
            <>
              <img src={require('./assets/categories/equality.webp')} width="40px" alt="Promoting Equality for All - socialbnb"></img>{' '}
              <FormattedMessage id="filter.category.equality" />
            </>
          ),
        },
        {
          key: 'Health',
          label: (
            <>
              <img src={require('./assets/categories/health.webp')} width="40px" alt="Fostering Health and Well-being - socialbnb"></img>{' '}
              <FormattedMessage id="filter.category.health" />
            </>
          ),
        },
        {
          key: 'Sports',
          label: (
            <>
              <img src={require('./assets/categories/sports.webp')} width="40px" alt="Encouraging Active Lifestyles - socialbnb"></img>{' '}
              <FormattedMessage id="filter.category.sports" />
            </>
          ),
        },

        // { key: 'Bildung', label: <FormattedMessage id="filter.category.Bildung" /> },
        // { key: 'Naturschutz', label:  },
        // { key: 'Tierschutz', label: <FormattedMessage id="filter.category.Tierschutz" /> },
        // { key: 'Community', label: <FormattedMessage id="filter.category.Community" /> },
      ],
    },
  },

  {
    id: 'languages',
    label: (
      <>
        <FormattedMessage id="filter.languages" />
      </>
    ),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_languages'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      searchMode: 'has_any',
      options: [
        {
          key: 'english',
          label: (
            <>
              <FormattedMessage id="filter.language.english" />{' '}
            </>
          ),
        },
        {
          key: 'spanish',
          label: (
            <>
              <FormattedMessage id="filter.language.spanish" />{' '}
            </>
          ),
        },
        {
          key: 'french',
          label: (
            <>
              {' '}
              <FormattedMessage id="filter.language.french" />
            </>
          ),
        },
        {
          key: 'german',
          label: (
            <>
              <FormattedMessage id="filter.language.german" />
            </>
          ),
        },
        {
          key: 'portuguese',
          label: (
            <>
              {' '}
              <FormattedMessage id="filter.language.portuguese" />
            </>
          ),
        },
        {
          key: 'chinese',
          label: (
            <>
              {' '}
              <FormattedMessage id="filter.language.chinese" />
            </>
          ),
        },

        // { key: 'Bildung', label: <FormattedMessage id="filter.category.Bildung" /> },
        // { key: 'Naturschutz', label: <FormattedMessage id="filter.category.Naturschutz" /> },
        // { key: 'Tierschutz', label: <FormattedMessage id="filter.category.Tierschutz" /> },
        // { key: 'Community', label: <FormattedMessage id="filter.category.Community" /> },
      ],
    },
  },

  {
    id: 'accomodationtype',
    label: (
      <>
        <FormattedMessage id="filter.accomodationtype" />
      </>
    ),
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_accomodationtype'],
    config: {
/*       searchMode: 'has_any', */
      options: [
        {
          key: 'B&B',
          label: (
            <>
              <FormattedMessage id="accomodationFilter.B&B" />
            </>
          ),
          labelMsgId: 'accomodationFilter.B&B',
        },
        {
          key: 'glamping',
          label: (
            <>
              <FormattedMessage id="accomodationFilter.glamping" />
            </>
          ),
          labelMsgId: 'accomodationFilter.glamping',
        },
        {
          key: 'hostel',
          label: (
            <>
              <FormattedMessage id="accomodationFilter.hostel" />
            </>
          ),
          labelMsgId: 'accomodationFilter.hostel',
        },
        {
          key: 'hotel',
          label: (
            <>
              <FormattedMessage id="accomodationFilter.hotel" />
            </>
          ),
          labelMsgId: 'accomodationFilter.hotel',
        },
        {
          key: 'homestay',
          label: (
            <>
              <FormattedMessage id="accomodationFilter.homestay" />
            </>
          ),
          labelMsgId: 'accomodationFilter.homestay',
        },
        {
          key: 'holiday',
          label: (
            <>
              <FormattedMessage id="accomodationFilter.holiday" />
            </>
          ),
          labelMsgId: 'accomodationFilter.holiday',
        },
        {
          key: 'apartment',
          label: (
            <>
              <FormattedMessage id="accomodationFilter.apartment" />
            </>
          ),
          labelMsgId: 'accomodationFilter.apartment',
        },
        {
          key: 'tinyHouse',
          label: (
            <>
              <FormattedMessage id="accomodationFilter.tinyHouse" />
            </>
          ),
          labelMsgId: 'accomodationFilter.tinyHouse',
        },
        {
          key: 'camping',
          label: (
            <>
              <FormattedMessage id="accomodationFilter.camping" />
            </>
          ),
          labelMsgId: 'accomodationFilter.camping',
        },
        {
          key: 'banglow',
          label: (
            <>
              <FormattedMessage id="accomodationFilter.banglow" />
            </>
          ),
          labelMsgId: 'accomodationFilter.banglow',
        },
      ],
    },
  },

  {
    id: 'roomtype',
    label: (
      <>
        <FormattedMessage id="filter.roomtype" />
      </>
    ),
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_roomType'],
    config: {
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'singlebedroom',
          label: (
            <>
            {/*   <img
                src={require('./assets/icons/roomtypes/onebedroom.png')}
                width="40px"
                alt="Single bedroom bnb - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="roomtypes.singlebedroom" />
            </>
          ),
          labelMsgId: 'roomtypes.singlebedroom',
        },
        {
          key: 'doublebedroom',
          label: (
            <>
       {/*        <img
                src={require('./assets/icons/roomtypes/doublebedroom.png')}
                width="40px"
                alt="Double bedroom bnb - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="roomtypes.doublebedroom" />
              {/* <img className="info1" src={require('./assets/icons/info-icon-thick.png')} width="25px" alt=""></img>{' '} */}
            </>
          ),
          labelMsgId: 'roomtypes.doublebedroom',
        },
        {
          key: 'twobedroom',
          label: (
            <>
          {/*     <img
                src={require('./assets/icons/roomtypes/twobedroom.png')}
                width="40px"
                alt="Two bedroom bnb - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="roomtypes.twobedroom" />
              {/* <img className="info2" src={require('./assets/icons/info-icon-thick.png')} width="25px" alt=""></img>{' '} */}
            </>
          ),
          labelMsgId: 'roomtypes.twobedroom',
        },
        {
          key: 'shared_bedroom',
          label: (
            <>
             {/*  <img
                src={require('./assets/icons/roomtypes/shared_bedroom.png')}
                width="40px"
                alt="Shared bedroom bnb - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="roomtypes.shared_bedroom" />
              {/* <img className="info3" src={require('./assets/icons/info-icon-thick.png')} width="25px" alt=""></img>{' '} */}
            </>
          ),
          labelMsgId: 'roomtypes.shared_bedroom',
        },

        /*-----here i am working ----------------------------------------------------------- */
        {
          key: 'multi_bedroom',
          label: (
            <>
    {/*           <img
                src={require('./assets/icons/roomtypes/multi_bedroom.png')}
                width="40px"
                alt="Multi bedrom bnb - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="roomtypes.multi_bedroom" />
              {/* <img className="info3" src={require('./assets/icons/info-icon-thick.png')} width="25px" alt=""></img>{' '} */}
            </>
          ),
          labelMsgId: 'roomtypes.multi_bedroom',
        },

        {
          key: 'entire_accomodation',
          label: (
            <>
            {/*   <img
                src={require('./assets/icons/roomtypes/entire_accomodation.png')}
                width="40px"
                alt="Entire accomodation bnb - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="roomtypes.entire_accomodation" />
              {/* <img className="info4" src={require('./assets/icons/info-icon-thick.png')} width="25px" alt=""></img>{' '} */}
            </>
          ),
          labelMsgId: 'roomtypes.entire_accomodation',
        },
        {
          key: 'tent',
          label: (
            <>
              <img src={require('./assets/icons/roomtypes/camping.png')} width="40px" alt="Tent accomodation bnb - socialbnb"></img>{' '}
              <FormattedMessage id="roomtypes.tent" />
              {/* <img className="info5" src={require('./assets/icons/info-icon-thick.png')} width="25px" alt=""></img>{' '} */}
            </>
          ),
          labelMsgId: 'roomtypes.tent',
        },
        // {
        //   key: 'camping',
        //   label: (
        //     <>
        //       <img src={require('./assets/icons/roomtypes/camping.png')} width="40px" alt=""></img>{' '}
        //       <FormattedMessage id="roomtypes.camping" />
        //       {/* <img className="info5" src={require('./assets/icons/info-icon-thick.png')} width="25px" alt=""></img>{' '} */}
        //     </>
        //   ),
        //   labelMsgId: 'roomtypes.camping',
        // },
      ],
    },
  },
  {
    id: 'price',
    label: (
      <>
        <FormattedMessage id="filter.price" />
      </>
    ),
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 950,
      step: 5,
    },
  },
  {
    id: 'customCurrency',
    label: 'Currency',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['custom_Currency'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',
      options: currencies,
    },
  },

  {
    id: 'characteristics',
    label: 'Highlights',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_characteristics'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'comfort',
          label: (
            <>
              {/* <img
                src={require('./assets/icons/characteristics/Komfort.webp')}
                width="40px"
                alt="Ensuring Comfort for All Guests - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.comfort" />
            </>
          ),
        },
        {
          key: 'adventure',
          label: (
            <>
          {/*     <img
                src={require('./assets/icons/characteristics/adventure.webp')}
                width="40px"
                alt="Embark on Exciting Adventures - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.adventure" />
            </>
          ),
        },
        {
          key: 'nature',
          label: (
            <>
{/*               <img
                src={require('./assets/icons/characteristics/Nature.webp')}
                width="40px"
                alt="Immerse Yourself in Nature's Beauty - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.nature" />
            </>
          ),
        },
        {
          key: 'near_beach',
          label: (
            <>
          {/*     <img
                src={require('./assets/icons/characteristics/Beach.webp')}
                width="40px"
                alt="Experience Beachside Bliss - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.near_beach" />
            </>
          ),
        },
        {
          key: 'animallovers',
          label: (
            <>
         {/*      <img
                src={require('./assets/icons/characteristics/Animals.webp')}
                width="40px"
                alt="Catering to Animal Lovers - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.animallovers" />
            </>
          ),
        },
        {
          key: 'sportlovers',
          label: (
            <>
            {/*   <img
                src={require('./assets/icons/characteristics/Sport.webp')}
                width="40px"
                alt="Perfect for Sport Enthusiasts - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.sportlovers" />
            </>
          ),
        },

        {
          key: 'familyfriendly',
          label: (
            <>
            {/*   <img
                src={require('./assets/icons/characteristics/Family.webp')}
                width="40px"
                alt="Welcoming Families with Open Arms - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.familyfriendly" />
            </>
          ),
        },

        {
          key: 'trekking',
          label: (
            <>
     {/*          <img
                src={require('./assets/icons/characteristics/Trekking.webp')}
                width="40px"
                alt="Explore Uncharted Territories - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.trekking" />
            </>
          ),
        },
        {
          key: 'culture',
          label: (
            <>
            {/*   <img
                src={require('./assets/icons/characteristics/Theatre.webp')}
                width="40px"
                alt="Immerse Yourself in Local Culture - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.culture" />
            </>
          ),
        },
        {
          key: 'barrier_free',
          label: (
            <>
             {/*  <img
                src={require('./assets/icons/characteristics/barrierefreiheit.webp')}
                width="40px"
                alt="Ensuring Accessibility for All - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.barrier_free" />
            </>
          ),
        },
        {
          key: 'accessibility',
          label: (
            <>
             {/*  <img
                src={require('./assets/icons/characteristics/Bus.webp')}
                width="40px"
                alt="Making Travel Inclusive for Everyone - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.accessibility" />
            </>
          ),
        },
        {
          key: 'projecttours_possible',
          label: (
            <>
             {/*  <img
                src={require('./assets/icons/characteristics/Projektbesuch_.webp')}
                width="40px"
                alt="Embark on Inspiring Project Tours - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.projecttours_possible" />
            </>
          ),
        },
        {
          key: 'projecttours_impossible',
          label: (
            <>
              {/* <img
                src={require('./assets/icons/characteristics/Projektbesuch_Cancel.webp')}
                width="40px"
                alt="Discover Unique Adventures Beyond Tours - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.characteristics.projecttours_impossible" />
            </>
          ),
        },
      ],
    },
  },

  // {
  //   id: 'keyword',
  //   label: 'Keyword',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },

  {
    id: 'food',
    label: (
      <>
        <FormattedMessage id="EditListingOffersForm.foodTitle" />
      </>
    ),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_food'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      options: [
        {
          key: 'breakfast_inclusive',
          label: (
            <>
            {/*   <img src={require('./assets/icons/meals/Breakfast.webp')} width="40px" alt=""></img>{' '} */}
              <FormattedMessage id="filter.food.breakfast_inclusive" />
            </>
          ),
        },
        {
          key: 'breakfast_for_sale',
          label: (
            <>
           {/*    <img
                src={require('./assets/icons/meals/Breakfast-paid.webp')}
                width="40px"
                alt="Start Your Day Right: Inclusive Breakfast Offerings - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.food.breakfast_for_sale" />
            </>
          ),
        },
        {
          key: 'lunch_inclusive',
          label: (
            <>
            {/*   <img src={require('./assets/icons/meals/Lunch.webp')} width="40px" alt="Enhance Your Stay: Breakfast Options for Sale - socialbnb"></img>{' '}
              */} <FormattedMessage id="filter.food.lunch_inclusive" />
            </>
          ),
        },
        {
          key: 'lunch_for_sale',
          label: (
            <>
             {/*  <img src={require('./assets/icons/meals/Lunch-paid.webp')} width="40px" alt="Enjoy a Delightful Lunch: Inclusive Options Available - socialbnb"></img>{' '}
              */} <FormattedMessage id="filter.food.lunch_for_sale" />
            </>
          ),
        },

        {
          key: 'dinner_inclusive',
          label: (
            <>
            {/*   <img src={require('./assets/icons/meals/Abendessen.webp')} width="40px" alt="Customize Your Dining Experience: Lunch for Sale - socialbnb"></img>{' '}
              */} <FormattedMessage id="filter.food.dinner_inclusive" />
            </>
          ),
        },
        {
          key: 'dinner_for_sale',
          label: (
            <>
           {/*    <img
                src={require('./assets/icons/meals/Abendessen-paid.webp')}
                width="40px"
                alt="Indulge in Evening Meals: Inclusive Dinner Offerings - socialbnb"
              ></img>{' '}
              */} <FormattedMessage id="filter.food.dinner_for_sale" />
            </>
          ),
        },
        {
          key: 'vegetarian_options',
          label: (
            <>
             {/*  <img src={require('./assets/icons/meals/Vegetarian_.webp')} width="40px" alt="Variety for Vegetarians: Explore Vegetarian Options - socialbnb"></img>{' '}
              */} <FormattedMessage id="filter.food.vegetarian_options" />
            </>
          ),
        },
        {
          key: 'vegan_options',
          label: (
            <>
            {/*   <img src={require('./assets/icons/meals/Vegan.webp')} width="40px" alt="Plant-Powered Choices: Discover Vegan Options - socialbnb"></img>{' '}
              */} <FormattedMessage id="filter.food.vegan_options" />
            </>
          ),
        },

        {
          key: 'coffee_tee',
          label: (
            <>
            {/*   <img src={require('./assets/icons/meals/Coffee_.webp')} width="40px" alt="Coffee and Tea Delights: Included in Your Stay - socialbnb"></img>{' '}
             */}  <FormattedMessage id="filter.food.coffee_tee" />
            </>
          ),
        },
      ],
    },
  },

  {
    id: 'amenities',
    label: (
      <>
        <FormattedMessage id="EditListingWizard.tabLabelFeatures" />
      </>
    ),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      options: [
        {
          key: 'amenities',
          label: (
            <>
             {/*  <img src={require('./assets/icons/equipment/Lampe.webp')} width="40px" alt="Essential Amenities Provided for Your Comfort - socialbnb"></img>{' '}
              */} <FormattedMessage id="filter.amenities.light" />
            </>
          ),
        },
        {
          key: 'electricity',
          label: (
            <>
            {/*   <img
                src={require('./assets/icons/equipment/Electricity.webp')}
                width="40px"
                alt="Stay Powered Up: Access to Electricity - socialbnb"
              ></img>{' '}
             */}  <FormattedMessage id="filter.amenities.electricity" />
            </>
          ),
        },

        {
          key: 'bedsheets',
          label: (
            <>
           {/*    <img
                src={require('./assets/icons/equipment/Bettwäsche_.webp')}
                width="40px"
                alt="Fresh Bedsheets for a Restful Sleep - socialbnb"
              ></img>{' '}
             */}  <FormattedMessage id="filter.amenities.bedsheets" />
            </>
          ),
        },
        {
          key: 'sink',
          label: (
            <>
             {/*  <img src={require('./assets/icons/equipment/Sink.webp')} width="40px" alt="Convenient Sink Access for Your Needs - socialbnb"></img>{' '}
             */}  <FormattedMessage id="filter.amenities.sink" />
            </>
          ),
        },
        {
          key: 'shower',
          label: (
            <>
             {/*  <img src={require('./assets/icons/equipment/Shower.webp')} width="40px" alt="Refresh and Renew: Access to a Shower - socialbnb"></img>{' '}
             */}  <FormattedMessage id="filter.amenities.shower" />
            </>
          ),
        },
        {
          key: 'toilet',
          label: (
            <>
             {/*  <img src={require('./assets/icons/equipment/Toilet.webp')} width="40px" alt="Essential Facilities: Access to a Toilet - socialbnb"></img>{' '}
             */}  <FormattedMessage id="filter.amenities.toilet" />
            </>
          ),
        },

        {
          key: 'privat_bathroom',
          label: (
            <>
          {/*     <img
                src={require('./assets/icons/equipment/Bathroom.webp')}
                width="40px"
                alt="Your Own Private Oasis: Access to a Private Bathroom - socialbnb"
              ></img>{' '}
             */}  <FormattedMessage id="filter.amenities.privat_bathroom" />
            </>
          ),
        },
        {
          key: 'shared_bathroom',
          label: (
            <>
            {/*   <img
                src={require('./assets/icons/equipment/Bathroom-Shared.webp')}
                width="40px"
                alt="Convenient Facilities: Access to a Shared Bathroom - socialbnb"
              ></img>{' '}
             */}  <FormattedMessage id="filter.amenities.shared_bathroom" />
            </>
          ),
        },
        {
          key: 'private_kitchen',
          label: (
            <>
            {/*   <img src={require('./assets/icons/equipment/Kitchen.webp')} width="40px" alt="Cook Your Favorite Meals: Access to a Private Kitchen - socialbnb"></img>{' '}
             */}  <FormattedMessage id="filter.amenities.private_kitchen" />
            </>
          ),
        },
        {
          key: 'shared_kitchen',
          label: (
            <>
           {/*    <img
                src={require('./assets/icons/equipment/SharedKitchen.webp')}
                width="40px"
                alt="Collaborative Cooking Space: Access to a Shared Kitchen - socialbnb"
              ></img>{' '}
              */} <FormattedMessage id="filter.amenities.shared_kitchen" />
            </>
          ),
        },
        {
          key: 'internet',
          label: (
            <>
           {/*    <img src={require('./assets/icons/equipment/Wifi.webp')} width="40px" alt="Stay Connected: Access to Internet - socialbnb"></img>{' '}
             */}  <FormattedMessage id="filter.amenities.internet" />
            </>
          ),
        },
        {
          key: 'computer',
          label: (
            <>
          {/*     <img
                src={require('./assets/icons/equipment/Computer.webp')}
                width="40px"
                alt="Productivity On the Go: Access to a Computer - socialbnb"
              ></img>{' '}
              */} <FormattedMessage id="filter.amenities.computer" />
            </>
          ),
        },
        {
          key: 'tv',
          label: (
            <>
             {/*  <img src={require('./assets/icons/equipment/Tv.webp')} width="40px" alt="Entertainment at Your Fingertips: Access to TV - socialbnb"></img>{' '}
             */}  <FormattedMessage id="filter.amenities.tv" />
            </>
          ),
        },
        {
          key: 'mosquito_net',
          label: (
            <>
            {/*   <img src={require('./assets/icons/equipment/Moskito.webp')} width="40px" alt="Keep the Bugs Away: Provided Mosquito Net - socialbnb"></img>{' '}
             */}  <FormattedMessage id="filter.amenities.mosquito_net" />
            </>
          ),
        },
        {
          key: 'towels',
          label: (
            <>
             {/*  <img
                src={require('./assets/icons/equipment/Handtücher_.webp')}
                width="40px"
                alt="Stay Fresh: Complimentary Towels Provided - socialbnb"
              ></img>{' '}
              */} <FormattedMessage id="filter.amenities.towels" />
            </>
          ),
        },
        {
          key: 'fan',
          label: (
            <>
           {/*    <img
                src={require('./assets/icons/equipment/Ventilator_.webp')}
                width="40px"
                alt="Stay Cool and Comfortable: Access to a Fan - socialbnb"
              ></img>{' '}
             */}  <FormattedMessage id="filter.amenities.fan" />
            </>
          ),
        },
        {
          key: 'air_conditioning',
          label: (
            <>
           {/*    <img
                src={require('./assets/icons/equipment/Klimaanlage.webp')}
                width="40px"
                alt="Control Your Environment: Access to Air Conditioning - socialbnb"
              ></img>{' '}
             */}  <FormattedMessage id="filter.amenities.air_conditioning" />
            </>
          ),
        },

        {
          key: 'heating',
          label: (
            <>
             {/*  <img src={require('./assets/icons/equipment/Heater.webp')} width="40px" alt="Stay Warm and Cozy: Access to Heating - socialbnb"></img>{' '}
              */} <FormattedMessage id="filter.amenities.heating" />
            </>
          ),
        },
        {
          key: 'safe',
          label: (
            <>
           {/*    <img
                src={require('./assets/icons/equipment/Security_Locker.webp')}
                width="40px"
                alt="Peace of Mind: Secure Your Belongings with a Safe - socialbnb"
              ></img>{' '}
              */} <FormattedMessage id="filter.amenities.safe" />
            </>
          ),
        },

        {
          key: 'wardrobe',
          label: (
            <>
            {/*   <img src={require('./assets/icons/equipment/Dresser.webp')} width="40px" alt="Organized Living: Utilize a Wardrobe for Storage - socialbnb"></img>{' '}
             */}  <FormattedMessage id="filter.amenities.wardrobe" />
            </>
          ),
        },

        {
          key: 'parking',
          label: (
            <>
            {/*   <img src={require('./assets/icons/equipment/Parking.webp')} width="40px" alt="Convenient Parking Available for Guests - socialbnb"></img>{' '}
              */} <FormattedMessage id="filter.amenities.parking" />
            </>
          ),
        },

        {
          key: 'luggage_storage',
          label: (
            <>
            {/*   <img src={require('./assets/icons/equipment/Luggage.webp')} width="40px" alt="Secure Luggage Storage for Your Convenience - socialbnb"></img>{' '}
              */} <FormattedMessage id="filter.amenities.luggage_storage" />
            </>
          ),
        },

        {
          key: 'laundry_facilities',
          label: (
            <>
            {/*   <img
                src={require('./assets/icons/equipment/Waschküche_.webp')}
                width="40px"
                alt="Keep Your Clothes Fresh: Access to Laundry Facilities - socialbnb"
              ></img>{' '}
              */} <FormattedMessage id="filter.amenities.laundry_facilities" />
            </>
          ),
        },
      ],
    },
  },

  // {
  //   id: 'accomodationtype',
  //   label: 'Unterkunftstyp',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_accomodationtype'],
  //   config: {
  //     searchMode: 'has_all',

  //     options: [
  //       { key: 'Lodge', label: 'Lodge' },
  //       { key: 'Hostel', label: 'Hostel' },
  //       { key: 'Homestay', label: 'Homestay' },
  //     ],
  //   },
  // },

  {
    id: 'surroundings',
    label: (
      <>
        <FormattedMessage id="filter.surroundings" />
      </>
    ),
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_surroundings'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      options: [
        {
          key: 'supermarket',
          label: (
            <>
             {/*  <img
                src={require('./assets/icons/surroundings/Supermarket.png')}
                width="40px"
                alt="Convenient Supermarket Access Nearby - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.surroundings.supermarket" />
            </>
          ),
        },
        {
          key: 'restaurant',
          label: (
            <>
              {/* <img
                src={require('./assets/icons/surroundings/Restaurant.png')}
                width="40px"
                alt="Local Restaurant Options for Dining - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.surroundings.restaurant" />
            </>
          ),
        },
        {
          key: 'facility',
          label: (
            <>
              {/* <img
                src={require('./assets/icons/surroundings/Doctor.png')}
                width="40px"
                alt="Facilities Nearby for Your Convenience - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.surroundings.facility" />
            </>
          ),
        },
        {
          key: 'washing',
          label: (
            <>
        {/*       <img
                src={require('./assets/icons/equipment/Waschküche_.webp')}
                width="40px"
                alt="Washing Facilities Accessible Nearby - socialbnb"
              ></img>{' '} */}
              <FormattedMessage id="filter.surroundings.washing" />
            </>
          ),
        },

        {
          key: 'bank',
          label: (
            <>
             {/*  <img src={require('./assets/icons/surroundings/Bank.png')} width="40px" alt="Banking Services in the Area - socialbnb"></img>{' '}
              */} <FormattedMessage id="filter.surroundings.bank" />
            </>
          ),
        },
        {
          key: 'public_transport',
          label: (
            <>
             {/*  <img
                src={require('./assets/icons/characteristics/Bus.webp')}
                width="40px"
                alt="Easy Access to Public Transport - socialbnb"
              ></img>{' '}
              */} <FormattedMessage id="filter.surroundings.public_transport" />
            </>
          ),
        },
        {
          key: 'tour',
          label: (
            <>
           {/*    <img
                src={require('./assets/icons/surroundings/Touranbieter.png')}
                width="40px"
                alt="Tour Opportunities in the Vicinity - socialbnb"
              ></img>{' '}
              */} <FormattedMessage id="filter.surroundings.tour" />
            </>
          ),
        },
      ],
    },
  },

  // {
  //   id: 'dates',
  //   label: 'Dates',
  //   type: 'BookingDateRangeFilter',
  //   group: 'secondary',

  //   queryParamNames: ['dates'],
  //   config: {},
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    /*{
      These are not needed right now ->



      key: '-createdAt',
      label: (
        <>
          <FormattedMessage id="filter.sort.newest" />
        </>
      ),
    },
    {
      key: 'createdAt',
      label: (
        <>
          <FormattedMessage id="filter.sort.oldest" />
        </>
      ),
    },*/

    {
      key: '-price',
      label: <FormattedMessage id="filter.sort.lowest_price" />,
    },
    {
      key: 'price',
      label: <FormattedMessage id="filter.sort.highest_price" />,
    },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    /*
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
    */
  ],
};
