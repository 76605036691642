import { FormattedMessage, useIntl } from 'react-intl';
import css from './Header.module.css';
import cover from './assets/cover.webp';
import { TopBarSearch } from '../../Navbar/helpers/topBarSearch/topBarSearch';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const FooterHeader = () => {
  const history = useHistory();
  const [location, setLocation] = useState(null);
  const currentSearchParams = {};
  const lang = useIntl().locale.trim();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLocation(window.location);
    }
  }, []);
  if (!location) return <></>;

  return (
    <div
      className={css.wrapper}
      data-elb="section"
      data-elb-section="name:footerButtom;position:12"
      data-elbaction="visible:footer search"
    >
      <div className={css.titleWrapper}>
        <div className={css.headerText}>
          <FormattedMessage id="footer.header.title" />
        </div>
        <div className={css.searchBar} data-elbaction={'click:click footer-search'}>
          <TopBarSearch
            history={history}
            location={location}
            currentSearchParams={currentSearchParams}
            language={lang}
          />
        </div>
      </div>
      <img src={cover} alt="cover" className={css.cover} />
    </div>
  );
};
