import { Walkerjs, elb } from '@elbwalker/walker.js';
import destinationGoogleGA4 from '@elbwalker/destination-web-google-ga4';
import destinationWebAPI from '@elbwalker/destination-web-api';
import Cookies from 'universal-cookie'

export function setupWalkerjs() {
  var cookies = new Cookies();
  var cookieClient = cookies.get('__hs_cookie_cat_pref') ? cookies.get('__hs_cookie_cat_pref') : null;
  var cookieClientApproval = cookieClient == '1:true_2:true_3:true' ? true : false;
  console.log('walker cookieClient ',cookieClient,cookies.get('__hs_cookie_cat_pref'));

  const devEnv = ((process.env.REACT_APP_MAIN_ENV == 'dev' ) ||
                  (process.env.REACT_APP_MAIN_ENV == 'development' )) ? true : false ;

  if(typeof(window) !== 'undefined'){
    if (window.walkerjs) return;

    // Optional, but helpful for debugging
    window.elbLayer = [];
    window.elb = elb;

    // Initialize the walkerOS web client
    window.walkerjs = Walkerjs({
        dataLayer: true,
        session: {
            storage: true,
            consent: "marketing",
        }
    });

    if(devEnv){
    console.log('Setting up analytics ',process.env.REACT_APP_MAIN_ENV);
    //Destination Console
    elb('walker destination', {push: (data) => {console.log('Walker',data)}});
    }else{
    // Destination GA4
    elb('walker destination', destinationGoogleGA4, {
        constent: {
            marketing: cookieClientApproval,
        },
        custom: {
            measurementId: 'G-F2LBJPDPKK',
            pageview: false,
            include: ['all'],
            snakeCase: true,
        }
    });
    //Destination API
    elb('walker destination', destinationWebAPI, {
        constent: {
             functional: cookieClientApproval,
        },
        custom: {
            url: 'https://ingest-j6g2sfloia-ew.a.run.app',
            //url: 'https://ingest-d4xet77liq-ew.a.run.app', //Michas Firebase
            transport: 'beacon',
        }
    });
    }
  }
  }
