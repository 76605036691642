import React, { useEffect } from 'react';
import css from './AmbassadorPage.module.css';

const HubspotForm = ({ pageLanguage }) => {
  useEffect(() => {
    // Erstellt das Formular, wenn das Hubspot-Formular-Script geladen ist
    if (pageLanguage === 'en') {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '6725180',
          formId: 'b8613366-50d8-432b-9e59-284644458091',
        });
      }
    } else {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '6725180',
          formId: 'f6953c72-72b3-4031-a874-121df3ff45b1',
        });
      }
    }
  }, []);

  return (
    <div className={css.hsForm}>
      {pageLanguage === 'en' ? (
        <>
          <script>
            {`hbspt.forms.create({
                  region: "na1",
                  portalId: "6725180",
                  formId: "b8613366-50d8-432b-9e59-284644458091"
                });`}
          </script>
        </>
      ) : (
        <>
          <script>
            {`hbspt.forms.create({
                  region: "na1",
                  portalId: "6725180",
                  formId: "f6953c72-72b3-4031-a874-121df3ff45b1"
                });`}
          </script>
        </>
      )}
    </div>
  );
};

export default HubspotForm;
