import css from './sectionOurTeam.module.css';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import imgAlex from './images/01_Alex_orange.webp';
import imgNils from './images/02_Nils_petrol.webp';
import imgMicha from './images/10_Micha.webp';
import imgYasser from './images/11_Yasser.webp';
import closeBtn from '../../assets/close.webp';
import { useRef } from 'react';

export default function SectionOurTeam() {
  const [showModal, setShowModal] = useState(false);
  const [memberInfo, setMemberInfo] = useState('');
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const handleModal = memberName => {
    if (windowSize.current[0] < 850) {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      window.onscroll = function() {
        window.scrollTo(scrollLeft, scrollTop);
      };
      setMemberInfo(<FormattedMessage id={`about.${memberName}MemberText`} />);
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (showModal == false) {
      window.onscroll = function() {};
    }
  }, [showModal]);

  return (
    <div className={css.teamWrapper}  data-elb="section" data-elb-section="name:ourTeam;position:5" data-elbaction="visible">
      {showModal && (
        <React.Fragment>
          <div className={css.modalBackdrop} onClick={() => setShowModal(false)}></div>
          <div className={css.memberModal}>
            <img
              onClick={() => setShowModal(false)}
              className={css.btnClose}
              src={closeBtn}
              alt="closeButton"
            />

            <div className={css.memberInf}>{memberInfo}</div>
          </div>
        </React.Fragment>
      )}
      <div className={css.teamTextWrapper}>
        <div className={css.teamTextHeaderWrapper}>
          <h1 className={css.teamTextHeader}>
            <FormattedMessage id="about.teamHeadline" />
          </h1>
          <p></p>
        </div>
      </div>
      <div className={css.teamImagesWrapper}>
        <div className={css.memberWrapper}>
          <div className={css.memberFrameOrange}>
            <img
              src={imgAlex}
              className={css.memberImage}
              onClick={() => handleModal('alex')}
              alt="socialbnb member Alex"
            />
          </div>
          <div className={css.memberInfo}>
            <h1>Alex</h1>
            <p>
              <FormattedMessage id="about.alexMemberText" />
            </p>
          </div>
        </div>
        <div className={css.memberWrapper}>
          <div className={css.memberFrameBlue}>
            <img
              src={imgNils}
              className={css.memberImage}
              onClick={() => handleModal('nils')}
              alt="socialbnb member Nils"
            />
          </div>
          <div className={css.memberInfo}>
            <h1>Nils</h1>
            <p>
              {' '}
              <FormattedMessage id="about.nilsMemberText" />
            </p>
          </div>
        </div>
        <div className={css.memberWrapper}>
          <div className={css.memberFrameOrange}>
            <img
              src={imgMicha}
              className={css.memberImage}
              onClick={() => handleModal('micha')}
              alt="socialbnb member Micha "
            />
          </div>
          <div className={css.memberInfo}>
            <h1>Micha</h1>
            <p>
              {' '}
              <FormattedMessage id="about.michaMemberText" />
            </p>
          </div>
        </div>

        {/* <div className={css.memberWrapper}>
          <div className={css.memberFrameBlue}>
            <img
              src={imgEugen}
              className={css.memberImage}
              onClick={() => handleModal('eugen')}
              alt="socialbnb member Eugen"
            />
          </div>
          <div className={css.memberInfo}>
            <h1>Eugen</h1>
            <p>
              {' '}
              <FormattedMessage id="about.eugenMemberText" />
            </p>
          </div>
        </div> */}

        {/* <div className={css.memberWrapper}>
          <div className={css.memberFrameBlue} onClick={() => handleModal('jasper')}>
            <img className={css.photo} src={imgJasper} alt="" />
            <img className={css.photo} src={imgGreg} alt="" />
          </div>
          <div className={css.dogInfo}>
            <h1 className={`${css.jasperName} ${css.jasperNameAnimation}`}>Jasper</h1>
            <h1 className={`${css.gregName} ${css.gregNameAnimation}`}>Greg</h1>
            <p>
              {' '}
              <FormattedMessage id="about.jasperMemberText" />
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
}
