import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import css from './SectionStepsNew.module.css';
import Swipe from 'react-easy-swipe';

import value1 from '../../../../assets/icons/how-it-works-card-one.webp';
import value2 from '../../../../assets/icons/support3.svg';
import value3 from '../../../../assets/icons/Backpack-icon.webp';

import thirdBackgroundImage from './images/tent700.webp';
import thirdBackgroundImageMobile from './images/tent 450.webp';

const SectionStepsNew = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize(); // Initial check

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const Data = [
    {
      index: 0,
      title: <FormattedMessage id="howItWorksCards.titleAuthentic" />,
      headline: <FormattedMessage id="sectionSteps.two" />,
      src: value3,
    },
    {
      index: 1,
      title: <FormattedMessage id="howItWorksCards.titleSupportRegion" />,
      headline: <FormattedMessage id="sectionSteps.three" />,
      src: value2,
    },
    {
      index: 2,
      title: <FormattedMessage id="howItWorksCards.titleExperience" />,
      headline: <FormattedMessage id="sectionSteps.one" />,
      src: value1,
    },
    {
      index: 3,
      title: <FormattedMessage id="howItWorksCards.titleAuthentic" />,
      headline: <FormattedMessage id="sectionSteps.two" />,
      src: value3,
    },
    {
      index: 4,
      title: <FormattedMessage id="howItWorksCards.titleSupportRegion" />,
      headline: <FormattedMessage id="sectionSteps.three" />,
      src: value2,
    },

    {
      index: 5,
      title: <FormattedMessage id="howItWorksCards.titleExperience" />,
      headline: <FormattedMessage id="sectionSteps.one" />,
      src: value1,
    },
  ];

  const desktopData = [
    {
      index: 0,
      title: <FormattedMessage id="howItWorksCards.titleExperience" />,
      headline: <FormattedMessage id="sectionSteps.one" />,
      src: value1,
    },
    {
      index: 1,
      title: <FormattedMessage id="howItWorksCards.titleAuthentic" />,
      headline: <FormattedMessage id="sectionSteps.two" />,
      src: value3,
    },
    {
      index: 2,
      title: <FormattedMessage id="howItWorksCards.titleSupportRegion" />,
      headline: <FormattedMessage id="sectionSteps.three" />,
      src: value2,
    },
  ];

  //logic carousel
  const [slideData, setSlideData] = useState(Data);
  const [desktopSliderData] = useState(desktopData);
  const [index, setIndex] = useState(0);
  const [autoplay, setAutoplay] = useState(false);

  //modular f(x) , returns positive value
  const mod = (n, m) => {
    let result = n % m;

    return result >= 0 ? result : result + m;
  };

  // autoplay
  useEffect(() => {
    if (autoplay) {
      const intervalId = setTimeout(() => {
        setIndex(mod(index - 1, slideData.length));
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [index, autoplay]);

  // remove autoplay when windows > 768px
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setAutoplay(false);
      } else return;
    };
    // add event listener to window resize
    window.addEventListener('resize', handleResize);
    // check the width on page load
    document.addEventListener('DOMContentLoaded', handleResize);
    // clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('DOMContentLoaded', handleResize);
    };
  }, []);

  //swipe logic

  const handleSwipeLeft = () => {
    setIndex((index + 1) % slideData.length);
    setAutoplay(false);
  };

  const handleSwipeRight = () => {
    setIndex(mod(index - 1, slideData.length));
    setAutoplay(false);
  };

  const sliderCards = slideData.map((slide, i) => {
    const indexLeft = mod(index - 1, slideData.length);
    const indexRight = mod(index + 1, slideData.length);

    let className = '';
    if (i === indexLeft) {
      className = ` ${css['step-0']}`;
    } else if (i === index) {
      className = ` ${css['step-1']}`;
    } else if (i === indexRight) {
      className = ` ${css['step-2']}`;
    } else if (i === (indexRight + 1) % slideData.length) {
      className = ` ${css['step-3']} `;
    } else if (i === (indexRight + 2) % slideData.length) {
      className = `${css['step-4']} `;
    } else if (i === (indexRight + 3) % slideData.length) {
      className = ` ${css['step-5']}`;
    } else {
      className = ` ${css['hidden']}`;
    }

    return (
      <div className={`${css['step']}  ${className}`} key={i}>
        <img
          className={css['stepImg']}
          src={slide.src}
          alt="icons representing the steps for you to make to enjoy your unique experience at a sustainable b & b"
        />
        {/*   <h4 className={css.stepTitleText}>{slide.title}</h4> */}
        <p className={css['stepText']}>{slide.headline}</p>
      </div>
    );
  });

  const sliderCardsDesktop = desktopSliderData.map((slide, i) => {
    return (
      <div className={i == 1 ? css.stepAnimationMid : css.stepAnimation} key={i-20}>
        <div className={css.step}>
          <img
            className={css.stepImg}
            src={slide.src}
            alt="icons representing the steps for you to make to enjoy your unique experience at a sustainable b & b"
          />
          {/*  <h4 className={css.slideTitle}>{slide.title}</h4> */}
          <p className={css.stepText}>{slide.headline}</p>
        </div>
      </div>
    );
  });

  return (
    <div className={css['steps-container']}>
      <h2 className={css['steps-heading']}>
        <FormattedMessage id="landing.bookingsHeadline1" />
      </h2>

      {isMobile ? (
        <Swipe
          key="1sf564"
          onSwipeLeft={handleSwipeLeft}
          onSwipeRight={handleSwipeRight}
          allowMouseEvents={true}
          tolerance={50}
          delta={10}
          preventdefaulttouchmoveevent="false"
        >
          <div key="sds44" className={css['steps']}>
            {sliderCards}
          </div>
        </Swipe>
      ) : (
        <Swipe
          key="1sf564"
          allowMouseEvents={false}
          tolerance={50}
          delta={10}
          preventdefaulttouchmoveevent="false"
        >
          {' '}
          <div key="sds44" className={css['steps']}>
            {' '}
            {sliderCardsDesktop}
          </div>
        </Swipe>
      )}

      <img
        className={css['thirdBkg']}
        src={isMobile ? thirdBackgroundImageMobile : thirdBackgroundImage}
        alt="Step into Sustainability: Discover the Journey to Your Eco-Friendly B&B Experience"
      />
    </div>
  );
};

export default SectionStepsNew;
