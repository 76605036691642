import React from 'react';
import ImpactAccomodations from '../SectionImpactAccomodations/SectionImpactAccomodations';
import { ForYouSection } from '../ForYouSection/ForYouSection';
import ExperiencesListingSlider from '../../../ListingPage/ExperiencesSlider/ExperiencesListingSlider';

const contentData = {
  stays: (
    <div
      data-elb="section"
      data-elb-section="name:impactaccomodation;position:2"
      data-elbaction="visible:vis lp-impct-section"
    >
      <ImpactAccomodations />
    </div>
  ),
  experiences: (
    <div
      data-elb="section"
      data-elb-section="name:impactaccomodation;position:2"
      data-elbaction="visible:vis lp-experience-section"
    >
      {' '}
      <ExperiencesListingSlider />{' '}
    </div>
  ),
  trips: [
    { title: 'Weekend Getaway', price: 'from 200€', location: 'Swiss Alps' },
    { title: 'Cultural Exploration', price: 'from 150€', location: 'Japan' },
    { title: 'Safari Adventure', price: 'from 300€', location: 'Kenya' },
  ],
  forYou: (
    <div
      data-elb="section"
      data-elb-section="name:impactaccomodation;position:2"
      data-elbaction="visible:vis lp-foryou-section"
    >
      {' '}
      <ForYouSection />{' '}
    </div>
  ),
};

const ContentSection = ({ currentCategory }) => {
  const content = contentData[currentCategory];

  if (Array.isArray(content)) {
    return (
      <div>
        {content.map((item, index) => (
          <div key={index}>
            <h2>{item.title}</h2>
            <p>{item.price}</p>
            <p>{item.location}</p>
          </div>
        ))}
      </div>
    );
  }

  return <div>{content}</div>;
};

export default ContentSection;
