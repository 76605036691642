import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import css from './AmbassadorPage.module.css';
import arrowDown from '../../assets/icons/arrowDown.svg';
import arrowUp from '../../assets/icons/arrowUp.svg';

const QuestionCard = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [setWidth] = useState(0);
  const [rowId, setRowId] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 576) {
        setIsMobileDevice(true);
      } else {
        setIsMobileDevice(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  const questionsAnswers = [
    {
      id: 1,
      question: <FormattedMessage id="ImpactAmb.ques1" />,
      ans: <FormattedMessage id="ImpactAmb.ans1" />,
    },
    {
      id: 2,
      question: <FormattedMessage id="ImpactAmb.ques2" />,
      ans: <FormattedMessage id="ImpactAmb.ans2" />,
    },

    {
      id: 3,
      question: <FormattedMessage id="ImpactAmb.ques3" />,
      ans: <FormattedMessage id="ImpactAmb.ans3" />,
    },

    {
      id: 4,
      question: <FormattedMessage id="ImpactAmb.ques4" />,
      ans: <FormattedMessage id="ImpactAmb.ans4" />,
    },
    {
      id: 5,
      question: <FormattedMessage id="ImpactAmb.ques5" />,
      ans: <FormattedMessage id="ImpactAmb.ans5" />,
    },
    {
      id: 6,
      question: <FormattedMessage id="ImpactAmb.ques6" />,
      ans: <FormattedMessage id="ImpactAmb.ans6" />,
    },
    {
      id: 7,
      question: <FormattedMessage id="ImpactAmb.ques7" />,
      ans: <FormattedMessage id="ImpactAmb.ans7" />,
    },
    {
      id: 8,
      question: <FormattedMessage id="ImpactAmb.ques8" />,
      ans: <FormattedMessage id="ImpactAmb.ans8" />,
    },

    {
      id: 9,
      question: <FormattedMessage id="ImpactAmb.ques9" />,
      ans: <FormattedMessage id="ImpactAmb.ans9" />,
    },

    {
      id: 10,
      question: <FormattedMessage id="ImpactAmb.ques10" />,
      ans: <FormattedMessage id="ImpactAmb.ans10" />,
    },
    {
      id: 11,
      question: <FormattedMessage id="ImpactAmb.ques11" />,
      ans: <FormattedMessage id="ImpactAmb.ans11" />,
    },
    {
      id: 12,
      question: <FormattedMessage id="ImpactAmb.ques12" />,
      ans: <FormattedMessage id="ImpactAmb.ans12" />,
    },
  ];

  const handleDrawerClick = id => {
    setRowId(id);
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className={css.wrapperPosition}>
      <div className={css.questionCardSection}>
        <div className={css.sectionTitle}>
          <FormattedMessage id="ImpactAmbQuesAns.title" />
        </div>

        <div className={css.questionCardOuterWrapper}>
          {isMobileDevice ? (
            <>
              {questionsAnswers.map(questionAnswer => (
                <div key={questionAnswer.id} className={css.questionCardWrapper}>
                  <div
                    onClick={() => handleDrawerClick(questionAnswer.id)}
                    className={css.quesWrapper}
                  >
                    <h3>{questionAnswer.question}</h3>

                    {isDrawerOpen ? (
                      <>
                        {rowId === questionAnswer.id && (
                          <img style={{ height: '20px' }} src={arrowUp} alt="arrows" />
                        )}
                      </>
                    ) : (
                      <>
                        <img style={{ height: '20px' }} src={arrowDown} alt="arrows" />
                      </>
                    )}
                  </div>

                  {isDrawerOpen && rowId === questionAnswer.id && (
                    <div className={css.ansWrapper}>
                      <p className={css.cardText}>{questionAnswer.ans}</p>
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {questionsAnswers.map(questionAnswer => (
                <div key={questionAnswer.id} className={css.questionCardWrapper}>
                  <div className={css.quesWrapper}>
                    <h3>{questionAnswer.question}</h3>
                  </div>
                  <div className={css.ansWrapper}>
                    <p className={css.cardText}>{questionAnswer.ans}</p>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
