import React, { Component } from 'react';
import { Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import ContactForm from '../../forms/ContactForm/ContactForm.js';
import { sendMail } from '../../util/api.js';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components/';
import Cookies from 'universal-cookie';
import axios from 'axios';

import css from './ContactPage.module.css';
import contactPerson from './Nils1.jpg';

import insta from '../../assets/icons/socialmedia/instagram-icon.png';
import fb from '../../assets/icons/socialmedia/facebook-icon.png';
import linkedIn from '../../assets/icons/socialmedia/linkedIn-icon.png';
import { connect } from 'react-redux';

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subPage: 'third',
      contactForm: '1',
      submitInProgress: false,
      ready: false,
      sendingDisabled: false
    };
    this.onMessageSubmit = this.onSubmit.bind(this);
    this.firstNextClick = this.firstNextClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (window !== undefined) {
      if (window.location.hash) {
        if (window.location.hash === '#FAQ') {
          window.location = '/FAQ#FAQ';
          this.setState({ subPage: 'third' });
        } else if (window.location.hash === '#contact') {
          window.location = '/FAQ#contact';
          this.setState({ subPage: 'fourth' });
        }
      }
    }
  }

  async onSubmit(values, form) {
    if(this.state.contactForm == "2"){
    this.setState({
      submitInProgress: true,
    });
    const { name, email, message } = values;
    let emailBody = `Someone filled the form in "www.socialbnb.org/contact": \n Name: ${name} \n Email: ${email}  \n Message: ${message} \n`;
    this.setState({sendingDisabled:true});
    await axios
    .post(
      'https://api.emailjs.com/api/v1.0/email/send-form/',
      new URLSearchParams({
        service_id: 'service_eyyf7wt',
        template_id: 'template_xp25qbb',
        user_id: '2LPtLKMfxBjA4y7c9',
        message: emailBody,
      })
    )
    .then(
      response => {
        this.setState({
          submitInProgress: false,
          ready: true,
        });
      },
      error => {
        console.log('Email ERROR', error);
        this.setState({
          submitInProgress: false,
          ready: true,
        });
        Alert('Error sending request please refresh the page and try again !')
      }
    );

  }
  }

  firstNextClick() {
    this.setState({ contactForm: '2' });
    this.setState({ subPage: 'fourth' });
    if (window !== undefined) window.scrollTo({ top: 0 });
  }

  render() {
    const { currentLanguage } = this.props;
    return (
      <Page
        title={
          currentLanguage === 'en'
            ? 'socialbnb - contact us and get in touch'
            : 'socialbnb - Nimm Kontakt mit uns auf '
        }
        // react helmet let's us override meta tags based on last nested
        // check out page.js for implementation details- weasel
        description={
          currentLanguage === 'en'
            ? 'Any questions? Contact us. How do I book a holiday with socialbnb?'
            : 'Du hast noch Fragen? Nimm Kontakt mit uns auf! Wie buche ich einen Urlaub mit socialbnb?'
        }
        keywords={
          currentLanguage === 'en' ? 'online contact, help contact ' : 'online Kontakt, Hilfe'
        }
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ContactPage',
          name: 'socialbnb contact',
          url: 'https://socialbnb.org/contact',
          // contactPoint: [{
          //   telephone: '+49 1573 097 76 60',
          //   contactType: 'Customer Support',
          //   email: 'info@socialbnb.de'
          // }],
        }}
      >
        <div className={css.Wrapper}>
          {' '}
          <div id="topbar">
            <TopbarContainer />
          </div>
          <div className={css.contactFormWrapper}>
            <span className={css.line1}></span>
            <span className={css.line2}></span>
            <div className={css.contactPerson}>
              <img src={contactPerson} height="100%" alt="contact Person"></img>
            </div>

            <ContactForm
              onSubmit={this.onSubmit}
              contactForm={this.state.contactForm}
              firstNextClick={this.firstNextClick}
              submitInProgress={this.state.submitInProgress}
              ready={this.state.ready}
              disabled={this.state.sendingDisabled}
            />

            <div className={css.alternativeContact}>
              <span className={css.alternativeContactTitle}>
                <FormattedMessage id="contactForm.alternative" />
              </span>{' '}
              {/* <br /> */}
              <div className={css.linkContainer}>
                <a href="mailto:info@socialbnb.de">info@socialbnb.de</a>
                <a href="tel:+49 157 3097 76 60">+49 157 3097 76 60</a>
              </div>
              <div className={css.socialLinkContainer}>
                <ExternalLink href="https://www.instagram.com/socialbnb" target="_blank">
                  <img src={insta} className={css.socialmedia} alt="instagram"></img>
                </ExternalLink>

                <ExternalLink href="https://www.facebook.com/Socialbnb" target="_blank">
                  <img src={fb} className={css.socialmedia} alt="facebook"></img>
                </ExternalLink>

                <ExternalLink
                  href="https://www.linkedin.com/company/socialbnb/"
                  target="_blank"
                  alt="linkedin"
                  className={css.socialmedia}
                >
                  <img
                    src={linkedIn}
                    className={css.socialmedia}
                    target="_blank"
                    alt="facebook"
                  ></img>
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
const mapStateToProps = state => ({
  currentLanguage: state.ui.language,
});
export default connect(mapStateToProps)(ContactPage);
