import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconLogo from './IconLogo';
import LogoImage from './logo_final.svg';
import LogoWhite from './logo_weiss_schrift_bild.svg';
import LogoWhiteCropped from './logo_white_cropped.svg';
import css from './OldLogo.module.css';
import { useConfiguration } from '../../context/configurationContext';

const OldLogo = props => {
  const { className, format, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);
  const config = useConfiguration();
  const [scrollPosition, setScrollPosition] = useState(0);

  var wheelEvent = null;
  var position = 0;

  useEffect(()=> {
  wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
  window.addEventListener(wheelEvent, changeCss, false);
  },[])
  const changeCss = () => {
    if(typeof window !== 'undefined' && window?.document){
    position = document?.body?.scrollTop ? document?.body?.scrollTop : 0;
    setScrollPosition(position);
    }
  };


  if (format === 'desktop') {
    return <img className={css.logo} src={LogoImage} alt={config.siteTitle} {...rest} />;
  }
  if (format === 'white') {
    return <img className={css.logoWhite} src={LogoWhite} alt={config.siteTitle} {...rest} />;
  }
  if (format === 'crop') {
    return <img className={css.logoWhiteCropped} src={LogoWhiteCropped} alt={config.siteTitle} {...rest} />;
  }

  return <IconLogo className={mobileClasses} {...rest} />;
};

const { oneOf, string } = PropTypes;

OldLogo.defaultProps = {
  className: null,
  format: 'desktop',
};

OldLogo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile', 'white', 'crop']),
};

export default OldLogo;
