import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import css from './TopbarDesktop.module.css';
import menuicon from './menu.png';
import closeicon from './close.png';
import searchSupport from './searchSupport.png';
import camera from './camera.webp';
import { FormattedMessage } from 'react-intl';
import { InlineTextButton, NamedLink } from '../../../components';
import LanguageSwitcher from '../../LanguageSwitcherMobile/LanguageSwitcher';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    overflow: 'visible',
    padding: '0',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },

  SelectRoot: {
    backgroundColor: 'green',
    borderBottom: '10px solid green',
  },

  SelectPaper: {
    backgroundColor: 'green',
    borderBottom: '10px solid green',
  },
});

const MenuDrawer = props => {
  const {
    authenticatedOnClientSide,
    currentUserHasListings,
    notificationCount,
    onLogout,
    intl,
  } = props;

  const classes = useStyles();
  var cookiesMaybe = cookies?.get('language') ? cookies?.get('language') : 'en';

  /*   const language = cookies?.get('language') == 'en' ? 'English' : 'Deutsch'; */

  /*   const languageOptions =
    cookies.get('language') === 'en' ? (
      <>
        <option value="Englisch">{intl.formatMessage({ id: 'languageSetting.english' })}</option>
        <option value="Deutsch">{intl.formatMessage({ id: 'languageSetting.german' })}</option>{' '}
      </>
    ) : (
      <>
        <option value="Deutsch">{intl.formatMessage({ id: 'languageSetting.german' })}</option>
        <option value="Englisch">{intl.formatMessage({ id: 'languageSetting.english' })}</option>
      </>

    setLang(event.target.value);

    if (event.target.value === 'Englisch') {
      cookies.set('language', 'en', {
        path: '/',
        maxAge: 60 * 60 * 24 * 90,
      });
      if (typeof window !== 'undefined') window.location.reload();
    } else {
      cookies.set('language', 'de', {
        path: '/',
        maxAge: 60 * 60 * 24 * 90,
      });
      if (typeof window !== 'undefined') window.location.reload();
    }
  }; */
  // revisit - weasel
  // when path is commented out , we can't change the language in the listing page anymore - Lars

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const profileMenu = authenticatedOnClientSide ? (
    <div className={css.HeaderWrapperRow} data-elbaction={'click:click menu-myAccount'}>
      <NamedLink name="ProfileSettingsPage">
        <h2 className={`${css.Header} ${css.Header1}`}>
          <FormattedMessage id="TopbarDesktop.ManageListingsPage" />
        </h2>
      </NamedLink>
    </div>
  ) : null;

  const notificationDot =
    notificationCount > 0 ? <div className={css.notificationDotMenuDrawer} /> : null;
  const inboxLink =
    authenticatedOnClientSide && currentUserHasListings != null ? (
      <div className={css.HeaderWrapperRow} data-elbaction={'click:click menu-inbox'}>
        <NamedLink name="InboxPage" params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}>
          <h2 className={`${css.Header} ${css.Header1}`}>
            <FormattedMessage id="TopbarDesktop.inbox" />
          </h2>
          <span className={css.inbox}>{notificationDot}</span>
        </NamedLink>
      </div>
    ) : null;

  const LoginButton = !authenticatedOnClientSide ? (
    <NamedLink id="anima" name="LoginPage" className={css.logInButton}>
      <FormattedMessage id="TopbarDesktop.registerLogin" />
    </NamedLink>
  ) : (
    <InlineTextButton className={css.logInButton} onClick={onLogout}>
      <span className={css.menuItemBorder} />
      <FormattedMessage id="TopbarDesktop.logout" />
    </InlineTextButton>
  );

  const line = authenticatedOnClientSide ? <div className={css.spacer}></div> : null;

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  var mediaQuery = null;
  if (typeof window !== 'undefined') {
    mediaQuery = window?.matchMedia('(min-width: 768px)');
  }

  const list = anchor => (
    <div className={css.drawer} role="presentation">
      <div className={css.languageSelector2}>
        <LanguageSwitcher />
      </div>
      <div
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        className={css.closeButton}
      >
        {' '}
        <span style={{ paddingLeft: '15px' }} className={css.about}>
          <FormattedMessage id="close" />
          <img
            src={closeicon}
            height="32px"
            width="40px"
            style={{ marginTop: '-2px' }}
            alt="selection tur"
          ></img>
        </span>
      </div>

      <div className={css.menuWrapper}>
        <div className={css.menuLeftWrapper}>
          {profileMenu}
          {inboxLink}
          {line}
          <div className={css.HeaderWrapperRow} data-elbaction={'click:click menu-start'}>
            <NamedLink name="LandingPage" activeClassName={css.activeClassName}>
              <h2 className={`${css.Header} ${css.Header1}`}>
                <FormattedMessage id="TopbarDesktop.start" />
              </h2>
            </NamedLink>
          </div>

          <div className={css.HeaderWrapperRow} data-elbaction={'click:click menu-aboutus'}>
            <NamedLink name="AboutPage" activeClassName={css.activeClassName}>
              <h2 className={`${css.Header} ${css.Header2}`}>
                <FormattedMessage id="TopbarDesktop.about" />
              </h2>
            </NamedLink>
          </div>
          <div className={css.HeaderWrapperRow} data-elbaction={'click:click menu-destinations'}>
            <NamedLink
              activeClassName={css.activeClassName}
              name="SearchPage"
              to={
                mediaQuery
                  ? mediaQuery.matches
                    ? { search: '?bounds=85.051129%2C179.00736739%2C-75.14077719%2C-160.60200761' }
                    : null
                  : null
              }
            >
              <h2 className={`${css.Header} ${css.Header3}`}>
                <FormattedMessage id="TopbarDesktop.destinations" />
              </h2>
            </NamedLink>
          </div>

          <div
            className={css.HeaderWrapperRow}
            data-elbaction={'click:click menu-experiences'}
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <NamedLink
              name="CMSPage"
              params={{ pageId: 'impact-experiences' }}
              activeClassName={css.activeClassName}
            >
              <h2 className={`${css.Header} ${css.Header4}`}>
                <FormattedMessage id="TopbarDesktop.experience" />
              </h2>
            </NamedLink>
          </div>
          <div
            className={css.HeaderWrapperRow}
            data-elbaction={'click:click menu-roundtrips'}
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <NamedLink
              name="CMSPage"
              params={{ pageId: 'impact-trips' }}
              activeClassName={css.activeClassName}
            >
              <h2 className={`${css.Header} ${css.Header5}`}>
                <FormattedMessage id="TopbarDesktop.roundTrips" />
              </h2>
            </NamedLink>
          </div>
          <div
            className={css.HeaderWrapperRow}
            data-elbaction={'click:click menu-blog'}
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <NamedLink
              name="CMSPage"
              params={{ pageId: 'blog' }}
              activeClassName={css.activeClassName}
            >
              <h2 className={`${css.Header} ${css.Header7}`}>Blog</h2>
            </NamedLink>
          </div>
          <div
            className={css.HeaderWrapperRow}
            data-elbaction={'click:click menu-giftcards'}
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <NamedLink
              name="CMSPage"
              params={{ pageId: 'gutschein' }}
              activeClassName={css.activeClassName}
            >
              <h2 className={`${css.Header} ${css.Header7}`}>
                <FormattedMessage id="newsletter.voucher" />
              </h2>
            </NamedLink>
          </div>
          <div
            className={`${css.HeaderWrapperRow} ${css.morePadding}`}
            data-elbaction={'click:click menu-ambassadorProgram'}
          >
            <NamedLink name="ambassadorPage" activeClassName={css.activeClassName}>
              <h2 className={`${css.Header} ${css.Header7}`}>
                <FormattedMessage id="ImpactAmb.link" />
              </h2>
            </NamedLink>
          </div>
          <div className={css.HeaderWrapperRowLast} data-elbaction={'click:click menu-becomeHost'}>
            <NamedLink name="BecomeHostPage" activeClassName={css.activeClassName}>
              <h2 className={`${css.Header} ${css.Header6}`}>
                <FormattedMessage id="TopbarDesktop.becomePartner" />
              </h2>
            </NamedLink>
          </div>
        </div>

        <div className={css.menuRightWrapper}>
          {LoginButton}

          <h3 className={css.sideHeader}>
            <FormattedMessage id="TopbarDesktop.menuSideTitle" />
          </h3>
          <p className={css.sideText}>
            <FormattedMessage
              id="TopbarDesktop.menuSideText"
              values={{
                contactPageLink: (
                  <NamedLink name="Contact">
                    <FormattedMessage id="TopbarDesktop.menuSideTextContactLink" />
                  </NamedLink>
                ),
              }}
            />
            {cookiesMaybe == 'en' ? '!' : '.'}
          </p>
          <br />

          <img
            src={searchSupport}
            width="25%"
            className={css.image1}
            alt="social tourism/Tourismus "
          ></img>
          <img src={camera} width="40%" className={css.image2} alt="tourism/Tourismus future"></img>
        </div>
      </div>

      <div onClick={toggleDrawer(anchor, true)} className={css.closeDrawerButton}>
        <div className={css.SwipeButton} float="left">
          <ExpandMoreIcon
            className={css.arrows}
            style={{ fill: '#fafafa', transform: 'translate(-50%, -50%) rotate(90deg)' }}
          />
        </div>
      </div>
      <div
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        className={css.backgroundContainer}
      ></div>
    </div>
  );

  return (
    <div>
      {['right'].map(anchor => (
        <React.Fragment key={anchor}>
          <div onClick={toggleDrawer(anchor, true)} className={css.openButtonScrolled}>
            <FormattedMessage id="menuDrawer.menu" style={{ fontSize: '16px' }} />
            <img
              src={menuicon}
              height="32px"
              width="40px"
              style={{ marginTop: '+0px' }}
              alt="menuDrawer socialbnb"
            ></img>
          </div>
          <Drawer
            backdropprops={{ classes: { root: classes.root } }}
            paperprops={{ classes: { root: classes.paper } }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default MenuDrawer;
