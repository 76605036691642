export const currencies = [
  { key: 'EUR', label: 'Euro', rate: 1 },
  { key: 'AED', label: 'United Arab Emirates Dirham', rate: 4.0130 },
  { key: 'AFN', label: 'Afghani', rate: 77.3020 },
  { key: 'ALL', label: 'Lek', rate: 104.8693 },
  { key: 'AMD', label: 'Armenian Dram', rate: 439.9096 },
  { key: 'ANG', label: 'Netherlands Antillean Guilder', rate: 1.9559 },
  { key: 'AOA', label: 'Kwanza', rate: 917.4432 },
  { key: 'ARS', label: 'Argentine Peso', rate: 389.0356 },
  { key: 'AUD', label: 'Australian Dollar', rate: 1.6649 },
  { key: 'AWG', label: 'Aruban Florin', rate: 1.9559 },
  { key: 'AZN', label: 'Azerbaijanian Manat', rate: 1.8437 },
  { key: 'BAM', label: 'Convertible Mark', rate: 1.9558 },
  { key: 'BBD', label: 'Barbados Dollar', rate: 2.1854 },
  { key: 'BDT', label: 'Taka', rate: 120.8244 },
  { key: 'BGN', label: 'Bulgarian Lev', rate: 1.9558 },
  { key: 'BHD', label: 'Bahraini Dinar', rate: 0.4109 },
  { key: 'BIF', label: 'Burundi Franc', rate: 3112.1672 },
  { key: 'BMD', label: 'Bermudian Dollar', rate: 1.0927 },
  { key: 'BND', label: 'Brunei Dollar', rate: 1.4615 },
  { key: 'BOB', label: 'Boliviano', rate: 7.4559 },
  { key: 'BRL', label: 'Brazilian Real', rate: 5.3437 },
  { key: 'BSD', label: 'Bahamian Dollar', rate: 1.0927 },
  { key: 'BTN', label: 'Ngultrum', rate: 91.0969 },
  { key: 'BWP', label: 'Pula', rate: 14.7435 },
  { key: 'BYN', label: 'Belarusian Ruble', rate: 3.4895 },
  { key: 'BZD', label: 'Belize Dollar', rate: 2.1854 },
  { key: 'CAD', label: 'Canadian Dollar', rate: 1.4974 },
  { key: 'CDF', label: 'Congolese Franc', rate: 2722.8710 },
  { key: 'CHF', label: 'Swiss Franc', rate: 0.9653 },
  { key: 'CLP', label: 'Chilean Peso', rate: 964.4948 },
  { key: 'CNY', label: 'Yuan Renminbi', rate: 7.8034 },
  { key: 'COP', label: 'Colombian Peso', rate: 4407.1563 },
  { key: 'CRC', label: 'Costa Rican Colon', rate: 572.5798 },
  { key: 'CUP', label: 'Cuban Peso', rate: 26.2249 },
  { key: 'CVE', label: 'Cabo Verde Escudo', rate: 110.2650 },
  { key: 'CZK', label: 'Czech Koruna', rate: 24.5182 },
  { key: 'DJF', label: 'Djibouti Franc', rate: 194.1965 },
  { key: 'DKK', label: 'Danish Krone', rate: 7.4585 },
  { key: 'DOP', label: 'Dominican Peso', rate: 61.2879 },
  { key: 'DZD', label: 'Algerian Dinar', rate: 145.7334 },
  { key: 'EGP', label: 'Egyptian Pound', rate: 33.8104 },
  { key: 'ERN', label: 'Eritrean Nakfa', rate: 16.3906 },
  { key: 'ETB', label: 'Ethiopian Birr', rate: 61.3533 },
  { key: 'FJD', label: 'Fiji Dollar', rate: 2.4430 },
  { key: 'FKP', label: 'Falkland Islands Pound', rate: 0.8715 },
  { key: 'GBP', label: 'Pound Sterling', rate: 0.8716 },
  { key: 'GEL', label: 'Georgian Lari', rate: 2.9574 },
  { key: 'GHS', label: 'Ghanaian Cedi', rate: 13.1994 },
  { key: 'GIP', label: 'Gibraltar Pound', rate: 0.8715 },
  { key: 'GMD', label: 'Gambian Dalasi', rate: 71.9055 },
  { key: 'GNF', label: 'Guinean Franc', rate: 9394.1805 },
  { key: 'GTQ', label: 'Guatemalan Quetzal', rate: 8.4397 },
  { key: 'GYD', label: 'Guyanaese Dollar', rate: 229.3723 },
  { key: 'HKD', label: 'Hong Kong Dollar', rate: 8.5203 },
  { key: 'HNL', label: 'Honduran Lempira', rate: 26.6107 },
  { key: 'HRK', label: 'Croatian Kuna', rate: 7.5345 },
  { key: 'HTG', label: 'Haitian Gourde', rate: 145.2823 },
  { key: 'HUF', label: 'Hungarian Forint', rate: 380.1926 },
  { key: 'IDR', label: 'Indonesian Rupiah', rate: 16857.6613 },
  { key: 'ILS', label: 'Israeli New Shekel', rate: 4.0620 },
  { key: 'INR', label: 'Indian Rupee', rate: 91.0903 },
  { key: 'IQD', label: 'Iraqi Dinar', rate: 1436.7489 },
  { key: 'IRR', label: 'Iranian Rial', rate: 46576.0127 },
  { key: 'ISK', label: 'Icelandic Krona', rate: 152.4984 },
  { key: 'JMD', label: 'Jamaican Dollar', rate: 169.9203 },
  { key: 'JOD', label: 'Jordanian Dinar', rate: 0.7747 },
  { key: 'JPY', label: 'Japanese Yen', rate: 161.7030 },
  { key: 'KES', label: 'Kenyan Shilling', rate: 167.6576 },
  { key: 'KGS', label: 'Kyrgystani Som', rate: 97.5777 },
  { key: 'KHR', label: 'Cambodian Riel', rate: 4501.8133 },
  { key: 'KMF', label: 'Comorian Franc', rate: 491.9678 },
  { key: 'KRW', label: 'South Korean Won', rate: 1412.8497 },
  { key: 'KWD', label: 'Kuwaiti Dinar', rate: 0.3354 },
  { key: 'KYD', label: 'Cayman Islands Dollar', rate: 0.9106 },
  { key: 'KZT', label: 'Kazakhstani Tenge', rate: 502.6168 },
  { key: 'LAK', label: 'Lao Kip', rate: 22202.8253 },
  { key: 'LBP', label: 'Lebanese Pound', rate: 16390.5675 },
  { key: 'LKR', label: 'Sri Lankan Rupee', rate: 357.5089 },
  { key: 'LRD', label: 'Liberian Dollar', rate: 205.4569 },
  { key: 'LSL', label: 'Lesotho Loti', rate: 20.2355 },
  { key: 'LYD', label: 'Libyan Dinar', rate: 5.2868 },
  { key: 'MAD', label: 'Moroccan Dirham', rate: 11.0139 },
  { key: 'MDL', label: 'Moldovan Leu', rate: 19.3779 },
  { key: 'MGA', label: 'Malagasy Ariary', rate: 4841.9688 },
  { key: 'MKD', label: 'Macedonian Denar', rate: 61.4935 },
  { key: 'MMK', label: 'Myanmar Kyat', rate: 2646.5426 },
  { key: 'MNT', label: 'Mongolian Tugrik', rate: 3817.4091 },
  { key: 'MOP', label: 'Macanese Pataca', rate: 8.7759 },
  { key: 'MRU', label: 'Mauritanian Ouguiya', rate: 43.5042 },
  { key: 'MUR', label: 'Mauritian Rupee', rate: 47.6330 },
  { key: 'MVR', label: 'Maldivian Rufiyaa', rate: 16.6346 },
  { key: 'MWK', label: 'Malawian Kwacha', rate: 1853.7833 },
  { key: 'MXN', label: 'Mexican Peso', rate: 18.7768 },
  { key: 'MYR', label: 'Malaysian Ringgit', rate: 5.0901 },
  { key: 'MZN', label: 'Mozambican Metical', rate: 69.9702 },
  { key: 'NAD', label: 'Namibian Dollar', rate: 20.2355 },
  { key: 'NGN', label: 'Nigerian Naira', rate: 976.1733 },
  { key: 'NIO', label: 'Nicaraguan Córdoba', rate: 39.4330 },
  { key: 'NOK', label: 'Norwegian Krone', rate: 11.6546 },
  { key: 'NPR', label: 'Nepalese Rupee', rate: 145.7551 },
  { key: 'NZD', label: 'New Zealand Dollar', rate: 1.8037 },
  { key: 'OMR', label: 'Omani Rial', rate: 0.4201 },
  { key: 'PAB', label: 'Panamanian Balboa', rate: 1.0927 },
  { key: 'PEN', label: 'Peruvian Nuevo Sol', rate: 4.0890 },
  { key: 'PGK', label: 'Papua New Guinean Kina', rate: 4.0046 },
  { key: 'PHP', label: 'Philippine Peso', rate: 60.5974 },
  { key: 'PKR', label: 'Pakistani Rupee', rate: 312.0044 },
  { key: 'PLN', label: 'Polish Złoty', rate: 4.3672 },
  { key: 'PYG', label: 'Paraguayan Guarani', rate: 8050.3631 },
  { key: 'QAR', label: 'Qatari Rial', rate: 3.9774 },
  { key: 'RON', label: 'Romanian Leu', rate: 4.9704 },
  { key: 'RSD', label: 'Serbian Dinar', rate: 117.1788 },
  { key: 'RUB', label: 'Russian Ruble', rate: 96.5063 },
  { key: 'RWF', label: 'Rwandan Franc', rate: 1394.7306 },
  { key: 'SAR', label: 'Saudi Riyal', rate: 4.0976 },
  { key: 'SBD', label: 'Solomon Islands Dollar', rate: 9.2210 },
  { key: 'SCR', label: 'Seychellois Rupee', rate: 14.6326 },
  { key: 'SDG', label: 'Sudanese Pound', rate: 490.0377 },
  { key: 'SEK', label: 'Swedish Krona', rate: 11.4145 },
  { key: 'SGD', label: 'Singapore Dollar', rate: 1.4617 },
  { key: 'SHP', label: 'Saint Helena Pound', rate: 0.8715 },
  { key: 'SLL', label: 'Sierra Leonean Leone', rate: 24291.4545 },
  { key: 'SOS', label: 'Somali Shilling', rate: 626.4119 },
  { key: 'SRD', label: 'Surinamese Dollar', rate: 42.0888 },
  { key: 'SSP', label: 'South Sudanese Pound', rate: 1171.2051 },
  { key: 'STN', label: 'São Tomé and Príncipe Dobra', rate: 24.5000 },
  { key: 'SYP', label: 'Syrian Pound', rate: 13906.9581 },
  { key: 'SZL', label: 'Swazi Lilangeni', rate: 20.2355 },
  { key: 'THB', label: 'Thai Baht', rate: 38.4322 },
  { key: 'TJS', label: 'Tajikistani Somoni', rate: 11.9656 },
  { key: 'TMT', label: 'Turkmenistani Manat', rate: 3.8319 },
  { key: 'TND', label: 'Tunisian Dinar', rate: 3.3866 },
  { key: 'TOP', label: "Tongan Pa'anga", rate: 2.5736 },
  { key: 'TRY', label: 'Turkish Lira', rate: 31.4429 },
  { key: 'TTD', label: 'Trinidad and Tobago Dollar', rate: 8.1458 },
  { key: 'TWD', label: 'New Taiwan Dollar', rate: 34.2737 },
  { key: 'TZS', label: 'Tanzanian Shilling', rate: 2749.2485 },
  { key: 'UAH', label: 'Ukrainian Hryvnia', rate: 39.4810 },
  { key: 'UGX', label: 'Ugandan Shilling', rate: 4135.7400 },
  { key: 'USD', label: 'Dollar', rate: 1.0927 },
  { key: 'UYU', label: 'Uruguayan Peso', rate: 42.8163 },
  { key: 'UZS', label: 'Uzbekistan Som', rate: 13427.8891 },
  { key: 'VES', label: 'Venezuelan Bolívar Soberano', rate: 38.7607 },
  { key: 'VND', label: 'Vietnamese Đồng', rate: 26188.7633 },
  { key: 'VUV', label: 'Vanuatu Vatu', rate: 131.1752 },
  { key: 'WST', label: 'Samoan Tala', rate: 2.9611 },
  { key: 'XAF', label: 'CFA Franc BEAC', rate: 655.9570 },
  { key: 'XCD', label: 'East Caribbean Dollar', rate: 2.9503 },
  { key: 'XDR', label: 'Special Drawing Rights', rate: 0.8214 },
  { key: 'XOF', label: 'CFA Franc BCEAO', rate: 655.9570 },
  { key: 'XPF', label: 'CFP Franc', rate: 119.3320 },
  { key: 'YER', label: 'Yemeni Rial', rate: 269.5823 },
  { key: 'ZAR', label: 'South African Rand', rate: 20.2469 },
  { key: 'ZMW', label: 'Zambian Kwacha', rate: 25.5253 },
  { key: 'ZWL', label: 'Zimbabwean Dollar', rate: 6314.0505 }
];
