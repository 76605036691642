import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import css from './FAQPage.module.css';

import { FormattedMessage } from '../../util/reactIntl';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { ExternalLink, NamedLink } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterNew from '../../components/Footer/FooterNew'

/* import coronaEN from '../../assets/CoronaRules/CoronaEN.pdf';
import coronaDE from '../../assets/CoronaRules/CoronaDE.pdf'; */

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '1080px',
    margin: 'auto',
  },
  accordion: {
    boxShadow: 'none',
  },
  section: {
    borderBottom: '2px solid #353535',
    boxShadow: 'none',
    backgroundImage: "url('https://www.transparenttextures.com/patterns/groovepaper.png')",

    backgroundSize: '400px 240px',
    backgroundRepeat: 'repeat',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  secondaryDetails: {
    backgroundImage: "url('https://www.transparenttextures.com/patterns/groovepaper.png')",

    backgroundSize: '400px 240px',
    backgroundRepeat: 'repeat',
  },
}));

var mediaQuery = null;
if (typeof window !== 'undefined') {
  mediaQuery = window.matchMedia('(min-width: 768px)');
}

export default function ControlledAccordions(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isShowGeneral, setIsShowGeneral] = useState(false);
  const [isShowTravelers, setIsShowTravelers] = useState(false);
  const [isShowProjects, setIsShowProjects] = useState(false);
  const header3 = useRef();
  const header2 = useRef();
  const header1 = useRef();

  const getQA = item => {
    if (item === 2) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  socialbnbteam: (
                    <ExternalLink href="https://blog.socialbnb.org/meet-the-socialbnb-team">
                      socialbnb team
                    </ExternalLink>
                  ),
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 4) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  blog: <ExternalLink href="https://blog.socialbnb.org/">blog</ExternalLink>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 6) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  partnerEmail: <a href="mailto:partner@socialbnb.de">partner@socialbnb.de</a>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 7) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  blog: <ExternalLink href="https://blog.socialbnb.org/">blog</ExternalLink>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 8) {
      return;
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
             {/*  <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  coronaRules: (
                    <ExternalLink href={cookies.get('language') === 'en' ? coronaEN : coronaDE}>
                      <FormattedMessage id={'faq.q' + item + '.a.link'} />
                    </ExternalLink>
                  ),
                }}
              /> */}
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 9) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  blogsocialbnb: <a href="mailto:blog@socialbnb.de">blog@socialbnb.de</a>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 10) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  destinations: (
                    <NamedLink
                      name="SearchPage"
                      to={
                        mediaQuery
                          ? mediaQuery.matches
                            ? {
                                search:
                                  '?bounds=85.051129%2C179.00736739%2C-75.14077719%2C-160.60200761',
                              }
                            : null
                          : null
                      }
                    >
                      <FormattedMessage id="faq.q10.a.link" />
                    </NamedLink>
                  ),
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 13) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  newsletter: (
                    <ExternalLink href="https://blog.socialbnb.org/newsletter">
                      newsletter
                    </ExternalLink>
                  ),
                  blog: <ExternalLink href="https://blog.socialbnb.org/">blog</ExternalLink>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 18) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  bookingsocialbnb: <a href="mailto:booking@socialbnb.de">booking@socialbnb.de</a>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 20) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  blog: <ExternalLink href="https://blog.socialbnb.org/">blog</ExternalLink>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 23) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  newsletter: (
                    <ExternalLink href="https://blog.socialbnb.org/newsletter">
                      newsletter
                    </ExternalLink>
                  ),
                  blog: (
                    <ExternalLink href="https://blog.socialbnb.org/futuretourism">
                      blog
                    </ExternalLink>
                  ),
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 28) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  contactForm: (
                    <a href="mailto:info@socialbnb.de">
                      <FormattedMessage id="faq.q28.a.link" />
                    </a>
                  ),
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 29) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  bookingsocialbnb: <a href="mailto:booking@socialbnb.de">booking@socialbnb.de</a>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 31) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  blogsocialbnb: <a href="mailto:blog@socialbnb.de">blog@socialbnb.de</a>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 32) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  blogsocialbnb: <a href="mailto:blog@socialbnb.de">blog@socialbnb.de</a>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 33) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  infosocialbnb: <a href="mailto:info@socialbnb.de">info@socialbnb.de</a>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 34) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  blog: <ExternalLink href="https://blog.socialbnb.org/">blog</ExternalLink>,
                  hier: <ExternalLink href="https://blog.socialbnb.org/">hier</ExternalLink>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 35) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  qualityCriteria: (
                    <ExternalLink href="https://blog.socialbnb.org/">
                      <FormattedMessage id="faq.q35.a.link" />
                    </ExternalLink>
                  ),
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 38) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  infosocialbnb: (
                    <a href="mailto:info@socialbnb.de">
                      <FormattedMessage id="faq.q38.a.link" />
                    </a>
                  ),
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 40) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  infosocialbnb: <a href="mailto:info@socialbnb.de">info@socialbnb.de</a>,
                  Ecpat: <ExternalLink href="https://ecpat.de/">Ecpat e.V.</ExternalLink>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 41) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  Ecpat: <ExternalLink href="https://ecpat.de/">Ecpat e.V.</ExternalLink>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 42) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  checklist: (
                    <ExternalLink href="https://static1.squarespace.com/static/52720d41e4b024943bdf6241/t/5a0be22b0d9297328daa35e9/1510728260373/Saih-Social-Media-Guide-2017.pdf">
                      checklist
                    </ExternalLink>
                  ),
                  RadiAid: <ExternalLink href="https://www.radiaid.com/">Radi-Aid</ExternalLink>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 43) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  infosocialbnb: <a href="mailto:info@socialbnb.de">info@socialbnb.de</a>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 44) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <p>
              <FormattedMessage id={'faq.q' + item + '.a'} />
            </p>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
              <li>
                <FormattedMessage id={'faq.q' + item + '.a1'} />
                <p style={{ margin: '0' }}>
                  <FormattedMessage id={'faq.q' + item + '.a11'} />
                </p>
              </li>

              <li style={{ margin: '16px 0' }}>
                <FormattedMessage id={'faq.q' + item + '.a2'} />
                <p style={{ margin: '0' }}>
                  <FormattedMessage id={'faq.q' + item + '.a21'} />
                </p>
              </li>

              <li>
                <FormattedMessage id={'faq.q' + item + '.a3'} />
                <p style={{ margin: '0' }}>
                  <FormattedMessage
                    id={'faq.q' + item + '.a31'}
                    values={{
                      infosocialbnb: <a href="mailto:info@socialbnb.de">info@socialbnb.de</a>,
                    }}
                  />
                </p>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 46) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  forProjects: (
                    <NamedLink name="BecomeHostPage">
                      <FormattedMessage id="faq.q46.a.link" />
                    </NamedLink>
                  ),
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 49) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  forProjects: (
                    <NamedLink name="BecomeHostPage">
                      <FormattedMessage id="faq.q49.a.link" />
                    </NamedLink>
                  ),
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 52) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  currencyConverter: (
                    <ExternalLink href="https://www.xe.com/de/currencyconverter/">
                      <FormattedMessage id="faq.q52.a.link" />
                    </ExternalLink>
                  ),
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    if (item === 54) {
      return (
        <Accordion
          key={item}
          className={classes.accordion}
          expanded={expanded === 'panel' + item}
          onChange={handleChange('panel' + item)}
        >
          <AccordionSummary
            className={classes.section}
            expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
            aria-controls={'panel' + item + 'bh-content'}
            id={'panel' + item + 'bh-header'}
          >
            <span className={css.heading}>
              <FormattedMessage id={'faq.q' + item + '.q'} />
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              <FormattedMessage
                id={'faq.q' + item + '.a'}
                values={{
                  partnersocialbnb: <a href="mailto:partner@socialbnb.de">partner@socialbnb.de</a>,
                }}
              />
            </p>
          </AccordionDetails>
        </Accordion>
      );
    }

    return (
      <Accordion
        key={item}
        className={classes.accordion}
        expanded={expanded === 'panel' + item}
        onChange={handleChange('panel' + item)}
      >
        <AccordionSummary
          className={classes.section}
          expandIcon={<ExpandMoreIcon style={{ fill: '#353535' }} />}
          aria-controls={'panel' + item + 'bh-content'}
          id={'panel' + item + 'bh-header'}
        >
          <span className={css.heading}>
            <FormattedMessage id={'faq.q' + item + '.q'} />
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <p>
            <FormattedMessage id={'faq.q' + item + '.a'} />
          </p>
        </AccordionDetails>
      </Accordion>
    );
  };
  let faq1 = [];
  for (let index = 1; index < 10; index++) {
    faq1.push(index);
  }
  let faq2 = [];
  for (let index = 10; index < 45; index++) {
    faq2.push(index);
  }
  let faq3 = [];
  for (let index = 45; index < 59; index++) {
    faq3.push(index);
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (window !== undefined) {
      let distance =
        header3.current.getBoundingClientRect().top -
        38 -
        header3.current.getBoundingClientRect().height +
        window.scrollY;

      if (!isShowProjects) {
        window.scroll({
          top: distance,
          behavior: 'smooth',
        });
      }
    }
  }, [isShowProjects]);

  useEffect(() => {
    if (window !== undefined) {
      let distance =
        header2.current.getBoundingClientRect().top -
        38 -
        header2.current.getBoundingClientRect().height +
        window.scrollY;

      if (!isShowTravelers) {
        window.scroll({
          top: distance,
          behavior: 'smooth',
        });
      }
    }
  }, [isShowTravelers]);

  useEffect(() => {
    if (window !== undefined) {
      let distance =
        header1.current.getBoundingClientRect().top -
        38 -
        header1.current.getBoundingClientRect().height +
        window.scrollY;

      if (!isShowGeneral) {
        window.scroll({
          top: distance,
          behavior: 'smooth',
        });
      }
    }
  }, [isShowGeneral]);

  useEffect(() => {
    window.scroll({
      top: 0.1,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div >
      <h2 ref={header1} className={css.FAQheader}>
        <FormattedMessage id="faq.headline1" />
      </h2>

      {isShowGeneral ? (
        <div className={classes.root}>{faq1.map(getQA)}</div>
      ) : (
        <div className={classes.root}>{faq1.slice(0, 3).map(getQA)}</div>
      )}

      <button className={css.showAll} onClick={() => setIsShowGeneral(!isShowGeneral)}>
        <FormattedMessage id={isShowGeneral ? 'faq.showLess' : 'faq.showAll'} />
      </button>

      <h2 ref={header2} className={css.FAQheader}>
        <FormattedMessage id="faq.headline2" />
      </h2>

      {isShowTravelers ? (
        <div className={classes.root}>{faq2.map(getQA)}</div>
      ) : (
        <div className={classes.root}>{faq2.slice(0, 3).map(getQA)}</div>
      )}

      <button className={css.showAll} onClick={() => setIsShowTravelers(!isShowTravelers)}>
        <FormattedMessage id={isShowTravelers ? 'faq.showLess' : 'faq.showAll'} />
      </button>

      <h2 ref={header3} className={css.FAQheader}>
        <FormattedMessage id="faq.headline3" />
      </h2>

      {isShowProjects ? (
        <div className={classes.root}>{faq3.map(getQA)}</div>
      ) : (
        <div className={classes.root}>{faq3.slice(0, 3).map(getQA)}</div>
      )}

      <button className={css.showAll} onClick={() => setIsShowProjects(!isShowProjects)}>
        <FormattedMessage id={isShowProjects ? 'faq.showLess' : 'faq.showAll'} />
      </button>
    </div>
  );
}
