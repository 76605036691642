import React from 'react';
import NamedLinkNewTab from '../../../../components/NamedLink/NamedLinkNewTab';
import { FormattedMessage } from 'react-intl';
import NamedLink from '../../../NamedLink/NamedLink';

export const ThirdColumn = ({ css }) => {
  return (
    <div className={css.list2}>
      <ul>
        <h3 className={css.heading}>
          <FormattedMessage id="footer.listing.infoCategory" />
        </h3>
        <li className={css.listItem} data-elbaction={'click:click footer-faq'}>
          <NamedLink name="SearchPage" className={css.link}>
            <FormattedMessage id="footer.listing.infoBookFree" />
          </NamedLink>
        </li>
        <li className={css.listItem} data-elbaction={'click:click footer-faq'}>
          <NamedLinkNewTab name="FAQ" className={css.link}>
            <FormattedMessage id="footer.listing.infoCancellation" />
          </NamedLinkNewTab>
        </li>

        <li className={css.listItem} data-elbaction={'click:click footer-faq'}>
          <NamedLinkNewTab name="FAQ" className={css.link}>
            <FormattedMessage id="TopbarDesktop.FAQ" />
          </NamedLinkNewTab>
        </li>
        <li className={css.listItem} data-elbaction={'click:click footer-press'}>
          <NamedLinkNewTab name="CMSPage" params={{ pageId: 'presse' }} className={css.link}>
            <FormattedMessage id="TopbarDesktop.Press" />
          </NamedLinkNewTab>
        </li>
        <li className={css.listItem} data-elbaction={'click:click bloglp'}>
          <NamedLinkNewTab name="CMSPage" params={{ pageId: 'blog' }} className={css.link}>
            <FormattedMessage id="TopbarDesktop.Blog" />
          </NamedLinkNewTab>
        </li>
        <li className={css.listItem} data-elbaction={'click:click footer-affiliate'}>
          <NamedLinkNewTab
            name="CMSPage"
            params={{ pageId: 'affiliatelanding' }}
            className={css.link}
          >
            <FormattedMessage id="Footer.affiliate" />
          </NamedLinkNewTab>
        </li>
        <li className={css.listItem} data-elbaction={'click:click footer-becomehost'}>
          <NamedLinkNewTab name="BecomeHostPage" className={css.link}>
            <FormattedMessage id="Footer.host" />
          </NamedLinkNewTab>
        </li>
      </ul>
    </div>
  );
};
